import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  Input,
  InputProps,
  useEditableControls,
} from '@chakra-ui/react';
import { FiCheck, FiX } from 'react-icons/fi';
import CurrencyInput from '../CurrencyInput';
import { useState } from 'react';

type InputWithConfirmationProps = Omit<
  InputProps,
  'type' | 'onSubmit' | 'value'
> & {
  type?: 'text' | 'currency';
  onSubmit: (nextValue: string) => void;
  value?: string;
};

const InputWithConfirmation = (props: InputWithConfirmationProps) => {
  const { type = 'text', onSubmit } = props;
  const [currencyValue, setCurrencyValue] = useState(props.value ?? '');
  const { bg, backgroundColor, title } = props;

  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
      useEditableControls();

    return isEditing ? (
      <ButtonGroup
        size="sm"
        minWidth="76px"
        spacing={1}
        display="inline-block"
        colorScheme="gray"
      >
        <IconButton
          aria-label="Confirmar"
          icon={<FiCheck />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancelar"
          icon={<FiX />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : null;
  }

  function handleSubmit(nextValue: string) {
    if (type === 'text') {
      onSubmit(nextValue);
    } else if (type === 'currency') {
      onSubmit(currencyValue);
    }
  }

  return (
    <Editable
      title={title}
      defaultValue={props.defaultValue?.toString()}
      isPreviewFocusable={true}
      selectAllOnFocus={false}
      onSubmit={handleSubmit}
      // reset currency value if user cancels submission
      onCancel={() => setCurrencyValue(props.value ?? '')}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      backgroundColor={bg || backgroundColor || 'gray.50'}
      zIndex="inherit"
    >
      <EditablePreview
        padding={4}
        border="1px solid"
        borderColor={props.isInvalid ? 'feedback.alert' : 'gray.200'}
        boxShadow={props.isInvalid ? '0 0 0 1px' : 'none'}
        color={props.isInvalid ? 'feedback.alert' : 'inherit'}
        height="56px"
        minWidth="100%"
        _hover={{
          borderColor: 'gray.400',
        }}
      />
      {type === 'text' && (
        <Input
          zIndex="inherit"
          as={EditableInput}
          marginRight={2}
          {...props}
          onSubmit={undefined}
          maxWidth="100%"
        />
      )}

      {type === 'currency' && (
        <CurrencyInput
          zIndex="inherit"
          as={EditableInput}
          value={currencyValue}
          onValueChange={({ value }) => setCurrencyValue(value)}
          marginRight={2}
          maxWidth="100%"
        />
      )}
      <EditableControls />
    </Editable>
  );
};

export default InputWithConfirmation;
