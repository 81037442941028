import { IRowHookInput } from 'dromo-uploader-react';
import { isValidCpf } from '../../../utils/validations/isValidCpf';

export function getDromoRowHooks(
  existingCPFs: string[] | undefined
): ((record: IRowHookInput) => IRowHookInput)[] {
  return [
    (record) => {
      const newRecord = record;
      if (newRecord.row.document) {
        const cpf = newRecord.row.document.value;
        if (!isValidCpf(cpf)) {
          newRecord.row.document.info = [
            {
              message: 'Ops... parece que o número não está certo',
              level: 'error',
            },
          ];
        } else if (existingCPFs?.includes(cpf)) {
          newRecord.row.document.info = [
            {
              message: 'Você já cadastrou um colaborador com este CPF',
              level: 'error',
            },
          ];
        } else {
          newRecord.row.document.info = [];
        }
      }

      return newRecord;
    },
  ];
}
