import { IDeveloperField } from 'dromo-uploader-react';
import { Address } from '../../../types/address';
import { UFs } from '../../../constants/general';
import { BenefitPackage } from '../../../types/benefit';
import { validDDDs } from '../../../utils/validations/regex';

export function getDromoFields(
  companyAddressList: Address[],
  benefitPackages: BenefitPackage[],
  cardType: string | undefined = 'NOMINAL',
  sendCommsOnAdmissionDate: boolean
): IDeveloperField[] {
  return [
    {
      key: 'fullName',
      type: 'string',
      label: 'Nome completo',
      description: 'Nome registrado no documento do colaborador',
      alternateMatches: [
        'nome',
        'Nome completo (obrigatório)',
        'Nome do Funcionário',
        'Nome do Colaborador',
        'Colaborador',
        'Funcionário',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'required',
          errorMessage: 'Você precisa preencher o nome do colaborador',
        },
        {
          validate: 'regex_match',
          // regex to only allow letters, spaces and hyphens
          regex: '^[a-zA-ZÀ-ú- ]+$',
          errorMessage: 'O nome do colaborador só pode conter letras',
        },
        {
          validate: 'regex_match',
          // regex to check if the name has at least 2 words
          regex: '^\\S+\\s+\\S+',
          errorMessage: 'Informe o nome e pelo menos um sobrenome',
        },
      ],
    },
    {
      key: 'document',
      type: 'string',
      label: 'CPF',
      description: 'Número do cadastro de pessoa física',
      requireMapping: true,
      alternateMatches: ['CPF (obrigatório)'],
      validators: [
        {
          validate: 'required',
          errorMessage: 'Você precisa preencher o CPF',
        },
        {
          validate: 'unique_case_insensitive',
          errorMessage: 'Existem duas linhas com este CPF nesta planilha',
        },
        {
          validate: 'regex_match',
          // regex to only allow numbers and 11 digits
          regex: '^[0-9]{11}$',
          errorMessage:
            'Por favor, digite somente os 11 números do CPF do colaborador, sem caracteres especiais',
        },
      ],
    },
    {
      key: 'cellphone',
      type: 'string',
      label: 'Celular com DDD',
      requireMapping: true,
      alternateMatches: [
        'Celular com DDD',
        'celular',
        'Celular com DDD (obrigatório se não tiver e-mail)',
      ],
      description: 'O celular é obrigatório se o colaborador não tiver e-mail',
      validators: [
        {
          validate: 'require_without',
          fields: ['email'],
          errorMessage: 'Você precisa informar um celular ou um e-mail',
        },
        {
          validate: 'regex_match',
          // regex to only allow numbers and 11 digits
          regex: '^[0-9]{11}$',
          errorMessage:
            'Por favor, digite o celular com DDD + 9 números, sem caracteres especiais',
        },
        {
          validate: 'regex_match',
          // regex to check if is valid DDD
          regex: validDDDs.source,

          errorMessage: 'Ops... parece que o DDD deste celular não está certo',
        },
        {
          validate: 'regex_match',
          // regex to check if the char at position 2 starts with 9
          regex: '^.{2}9',
          errorMessage: 'O celular precisa começar com o número 9 após o DDD',
        },
      ],
    },
    {
      key: 'email',
      type: 'email',
      label: 'E-mail',
      description: 'O e-mail é obrigatório se o colaborador não tiver celular',
      alternateMatches: [
        'E-mail (obrigatório se não tiver celular)',
        'E-mail',
        'e-mail',
      ],
      requireMapping: true,
      invalidValueMessage: 'Ops... parece que este e-mail não está certo',
      validators: [
        {
          validate: 'require_without',
          fields: ['cellphone'],
          errorMessage: 'Você precisa informar um celular ou um e-mail',
        },
      ],
    },
    {
      key: 'deliveryType',
      type: 'select',
      label: 'Local da entrega do cartão',
      requireMapping: true,
      description:
        'Empresa ou Colaborador, veja as instruções de preenchimento na guia do navegador que foi aberta ao lado',
      alternateMatches: [
        'Destinatário da entrega do cartão',
        'Destinatário da entrega',
        'Local da entrega do cartão',
        'Local da entrega do cartão (Empresa ou Colaborador, veja as instruções no Portal)',
      ],
      validators:
        cardType === 'NOMINAL'
          ? [
              {
                validate: 'required',
                errorMessage:
                  'Escolha se o cartão será entregue num endereço da empresa ou do colaborador',
              },
            ]
          : [],
      selectOptions: [
        {
          label: 'Empresa',
          value: 'COMPANY',
        },
        {
          label: 'Colaborador',
          value: 'RESIDENTIAL',
        },
      ],
      invalidValueMessage:
        'Escolha se o cartão será entregue num endereço da empresa ou do colaborador',
    },
    {
      key: 'companyAddressId',
      type: 'select',
      label: 'Nome do endereço de entrega do cartão',
      description:
        'Nome do endereço desejado conforme o que está cadastrado no Portal Raiô, veja as instruções de preenchimento na guia do navegador que foi aberta ao lado',
      alternateMatches: [
        'Nome do endereço de entrega do cartão',
        'Nome do endereço de entrega do cartão (obrigatório para empresas com vários endereços se o local da entrega é na Empresa)',
      ],
      requireMapping: companyAddressList.length > 1 ? true : false,
      validators: [
        {
          validate: 'require_with_values',
          fieldValues: {
            deliveryType: 'Empresa',
          },
          errorMessage:
            'Selecione em qual endereço da empresa o cartão deve ser entregue',
        },
      ],
      selectOptions: companyAddressList.map((address) => ({
        label: address.name,
        value: address.id,
      })),
      invalidValueMessage: 'Selecione um dos endereços cadastrados da empresa',
    },
    {
      key: 'identificationNumber',
      type: 'string',
      label: 'Matrícula do colaborador',
      description:
        'Código único que identifica o colaborador internamente na empresa',
      alternateMatches: [
        'Matrícula do colaborador (opcional)',
        'Matrícula',
        'Cadastro',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'unique_case_insensitive',
          errorMessage: 'Existem duas linhas com esta matrícula nesta planilha',
        },
      ],
    },
    {
      key: 'admissionDate',
      type: [
        'date',
        {
          displayFormat: 'dd/MM/yyyy',
          outputFormat: 'dd/MM/yyyy',
          locale: 'pt-BR',
        },
      ],
      label: 'Data de admissão',
      description: 'Quando o colaborador começou a trabalhar na empresa',
      requireMapping: sendCommsOnAdmissionDate ? true : false,
      validators: sendCommsOnAdmissionDate
        ? [
            {
              validate: 'required',
              errorMessage: 'Você precisa preencher a data de admissão',
            },
          ]
        : [],
      invalidValueMessage:
        'Por favor, digite a data no formato DD/MM/AAAA (por exemplo, 22/01/2024)',
      alternateMatches: [
        'Data de admissão',
        'Data de admissão (opcional)',
        'Data da contratação',
      ],
    },
    {
      key: 'zipCode',
      type: 'string',
      label: 'CEP do colaborador',
      description:
        'Se o local de entrega do cartão é o colaborador, este campo é obrigatório',
      alternateMatches: [
        'CEP do colaborador',
        'CEP',
        'CEP do colaborador (obrigatório se o local da entrega é no endererço do colaborador)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          // regex to only allow numbers and 8 digits
          regex: '^[0-9]{8}$',
          errorMessage:
            'Por favor, digite somente os 8 números do CEP do colaborador, sem caracteres especiais',
        },
        {
          validate: 'require_with_values',
          fieldValues: {
            deliveryType: 'Colaborador',
          },
          errorMessage:
            'Você precisa preencher o CEP se o local da entrega é no endereço do colaborador',
        },
        {
          validate: 'require_with',
          fields: [
            'address',
            'number',
            'complement',
            'district',
            'city',
            'state',
          ],
          errorMessage:
            'Você precisa preencher o CEP ou apagar o resto do endereço',
        },
      ],
    },
    {
      key: 'address',
      type: 'string',
      label: 'Nome da rua do colaborador',
      description:
        'Se o local de entrega do cartão é o colaborador, este campo é obrigatório',
      alternateMatches: [
        'Rua',
        'Nome da rua do colaborador (obrigatório se o local da entrega é no endererço do colaborador, até 40 caracteres)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          regex: '^.{0,40}$',
          errorMessage: 'O nome da rua pode ter no máximo 40 caracteres',
        },
        {
          validate: 'require_with',
          fields: [
            'zipCode',
            'number',
            'complement',
            'district',
            'city',
            'state',
          ],
          errorMessage:
            'Você precisa preencher o nome da rua ou apagar o resto do endereço',
        },
      ],
    },
    {
      key: 'number',
      type: 'string',
      label: 'Número',
      description:
        'Se o local de entrega do cartão é o colaborador e se o endereço do colaborador tiver número, este campo é obrigatório',
      alternateMatches: [
        'Número (obrigatório se o local da entrega é no endererço do colaborador, até 9 números)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          regex: '^[0-9]{0,9}$',
          errorMessage: 'O número pode ter no máximo 9 caracteres',
        },
      ],
    },
    {
      key: 'complement',
      type: 'string',
      label: 'Complemento',
      description:
        'Se o local de entrega do cartão é o colaborador e se o endereço do colaborador tiver complemento, este campo é obrigatório',
      alternateMatches: [
        'Complemento (obrigatório se o local da entrega é no endererço do colaborador, até 30 caracteres)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          regex: '^.{0,30}$',
          errorMessage: 'O complemento pode ter no máximo 30 caracteres',
        },
      ],
    },
    {
      key: 'district',
      type: 'string',
      label: 'Bairro',
      description:
        'Se o local de entrega do cartão é o colaborador, este campo é obrigatório',
      alternateMatches: [
        'Bairro (obrigatório se o local da entrega é no endereço do colaborador, até 30 caracteres)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          regex: '^.{0,30}$',
          errorMessage: 'O bairro pode ter no máximo 30 caracteres',
        },
        {
          validate: 'require_with',
          fields: [
            'zipCode',
            'address',
            'number',
            'complement',
            'city',
            'state',
          ],
          errorMessage:
            'Você precisa preencher o bairro ou apagar o resto do endereço',
        },
      ],
    },
    {
      key: 'city',
      type: 'string',
      label: 'Cidade',
      description:
        'Se o local de entrega do cartão é o colaborador, este campo é obrigatório',
      alternateMatches: [
        'Cidade',
        'Cidade (obrigatório se o local da entrega é no endererço do colaborador, até 30 caracteres)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          regex: '^.{0,30}$',
          errorMessage: 'A cidade pode ter no máximo 30 caracteres',
        },
        {
          validate: 'require_with',
          fields: [
            'zipCode',
            'address',
            'number',
            'complement',
            'district',
            'state',
          ],
          errorMessage:
            'Você precisa preencher a cidade ou apagar o resto do endereço',
        },
      ],
    },
    {
      key: 'state',
      type: 'select',
      label: 'Estado',
      description:
        'Se o local de entrega do cartão é o colaborador, este campo é obrigatório',
      alternateMatches: [
        'Estado',
        'UF',
        'Estado (obrigatório se o local da entrega é no endererço do colaborador)',
      ],
      requireMapping: true,
      validators: [
        {
          validate: 'require_with',
          fields: [
            'zipCode',
            'address',
            'number',
            'complement',
            'district',
            'city',
          ],
          errorMessage:
            'Você precisa preencher o estado ou apagar o resto do endereço',
        },
      ],
      selectOptions: UFs.map((uf) => ({
        label: uf.label,
        value: uf.value,
      })),
    },
    {
      key: 'packageId',
      type: 'select',
      description:
        'Nome do pacote de benefícios a ser atribuído conforme o que está cadastrado no Portal Raiô, veja as instruções de preenchimento na guia do navegador que foi aberta ao lado',
      selectOptions: benefitPackages.map((benefitPackage) => ({
        label: benefitPackage.name,
        value: benefitPackage.id,
      })),
      requireMapping: benefitPackages.length > 1 ? true : false,
      label: 'Nome do pacote de benefícios',
      alternateMatches: [
        'Nome do pacote de benefícios (opcional, veja as instruções no Portal)',
        'Nome do pacote de benefícios',
      ],
    },
    {
      key: 'socialName',
      type: 'string',
      label: 'Nome social do colaborador',
      description:
        'Nome que a pessoa escolhe usar, diferente do nome no documento oficial, para melhor representar sua identidade',
      requireMapping: true,
      validators: [
        {
          validate: 'regex_match',
          // regex to only allow letters, spaces and hyphens
          regex: '^[a-zA-ZÀ-ú- ]+$',
          errorMessage: 'O nome social só pode conter letras',
        },
        {
          validate: 'regex_match',
          // regex to check if the name has at least 2 words
          regex: '^\\S+\\s+\\S+',
          errorMessage: 'Informe o nome social e pelo menos um sobrenome',
        },
      ],
    },
  ];
}
