import { Box, Text } from '@chakra-ui/react';

import { BulbIcon } from '../../../../assets/customIcons';
import { Semibold } from '../../../../components/Typography';

const RechargeHelpProcessingColabs = () => {
  return (
    <Box
      background="light.orange"
      maxHeight={'75px'}
      marginRight={'100px'}
      display="flex"
      alignItems="center"
      padding={{ base: '12px 16px', md: '12px 16px', lg: '10px' }}
      borderRadius="lg"
      fontSize="20px"
      flexBasis="fit-content"
    >
      <BulbIcon mr={3} />
      <Text whiteSpace={'nowrap'}>
        Para criar uma recarga, <Semibold>aguarde o processamento</Semibold>
      </Text>
    </Box>
  );
};

export default RechargeHelpProcessingColabs;
