import { ElementType, ReactNode } from 'react';
import {
  Box,
  Card,
  CardProps,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  Spinner,
  Stack,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

type InfoDisplayWithIconProps = StackProps & {
  label?: string;
  isLoading?: boolean;
  icon: ElementType;
  children: ReactNode;
  cardProps?: CardProps;
  flexProps?: FlexProps;
  iconProps?: IconProps;
  textProps?: TextProps;
};

const InfoDisplayWithIcon = ({
  label,
  isLoading = false,
  icon,
  children,
  cardProps,
  flexProps,
  iconProps,
  textProps,
  ...stackProps
}: InfoDisplayWithIconProps) => {
  return (
    <Stack spacing={4} {...stackProps}>
      {label && (
        <Text fontWeight={600} lineHeight="130%">
          {label}
        </Text>
      )}
      <Card
        backgroundColor="gray.100"
        boxShadow="none"
        height="56px"
        {...cardProps}
      >
        <Flex
          height="100%"
          alignItems="center"
          padding={4}
          gap={4}
          {...flexProps}
        >
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <Icon as={icon} color="brand.primary" {...iconProps} />
              <Box fontSize="lg" lineHeight="130%" {...textProps}>
                {children}
              </Box>
            </>
          )}
        </Flex>
      </Card>
    </Stack>
  );
};

export default InfoDisplayWithIcon;
