import {
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  Text,
  useClipboard,
} from '@chakra-ui/react';

import { FaWhatsapp } from 'react-icons/fa';
import { FiCopy, FiCheckCircle } from 'react-icons/fi';

import {
  SUPPORT_EMAIL_ADDRESS,
  SUPPORT_WHATSAPP_NUMBER,
  SUPPORT_WHATSAPP_URL,
} from '../../constants/general';

type ContactFormsProps = {
  withoutDescription?: boolean;
};

const ContactForms = ({ withoutDescription }: ContactFormsProps) => {
  const { onCopy, hasCopied } = useClipboard(SUPPORT_EMAIL_ADDRESS);

  return (
    <Flex direction="column" gap={6}>
      <Box>
        {!withoutDescription && (
          <Text fontSize="sm" lineHeight={1} marginBottom={2}>
            WhatsApp
          </Text>
        )}
        <Flex alignItems="start" gap={2}>
          <Icon as={FaWhatsapp} boxSize="20px" />
          <Link lineHeight={1.4} target="_blank" href={SUPPORT_WHATSAPP_URL}>
            {SUPPORT_WHATSAPP_NUMBER}
          </Link>
        </Flex>
      </Box>

      <Box>
        {!withoutDescription && (
          <Text fontSize="sm" lineHeight={1} marginBottom={2}>
            E-mail
          </Text>
        )}
        <Flex alignItems="start" gap={2}>
          <Text>{SUPPORT_EMAIL_ADDRESS}</Text>
          <IconButton
            onClick={onCopy}
            boxSize={6}
            variant="ghost"
            aria-label="copiar endereço de e-mail"
            icon={<Icon as={FiCopy} boxSize="20px" />}
          />
        </Flex>
        {hasCopied && (
          <Flex mt={6} alignItems="center" gap={2} color="feedback.success">
            <Icon as={FiCheckCircle} boxSize="20px" />
            <Text>E-mail copiado!</Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default ContactForms;
