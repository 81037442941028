import { Tooltip, TooltipProps } from '@chakra-ui/react';

const CustomTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      hasArrow
      background="gray.900"
      padding="16px"
      borderRadius="4px"
      fontWeight={600}
      fontSize="md"
      lineHeight={1.2}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
