import {
  Box,
  Stepper,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Text,
} from '@chakra-ui/react';
import { Semibold } from '../../../../components/Typography';

const steps = [
  {
    title: (
      <Text>
        <Semibold>Veja</Semibold> se você possui{' '}
        <Semibold>cartões sem nome disponíveis</Semibold> para entrega na sua
        gaveta
      </Text>
    ),
  },
  {
    title: (
      <Text>
        Entregue um <Semibold>cartão sem nome</Semibold> para o colaborador
      </Text>
    ),
  },
  {
    title: (
      <Text>
        <Semibold>Avise o colaborador</Semibold> que ele deverá fazer a{' '}
        <Semibold>ativação</Semibold> do cartão pelo{' '}
        <Semibold>aplicativo Raiô Benefícios</Semibold>
      </Text>
    ),
  },
];

const NoNameCardSteps = () => {
  return (
    <Stepper index={-1} orientation="vertical" gap="0">
      {steps.map((step, index) => {
        return (
          <Step key={index}>
            <StepIndicator
              bg="gray.100"
              border="2px solid"
              borderColor="gray.500"
              fontWeight={600}
            >
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box marginTop={1} marginLeft={1} height="fit-content">
              {step.title}
            </Box>
            <Box minHeight="100px">
              <StepSeparator />
            </Box>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default NoNameCardSteps;
