import { UserHeartIcon } from '../../../../assets/customIcons';
import { Card, CardProps, Text } from '@chakra-ui/react';

const EmployeeEmptyState = (props: CardProps) => {
  return (
    <Card
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      padding={8}
      {...props}
    >
      <UserHeartIcon boxSize="56px" color="brand.primary" />
      <Text fontSize="40px" fontWeight={700}>
        Já Raiô!
      </Text>
      <Text fontSize="32px">Bora cadastrar um colaborador?</Text>
    </Card>
  );
};

export default EmployeeEmptyState;
