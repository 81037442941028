import { Button, Card, Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { spreadsheetTemplate } from '../../../../constants/general';
import { FiDownload, FiFileText, FiSave } from 'react-icons/fi';
import { Semibold } from '../../../../components/Typography';

const SpreadsheetInstructionsCard = () => {
  return (
    <Card padding="32px" width="100%" maxWidth="495px" height="fit-content">
      <VStack align="start" spacing={6}>
        <VStack align="start" spacing={4}>
          <Text fontSize="20px" fontWeight="500">
            Modelo de planilha
          </Text>
          <Text lineHeight="1.3">
            Você pode baixar o modelo de planilha Raiô ou importar um modelo
            próprio
          </Text>
        </VStack>

        <Button
          as={Link}
          download
          href={spreadsheetTemplate}
          variant="ghost"
          fontWeight={600}
          width="fit-content"
          background="gray.100"
          padding="12px 16px"
          alignItems="center"
          gap={4}
          leftIcon={<FiDownload size="24px" />}
          iconSpacing={1}
        >
          Modelo de planilha Raiô
        </Button>

        <VStack
          align="start"
          padding={6}
          spacing={6}
          width="100%"
          backgroundColor="light.orange"
          borderRadius="8px"
          color="black"
        >
          <Flex alignItems="center" gap={6}>
            <Icon as={FiSave} boxSize="24px" color="brand.aux08" />
            <Text lineHeight="130%">
              <Semibold>Salve</Semibold> o arquivo no{' '}
              <Semibold>formato XLSX</Semibold> (padrão do Excel){' '}
              <Semibold>ou CSV UTF-8</Semibold>
            </Text>
          </Flex>
          <Flex alignItems="center" gap={6}>
            <Icon as={FiFileText} boxSize="24px" color="brand.aux08" />
            <Text>Dê o nome que você quiser ao arquivo</Text>
          </Flex>
        </VStack>
      </VStack>
    </Card>
  );
};

export default SpreadsheetInstructionsCard;
