import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import BenefitDisplay from '../../../components/BenefitDisplay';
import { BenefitCategories } from '../../../types/benefit';
import CurrencyInput, {
  CurrencyInputProps,
} from '../../../components/CurrencyInput';
import { NumberFormatBase, SourceInfo } from 'react-number-format';
import { formatCurrencyInCents } from '../../../utils/formatters/formatCurrency';
import { Semibold } from '../../../components/Typography';
import AddCurrentBalanceButton from './AddCurrentBalanceButton';

type BenefitRefundInputProps = {
  label: string;
  balance: number;
  isInvalid?: boolean;
  errorMessage?: string;
  handleClickRefund?: () => void;
  inputProps?: CurrencyInputProps;
};

const BenefitRefundInput = forwardRef<
  typeof NumberFormatBase,
  BenefitRefundInputProps
>((props, ref) => {
  const { label, balance, isInvalid, errorMessage, handleClickRefund } = props;

  return (
    <FormControl
      padding={4}
      isInvalid={isInvalid}
      backgroundColor="gray.75"
      borderRadius="lg"
      boxShadow="sm"
    >
      <Stack spacing={4}>
        <FormLabel>
          <BenefitDisplay
            benefit={
              {
                category: { name: label },
              } as BenefitCategories
            }
          />
        </FormLabel>
        <Box>
          <CurrencyInput
            ref={ref}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            {...props.inputProps}
          />
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </Box>
        <Flex gap={2} flexWrap="wrap">
          <Text lineHeight={1.3}>
            O saldo atual é{' '}
            <Semibold lineHeight={1.3}>
              {formatCurrencyInCents(balance)}
            </Semibold>
          </Text>
          <AddCurrentBalanceButton onClick={handleClickRefund} />
        </Flex>
      </Stack>
    </FormControl>
  );
});

export default BenefitRefundInput;
