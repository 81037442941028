import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import bg from '../assets/bg.svg';
import {
  MessageRequestPasswordChange,
  WelcomeMessageFirstAccess,
  WelcomeMessageLogin,
} from '../components/WelcomeMessage';
import { useAuthContext } from '../hooks/useAuthContext';
import LogoutButton from '../components/LogoutButton';
import CompanySelect from '../components/CompanySelect';
import { AuthUser } from '../types/auth';

type WelcomeProps = {
  variant?: 'login' | 'firstAccess' | 'passwordReset';
};

type FirstAccessLayoutProps = WelcomeProps & {
  children: React.ReactNode;
};

type FirstAccessHeaderProps = {
  user: AuthUser | null;
};

const Welcome = ({ variant }: WelcomeProps) => {
  if (variant === 'login') {
    return <WelcomeMessageLogin />;
  }
  if (variant === 'firstAccess') {
    return <WelcomeMessageFirstAccess />;
  }
  if (variant === 'passwordReset') {
    return <MessageRequestPasswordChange />;
  }
};

const FirstAccessHeader = ({ user }: FirstAccessHeaderProps) => {
  return (
    <Flex
      width="100%"
      paddingX={10}
      paddingY={6}
      position="absolute"
      top={0}
      left={0}
      justifyContent="flex-end"
      alignItems="center"
      gap={4}
    >
      <Flex alignItems="center" gap={4}>
        <CompanySelect />
        <Text lineHeight={1} height="fit-content" marginX={3}>
          {user?.fullName}
        </Text>
        <Divider
          orientation="vertical"
          height="30px"
          borderWidth="1px"
          borderColor="gray.800"
        />
        <LogoutButton />
      </Flex>
    </Flex>
  );
};

const FirstAccessLayout = ({
  children,
  variant = 'firstAccess',
}: FirstAccessLayoutProps) => {
  const { isAuthenticated, user } = useAuthContext();
  const isAuthed = isAuthenticated();
  return (
    <Stack
      minHeight="100vh"
      backgroundImage={bg}
      backgroundRepeat="no-repeat"
      backgroundPosition={{ base: 'center', lg: 'right' }}
      backgroundSize={{ base: 'cover', lg: 'contain' }}
      justifyContent="center"
      position="relative"
    >
      {isAuthed && <FirstAccessHeader user={user} />}
      <Flex
        padding={20}
        justifyContent="space-around"
        gap={{ base: 10, lg: 2 }}
        alignItems="center"
        direction={{ base: 'column', lg: 'row' }}
        mt={4}
      >
        <Welcome variant={variant} />
        {children}
      </Flex>
    </Stack>
  );
};

export default FirstAccessLayout;
