import { Flex, Box, Button, Text } from '@chakra-ui/react';
import AlertCard from '../../../../components/AlertCard';
import { EmployeeInfoStatusEnum } from '../../../../types/employee';
import { useNavigate } from 'react-router-dom';
import { useEnableEmployee } from '../../../../api/employees';

type DocumentAlertProps = {
  status: EmployeeInfoStatusEnum;
  collaboratorId: string;
};

const DocumentAlert = ({ status, collaboratorId }: DocumentAlertProps) => {
  const navigate = useNavigate();
  const enableEmployeeMutation = useEnableEmployee();

  async function handleEnableEmployee(employeeId: string) {
    enableEmployeeMutation.mutateAsync(
      {
        employeeId,
      },
      {
        onSuccess: () => {
          navigate(`/colaboradores/editar-colaborador/${employeeId}`);
        },
      }
    );
  }
  return (
    <AlertCard>
      {status === EmployeeInfoStatusEnum.ACTIVE && (
        <Flex gap={6}>
          <Text>
            Já existe um{' '}
            <Text as="span" fontWeight={600}>
              colaborador cadastrado
            </Text>{' '}
            com este CPF
          </Text>
          <Box>
            <Button
              variant="link"
              color="brand.aux08"
              fontWeight={600}
              padding={0}
              onClick={() => {
                navigate(`/colaboradores/editar-colaborador/${collaboratorId}`);
              }}
            >
              Editar cadastro do colaborador
            </Button>
          </Box>
        </Flex>
      )}
      {status === EmployeeInfoStatusEnum.PROCESSING && (
        <Flex gap={6}>
          <Text>
            O cadastro deste colaborador já foi feito e está{' '}
            <Text as="span" fontWeight={600}>
              em processamento
            </Text>{' '}
          </Text>
          <Box>
            <Button
              variant="link"
              color="brand.aux08"
              fontWeight={600}
              padding={0}
              onClick={() => {
                navigate(`/colaboradores`);
              }}
            >
              Visualizar colaboradores em processamento
            </Button>
          </Box>
        </Flex>
      )}
      {status === EmployeeInfoStatusEnum.DISABLED && (
        <Flex gap={6}>
          <Box>
            <Text>
              Este colaborador foi{' '}
              <Text as="span" fontWeight={600}>
                desligado
              </Text>{' '}
              pela empresa, mas já possui um cadastro
            </Text>
          </Box>
          <Box>
            <Button
              variant="link"
              color="brand.aux08"
              fontWeight={600}
              width={'auto'}
              padding={0}
              onClick={() => {
                handleEnableEmployee(collaboratorId);
              }}
            >
              Reativar colaborador e editar dados cadastrais
            </Button>
          </Box>
        </Flex>
      )}
    </AlertCard>
  );
};

export default DocumentAlert;
