import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { FiCalendar, FiCheckCircle, FiCopy, FiFileText } from 'react-icons/fi';
import { RechargeResultResponse } from '../../../../types/recharge';
import { format } from 'date-fns';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { IMaskInput } from 'react-imask';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';

type RechargeInfoBoletoProps = {
  rechargeInfo: LocationResultResponse;
  statusIsProcessed: boolean;
};

type LocationResultResponse = RechargeResultResponse & {
  changeDueDate?: boolean;
  isEdit?: boolean;
};

const rechargeTitle = (isEdit: boolean, isChangeDueDate: boolean) => {
  if (isEdit) {
    if (isChangeDueDate) {
      return 'Recarga editada com sucesso, um novo Boleto foi gerado!';
    } else {
      return 'Recarga editada com sucesso!';
    }
  } else {
    return 'Recarga criada com sucesso!';
  }
};

const RechargeInfo = ({
  isEdit,
  isChangeDueDate,
}: {
  isEdit: boolean;
  isChangeDueDate: boolean;
}) => {
  if (isEdit && isChangeDueDate) {
    return (
      <Flex marginBottom={6}>
        <Text>
          Desconsidere o boleto gerado quando a recarga foi criada e escaneie o
          <Text as="span" fontWeight={600}>
            {' '}
            novo código de barras
          </Text>{' '}
          do boleto abaixo com o aplicativo do seu banco para realizar o
          pagamento da recarga:
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex marginBottom={6}>
        <Text>
          Escaneie a imagem do{' '}
          <Text as="span" fontWeight={600}>
            código de barras
          </Text>{' '}
          no boleto abaixo com o aplicativo do seu banco para realizar o
          pagamento da recarga:
        </Text>
      </Flex>
    );
  }
};

const RechargeInfoBoleto = ({
  rechargeInfo,
  statusIsProcessed,
}: RechargeInfoBoletoProps) => {
  const invoiceDigitableLine = rechargeInfo.invoice?.digitableLine;

  const isEdit = rechargeInfo?.isEdit || false;
  const isChangeDueDate = rechargeInfo?.changeDueDate || false;

  const { hasCopied, onCopy } = useClipboard(invoiceDigitableLine || '');

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  return (
    <Box padding={8} width="100%" background="white" borderRadius="8px">
      <Flex alignItems="center" mb={8}>
        <Icon as={FiCheckCircle} boxSize="32px" color="feedback.success" />
        <Text fontSize="20px" fontWeight={500} ml={2}>
          {rechargeTitle(isEdit, isChangeDueDate)}
        </Text>
      </Flex>

      {statusIsProcessed ? (
        <Box>
          <Text fontSize="32px" fontWeight={600} mb="32px">
            {formatCurrencyInCents(rechargeInfo.amount)}
          </Text>
          <>
            <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
              Data de crédito
            </Text>
            <InputGroup mb="16px">
              <InputLeftElement w="54px" h="54px" top="0.7px" cursor="pointer">
                <Flex alignItems="center" color="brand.aux03">
                  <Icon as={FiCalendar} boxSize="20px" />
                </Flex>
              </InputLeftElement>
              {rechargeInfo.invoice?.scheduleDate && (
                <Input
                  isReadOnly
                  defaultValue={format(
                    rechargeInfo.invoice?.scheduleDate,
                    'dd/MM/yyyy'
                  )}
                  pl="44px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  background={'gray.100'}
                  fontWeight={600}
                />
              )}
            </InputGroup>
          </>
        </Box>
      ) : (
        <>
          <RechargeInfo isEdit={isEdit} isChangeDueDate={isChangeDueDate} />
          {rechargeInfo.invoice?.pdf && (
            <object
              title="boleto"
              data={`data:application/pdf;base64,${rechargeInfo.invoice.pdf}#zoom=100;`}
              type="application/pdf"
              width="100%"
              height="526px"
            />
          )}
          <Box mt="32px" mb="16px">
            <Flex mb="16px">
              <Text>
                Se preferir, copie o{' '}
                <Text as="span" fontWeight={600}>
                  código do boleto
                </Text>{' '}
                para pagamento:
              </Text>
            </Flex>

            <InputGroup>
              <InputRightElement
                w="54px"
                h="54px"
                top="0.7px"
                right="29px"
                cursor="pointer"
                onClick={onCopy}
              >
                {hasCopied ? (
                  <Flex alignItems="center" color="feedback.success">
                    <Icon as={FiCheckCircle} boxSize="20px" />
                    <Link textDecor="underline" fontWeight={600} ml={1}>
                      Copiado!
                    </Link>
                  </Flex>
                ) : (
                  <Flex alignItems="center" color="brand.aux08">
                    <Icon as={FiCopy} boxSize="22px" />
                    <Link textDecor="underline" fontWeight={600} ml={1}>
                      Copiar
                    </Link>
                  </Flex>
                )}
              </InputRightElement>
              <Input
                defaultValue={invoiceDigitableLine}
                as={IMaskInput}
                mask="00000.00000 00000.000000 00000.000000 0 00000000000000"
                mb="32px"
                pr="105px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              />
            </InputGroup>
            <Text fontSize="32px" fontWeight={600} mb="32px">
              {formatCurrencyInCents(rechargeInfo.amount)}
            </Text>
            <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
              Dados do beneficiário
            </Text>
            <InputGroup mb="24px">
              <InputLeftElement
                w="54px"
                h="54px"
                top="0.7px"
                cursor="pointer"
                mb="32px"
              >
                <Flex alignItems="center" color="brand.aux03">
                  <Icon as={FiFileText} boxSize="20px" />
                </Flex>
              </InputLeftElement>
              <Flex
                alignItems="center"
                pl="32px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                bg="gray.100"
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="md"
                minHeight="54px"
                mb="32px"
                flexGrow={1}
              >
                <Text
                  fontSize={'16px'}
                  fontWeight="600"
                  lineHeight="130%"
                  ml="12px"
                >
                  {paymentBeneficiary?.name}{' '}
                  <Text
                    as="span"
                    fontSize={'16px'}
                    fontWeight="400"
                    lineHeight="130%"
                  >
                    ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
                  </Text>
                </Text>
              </Flex>
            </InputGroup>

            <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
              Data de vencimento do boleto
            </Text>
            <InputGroup bg="gray.100" mb="32px" borderRadius={'8px'}>
              <InputLeftElement w="54px" h="54px" top="0.7px" cursor="pointer">
                <Flex alignItems="center" color="brand.aux03">
                  <Icon as={FiFileText} boxSize="20px" />
                </Flex>
              </InputLeftElement>
              <Input
                isReadOnly
                defaultValue={
                  rechargeInfo.invoice?.dueDate
                    ? format(
                        new Date(rechargeInfo.invoice?.dueDate),
                        'dd/MM/yyyy'
                      )
                    : ' '
                }
                pl="44px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                fontWeight={600}
                bg="gray.100"
              />
            </InputGroup>
            <Flex>
              <Text>
                Como definido na criação da recarga, o valor será creditado aos
                colaboradores no dia{' '}
                <Text as="span" fontWeight={600}>
                  {format(
                    new Date(rechargeInfo.invoice?.scheduleDate ?? ''),
                    'dd/MM/yyyy'
                  )}
                  .
                </Text>
              </Text>
            </Flex>
          </Box>
        </>
      )}
    </Box>
  );
};

export default RechargeInfoBoleto;
