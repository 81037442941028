import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Text,
  useClipboard,
  Button,
} from '@chakra-ui/react';
import { BenefitPackage } from '../../../../types/benefit';
import BenefitDisplay from '../../../../components/BenefitDisplay';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { FiCheckCircle, FiCopy } from 'react-icons/fi';

type BenefitCardProps = BoxProps & {
  benefit: BenefitPackage;
};

const EmployeeImportBenefitCard = ({
  benefit,
  ...boxProps
}: BenefitCardProps) => {
  const { hasCopied, onCopy } = useClipboard(benefit.name ?? '');
  return (
    <Box
      width="288px"
      maxWidth="288px"
      {...boxProps}
      backgroundColor="gray.100"
      padding={6}
      borderRadius="lg"
    >
      <Flex justifyContent="space-between" alignItems="start">
        <Text fontWeight={700}>{benefit.name}</Text>
        {hasCopied ? (
          <Flex alignItems="center" color="feedback.success">
            <Icon as={FiCheckCircle} boxSize="22px" />
            <Button variant="link" padding={0} fontWeight={600} ml={2}>
              Copiado!
            </Button>
          </Flex>
        ) : (
          <Flex alignItems="center" color="brand.aux08" onClick={onCopy}>
            <Icon as={FiCopy} boxSize="22px" />
            <Button variant="link" padding={0} fontWeight={600} ml={2}>
              Copiar
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex
        direction="column"
        flexWrap="wrap"
        gap={3}
        maxHeight="260px"
        marginTop={4}
        width="100%"
        fontSize="14px"
      >
        {benefit.package_categories.map((benefit) => (
          <BenefitDisplay
            key={benefit.id}
            benefit={benefit}
            textProps={{
              maxWidth: '120px',
            }}
          >
            <Text marginTop={1} marginLeft="18px" lineHeight={1.2}>
              {formatCurrencyInCents(benefit.value)}{' '}
              {benefit.periodType === 'DAILY' && 'por dia'}
            </Text>
          </BenefitDisplay>
        ))}
      </Flex>
    </Box>
  );
};

export default EmployeeImportBenefitCard;
