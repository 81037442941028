import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { FiCheckCircle, FiCopy, FiFileText } from 'react-icons/fi';
import { format } from 'date-fns';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { IMaskInput } from 'react-imask';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';
import { CreateCreditOrderResponse } from '../../../../types/credits';

type OrderInfoBoletoProps = {
  orderInfo: CreateCreditOrderResponse;
};

const InputInfoComponent = ({ defaultValue }: { defaultValue: string }) => {
  return (
    <InputGroup background="gray.100" borderRadius={'8px'}>
      <InputLeftElement width="54px" height="54px" top="0.7px" cursor="pointer">
        <Flex alignItems="center" color="brand.aux03">
          <Icon as={FiFileText} boxSize="20px" />
        </Flex>
      </InputLeftElement>
      <Input
        isReadOnly
        defaultValue={defaultValue}
        paddingLeft="44px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        fontWeight={600}
        background="gray.100"
      />
    </InputGroup>
  );
};

const OrderInfoBoleto = ({ orderInfo }: OrderInfoBoletoProps) => {
  const invoiceDigitableLine = orderInfo.paymentCode;

  const { hasCopied, onCopy } = useClipboard(invoiceDigitableLine || '');

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  return (
    <Box padding={8} width="100%" background="white" borderRadius="8px">
      <Flex alignItems="center" marginBottom={8}>
        <Icon as={FiCheckCircle} boxSize="24px" color="feedback.success" />
        <Text fontSize="20px" fontWeight={500} marginLeft={2}>
          Créditos Raiô solicitados com sucesso!
        </Text>
      </Flex>

      <Flex marginBottom={6}>
        <Text>
          Escaneie o{' '}
          <Text as="span" fontWeight={600}>
            código de barras
          </Text>{' '}
          no boleto abaixo com o aplicativo do seu banco para realizar o
          pagamento do pedido:
        </Text>
      </Flex>

      {orderInfo.paymentFileBase64 && (
        <object
          title="boleto"
          data={`data:application/pdf;base64,${orderInfo.paymentFileBase64}#zoom=100;`}
          type="application/pdf"
          width="100%"
          height="526px"
        />
      )}
      <Box marginTop={8} marginBottom={4}>
        <Flex marginBottom={4}>
          <Text>
            Se preferir, copie o{' '}
            <Text as="span" fontWeight={600}>
              código do boleto
            </Text>{' '}
            para pagamento:
          </Text>
        </Flex>

        <InputGroup>
          <InputRightElement
            width="54px"
            height="54px"
            top="0.7px"
            right="29px"
            cursor="pointer"
            onClick={onCopy}
          >
            {hasCopied ? (
              <Flex alignItems="center" color="feedback.success">
                <Icon as={FiCheckCircle} boxSize="20px" />
                <Link textDecor="underline" fontWeight={600} ml={1}>
                  Copiado!
                </Link>
              </Flex>
            ) : (
              <Flex alignItems="center" color="brand.aux08">
                <Icon as={FiCopy} boxSize="22px" />
                <Link textDecor="underline" fontWeight={600} ml={1}>
                  Copiar
                </Link>
              </Flex>
            )}
          </InputRightElement>
          <Input
            defaultValue={invoiceDigitableLine}
            as={IMaskInput}
            mask="00000.00000 00000.000000 00000.000000 0 00000000000000"
            marginBottom={8}
            paddingRight="105px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          />
        </InputGroup>
        <Text fontSize="32px" fontWeight={600} marginBottom={8}>
          {formatCurrencyInCents(orderInfo.amount)}
        </Text>

        <Text fontSize={'16px'} fontWeight={'600'} marginBottom={2}>
          Dados do beneficiário
        </Text>
        <InputGroup marginBottom={8}>
          <InputLeftElement
            width="54px"
            height="54px"
            top="0.7px"
            cursor="pointer"
            marginBottom={8}
          >
            <Flex alignItems="center" color="brand.aux03">
              <Icon as={FiFileText} boxSize="20px" />
            </Flex>
          </InputLeftElement>
          <Flex
            alignItems="center"
            paddingLeft="32px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            background="gray.100"
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius="md"
            minHeight="54px"
            flexGrow={1}
          >
            <Text
              fontSize={'16px'}
              fontWeight="600"
              lineHeight="130%"
              marginLeft="12px"
            >
              {paymentBeneficiary?.name}{' '}
              <Text
                as="span"
                fontSize={'16px'}
                fontWeight="400"
                lineHeight="130%"
              >
                ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
              </Text>
            </Text>
          </Flex>
        </InputGroup>

        <Text fontSize={'16px'} fontWeight={'600'} marginBottom={2}>
          Data de vencimento do boleto
        </Text>

        <InputInfoComponent
          defaultValue={
            orderInfo.dates.due
              ? format(new Date(orderInfo.dates.due), 'dd/MM/yyyy')
              : ' '
          }
        />

        <Text
          fontSize={'16px'}
          fontWeight={'600'}
          marginBottom={2}
          marginTop={4}
        >
          Data de disponibilização dos créditos prevista
        </Text>
        <InputInfoComponent
          defaultValue={
            orderInfo.dates.credit
              ? format(new Date(orderInfo.dates.credit), 'dd/MM/yyyy')
              : ' '
          }
        />
      </Box>
    </Box>
  );
};

export default OrderInfoBoleto;
