import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Icon,
  StackProps,
  Text,
  VStack,
  useCheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react';
import {
  SEND_CARD_OPTIONS_ENUM as OPTIONS,
  employeeInfoSchema,
  sendCardFormSchema,
} from '../../../../types/schemas/employeeRegistrationSchema';
import { z } from 'zod';
import ConnectForm from '../../../../components/ConnectForm';
import { ErrorMessage } from '@hookform/error-message';
import { useGetCompanyAddressesByType } from '../../../../api/address';
import CheckboxCard from '../../../../components/CheckboxCard';
import { BuildingIcon, HomeIcon } from '../../../../assets/customIcons';
import { Address } from '../../../../types/address';
import { LuPlusCircle } from 'react-icons/lu';
import CreatableAddressModal from '../CreatableAddressModal';
import Spinner from '../../../../components/Spinner';
import { useCallback } from 'react';

type FormValues = z.infer<
  typeof sendCardFormSchema & typeof employeeInfoSchema
>;

const employeeAddressId = crypto.randomUUID();

type AddressCardContentProps = StackProps & {
  address: Partial<Address>;
};

type SendCardFormProps = {
  onCreateCollaboratorAddress?: (
    address: Partial<Address>
  ) => Promise<string | undefined>;
};

const AddressCardContent = ({ address, ...props }: AddressCardContentProps) => {
  return (
    <VStack align="flex-start" {...props}>
      <Icon
        as={address.entityType === 'COMPANY' ? BuildingIcon : HomeIcon}
        boxSize="24px"
      />
      <Text fontWeight={600} marginY={4}>
        {address.name}
      </Text>
      <Text>
        {address.address}
        {address.number ? `, ${address.number}` : ''}
      </Text>
      <Text>
        {address.district},{' '}
        {address.zipCode &&
          address.zipCode.slice(0, 5) + '-' + address.zipCode.slice(5)}
      </Text>
      <Text>
        {address.city}/{address.state}
      </Text>
      <Text>{address.complement}</Text>
    </VStack>
  );
};

const SendCardForm = ({ onCreateCollaboratorAddress }: SendCardFormProps) => {
  const addressModal = useDisclosure();

  const { data: companyAddressData, isPending } = useGetCompanyAddressesByType({
    type: OPTIONS.Enum.COMPANY,
    enabled: true,
  });

  const companyAddressList = companyAddressData?.data || [];

  const { value: selectedOptions, setValue: setCheckboxValue } =
    useCheckboxGroup();

  return (
    <ConnectForm<FormValues>>
      {({ setValue, getValues, trigger, watch }) => {
        const employeeAddress = {
          ...getValues('collaboratorData.address'),
          id: employeeAddressId,
          entityType: 'PERSON',
          name: 'Colaborador',
        } as Address;

        const hasFilledEmployeeAddress = !!employeeAddress?.zipCode;

        const { companyAddressId, deliveryType } = watch();

        const isAddressVisible = watch('isAddressVisible');

        // custom function to allow only one checkbox to be checked at a time
        // it is needed because the selectors should behave like radio buttons but allowing to uncheck
        function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
          const nextValue = e.target.value;
          const checked = e.target.checked;
          const address =
            companyAddressList.find((address) => address.id === nextValue) ||
            employeeAddress;

          if (checked) {
            setCheckboxValue([nextValue]);
            if (address?.entityType === 'COMPANY') {
              setValue('companyAddressId', address.id, { shouldDirty: true });
              setValue('deliveryType', OPTIONS.Enum.COMPANY, {
                shouldDirty: true,
              });
            }
            if (address?.entityType === 'PERSON') {
              setValue('companyAddressId', undefined, { shouldDirty: true });
              setValue('deliveryType', OPTIONS.Enum.RESIDENTIAL, {
                shouldDirty: true,
              });
              setValue('isAddressVisible', false);
            }
          } else {
            setCheckboxValue([]);
            setValue('companyAddressId', undefined, { shouldDirty: true });
            setValue('deliveryType', undefined, { shouldDirty: true });
          }
          trigger('deliveryType');
        }
        function handleAddressVisibilityChange() {
          setValue('isAddressVisible', !isAddressVisible);
        }
        const setEmployeeAddress = (address: Partial<Address>) => {
          setValue('collaboratorData.address', address, {
            shouldDirty: true,
          });
        };

        if (isPending) {
          return <Spinner />;
        }

        return (
          <Box>
            {deliveryType === 'COMPANY' && (
              <Box marginBottom={8}>
                <Checkbox
                  size="lg"
                  cursor="pointer"
                  borderRadius="md"
                  boxShadow="md"
                  backgroundColor={'gray.100'}
                  padding={4}
                  _checked={{
                    borderColor: 'orange.500',
                    boxShadow: 'none',
                    borderWidth: '2px',
                    backgroundColor: 'light.orange',
                  }}
                  _focus={{
                    boxShadow: 'outline',
                  }}
                  onMouseEnter={undefined}
                  onMouseLeave={undefined}
                  onChange={handleAddressVisibilityChange}
                  isChecked={isAddressVisible}
                >
                  <Text
                    fontSize={'16px'}
                    fontWeight={400}
                    lineHeight={'130%'}
                    marginLeft={'8px'}
                  >
                    Selecione esta opção para que o endereço de entrega seja
                    exibido no Aplicativo Raiô para o colaborador
                  </Text>
                </Checkbox>
              </Box>
            )}
            <ErrorMessage
              name="deliveryType"
              render={({ message }) => (
                <Text color="feedback.alert" marginBottom={4}>
                  {message}
                </Text>
              )}
            />
            <Flex gap={6} flexWrap="wrap">
              <CheckboxGroup>
                {hasFilledEmployeeAddress && (
                  <CheckboxCard
                    value={employeeAddress.id}
                    onChange={handleCheckboxChange}
                    isChecked={deliveryType === 'RESIDENTIAL'}
                    width="300px"
                    height="300px"
                  >
                    <AddressCardContent address={employeeAddress} />
                  </CheckboxCard>
                )}
                {companyAddressList.map((address) => (
                  <CheckboxCard
                    key={address.id}
                    value={address.id}
                    onChange={handleCheckboxChange}
                    isChecked={
                      deliveryType === 'COMPANY' &&
                      address.id === companyAddressId
                    }
                    width="300px"
                    height="300px"
                  >
                    <AddressCardContent address={address} />
                  </CheckboxCard>
                ))}
              </CheckboxGroup>
              <Button
                variant="ghost"
                cursor="pointer"
                border="1.5px solid"
                borderColor="brand.primary"
                borderRadius="8px"
                width="300px"
                height="300px"
                padding={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={6}
                color="brand.primary"
                onClick={addressModal.onOpen}
              >
                <LuPlusCircle
                  size="40px"
                  color="currentColor"
                  strokeWidth={'1px'}
                />
                <Text
                  whiteSpace="normal"
                  fontSize="16px"
                  fontWeight={600}
                  textAlign="center"
                >
                  {hasFilledEmployeeAddress
                    ? 'Cadastrar novo endereço da empresa'
                    : 'Cadastrar novo endereço'}
                </Text>
              </Button>

              <CreatableAddressModal
                isOpen={addressModal.isOpen}
                onClose={addressModal.onClose}
                hasFilledEmployeeAddress={hasFilledEmployeeAddress}
                setEmployeeAddress={setEmployeeAddress}
                onCreateCollaboratorAddress={onCreateCollaboratorAddress}
              />
            </Flex>
          </Box>
        );
      }}
    </ConnectForm>
  );
};

export default SendCardForm;
