import { isoDateRegex } from '../validations/regex';

// format and return date in format 'dd/MM/yyyy' from an iso string
export function getDayMonthYearFromISO(isoDate: string): string {
  if (!isoDateRegex.test(isoDate)) {
    return '';
  }

  const date = new Date(isoDate);
  return date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}
