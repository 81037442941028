import { Icon, IconProps } from '@chakra-ui/react';
import { SortDirection } from '@tanstack/react-table';
import { RxCaretDown, RxCaretSort, RxCaretUp } from 'react-icons/rx';

type SortDirectionIndicatorProps = {
  direction: SortDirection | false;
  iconProps?: IconProps;
};

const getSortingIcon = (
  direction: SortDirectionIndicatorProps['direction']
) => {
  if (direction === 'asc') {
    return RxCaretUp;
  }

  if (direction === 'desc') {
    return RxCaretDown;
  }

  return RxCaretSort;
};

const SortDirectionIndicator = ({
  direction,
  iconProps,
}: SortDirectionIndicatorProps) => {
  const SortingIcon = getSortingIcon(direction);

  return (
    <Icon
      as={SortingIcon}
      color="brand.primary"
      strokeWidth={1.1}
      {...iconProps}
    />
  );
};

export default SortDirectionIndicator;
