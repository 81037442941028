import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
} from '@chakra-ui/react';
type Step = {
  title: string;
  description: string;
};

type EmployeeRegistrationStepperProps = {
  activeStep: number;
  isNoNameCardsAllowed: boolean;
};

const EmployeeRegistrationStepper = ({
  activeStep,
  isNoNameCardsAllowed,
}: EmployeeRegistrationStepperProps) => {
  const steps = [
    { title: 'Dados do colaborador', description: 'Obrigatório' },
    {
      title: 'Escolher benefícios',
      description: 'Opcional',
    },
    {
      title: 'Enviar comunicação',

      description: 'Opcional',
    },
    {
      title: isNoNameCardsAllowed ? 'Escolher cartão' : 'Pedir cartão',
      description: 'Obrigatório',
    },
  ];
  return (
    <>
      <Stepper
        index={activeStep}
        orientation="horizontal"
        colorScheme="orange"
        marginBottom={5}
        size="md"
        width={'1024px'}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator fontWeight={600}>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box flexShrink="0">
              <>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default EmployeeRegistrationStepper;
