import { Link, LinkProps } from '@chakra-ui/react';
import { PRIVACY_POLICY_URL } from '../../constants/general';

const PrivacyPolicyLink = (props: LinkProps) => {
  return (
    <Link
      color="brand.aux08"
      textDecoration="underline"
      fontWeight={500}
      href={PRIVACY_POLICY_URL}
      target="_blank"
      {...props}
    >
      Política de Privacidade.
    </Link>
  );
};

export default PrivacyPolicyLink;
