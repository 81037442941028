import { isoDateRegex } from '../validations/regex';

export function getWeekdayFromISO(isoDate: string): string {
  if (!isoDateRegex.test(isoDate)) {
    return '';
  }

  const date = new Date(isoDate);
  const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });
  return weekDay;
}
