export const isValidCpf = (cpf: string): boolean => {
  if (!cpf) {
    return false;
  }

  const sanitizedCpf = cpf.replace(/\D/g, '');

  if (sanitizedCpf.length !== 11 || /^(.)\1{10}$/.test(sanitizedCpf)) {
    return false;
  }

  const calculateDigit = (slice: string, factor: number): number => {
    let sum = 0;
    for (let i = 0; i < slice.length; i++) {
      sum += parseInt(slice[i], 10) * (factor - i);
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const firstNineDigits = sanitizedCpf.slice(0, 9);
  const firstCheckDigit = calculateDigit(firstNineDigits, 10);

  if (firstCheckDigit !== parseInt(sanitizedCpf[9], 10)) {
    return false;
  }

  const secondCheckDigit = calculateDigit(sanitizedCpf.slice(0, 10), 11);

  return secondCheckDigit === parseInt(sanitizedCpf[10], 10);
};
