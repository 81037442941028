import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Box,
  Button,
  ButtonProps,
  ModalProps,
  useDisclosure,
  Flex,
  Progress,
} from '@chakra-ui/react';
import ContactForms from '../../../../components/ContactForms';
import { CardIcon } from '../../../../assets/customIcons';
import { Semibold } from '../../../../components/Typography';

type PendingActivationCardModaProps = ButtonProps & {
  modalProps?: ModalProps;
  buttonTittle?: string;
};

const PendingActivationCardModal = ({
  title,
  ...buttonProps
}: PendingActivationCardModaProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex alignItems={'center'}>
        <CardIcon
          width={'20px'}
          height={'20px'}
          marginRight={'2'}
          marginTop="4px"
          color="brand.aux08"
        />
        <Button
          variant={'link'}
          color="brand.aux08"
          fontWeight={400}
          padding={0}
          {...buttonProps}
          onClick={onOpen}
        >
          {title}
        </Button>
      </Flex>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody padding={8}>
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              flexDir={'column'}
            >
              <CardIcon width={'57px'} height={'47px'} />
              <ModalHeader
                fontWeight={700}
                marginBottom={'32px'}
                fontSize={'18px'}
              >
                Aguardando Ativação
              </ModalHeader>
            </Flex>
            <Box>
              <Stepper
                index={-1}
                orientation="vertical"
                gap={6}
                colorScheme="gray.500"
              >
                <Step>
                  <StepIndicator background={'bg'} marginRight={'24px'} gap={0}>
                    <StepNumber>
                      <Text as="span" fontWeight="600">
                        {' '}
                        1{' '}
                      </Text>
                    </StepNumber>
                    <StepSeparator>
                      <Progress height={'25px'} />
                    </StepSeparator>
                    <StepStatus />
                  </StepIndicator>
                  <StepDescription>
                    <Text as="span" color="gray.900" fontSize={'16px'}>
                      Se ainda não entregou, <Semibold>confira</Semibold> se
                      possui <Semibold>cartões sem nome</Semibold> disponíveis
                    </Text>
                  </StepDescription>
                </Step>

                <Step>
                  <StepIndicator marginRight={'24px'} gap={0}>
                    <StepNumber>
                      {' '}
                      <Text as="span" fontWeight="600">
                        {' '}
                        2{' '}
                      </Text>
                    </StepNumber>
                    <StepSeparator>
                      <Progress height={'25px'} />
                    </StepSeparator>
                    <StepStatus />
                  </StepIndicator>
                  <StepDescription>
                    <Text as="span" color="gray.900" fontSize={'16px'}>
                      Entregue um <Semibold>cartão sem nome </Semibold> para o
                      colaborador
                    </Text>
                  </StepDescription>
                </Step>
                <Step>
                  <StepIndicator marginRight={'24px'} gap={0}>
                    <StepNumber>
                      {' '}
                      <Text as="span" fontWeight="600">
                        {' '}
                        3{' '}
                      </Text>
                    </StepNumber>
                    <StepSeparator />
                    <StepStatus />
                  </StepIndicator>

                  <StepDescription>
                    <Text as="span" color="gray.900" fontSize={'16px'}>
                      <Semibold>Avise o colaborador </Semibold>
                      que ele deverá fazer a <Semibold>ativação</Semibold> do
                      cartão pelo{' '}
                      <Semibold>aplicativo Raiô Benefícios</Semibold>
                    </Text>
                  </StepDescription>
                </Step>
              </Stepper>
              <Text fontWeight="600" mt="6" mb="6">
                Você pode falar com nosso atendimento para solicitar mais
                cartões sem nome
              </Text>
              <ContactForms />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PendingActivationCardModal;
