import { Text } from '@chakra-ui/react';

const fontFamily = `'Lexend'`;

type TitleProps = {
  children: React.ReactNode;
};

const Title = ({ children }: TitleProps) => {
  return (
    <Text
      fontFamily={fontFamily}
      fontWeight="700"
      fontSize="24px"
      _after={{
        content: '""',
        display: 'block',
        width: '48px',
        height: '4px',
        backgroundColor: 'brand.primary',
        borderRadius: '4px',
        marginTop: '6px',
      }}
    >
      {children}
    </Text>
  );
};

export default Title;
