import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';
import { Semibold } from '../../../components/Typography';
import { EmployeeRefundFormValues } from '../types';
import { useRefundEmployeeBalance } from '../../../api/employees';

type RefundConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  formValues: EmployeeRefundFormValues & {
    collaboratorId: string;
  };
};

const RefundConfirmationModal = ({
  isOpen,
  onClose,
  formValues,
}: RefundConfirmationModalProps) => {
  function handleClose() {
    onClose();
  }

  const refundEmployeeBalance = useRefundEmployeeBalance();

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={700}>
          <Flex alignItems="center" gap={2}>
            <Icon as={HiOutlineCurrencyDollar} boxSize="24px" />
            <Text>Deseja realmente confirmar o estorno?</Text>
          </Flex>
        </ModalHeader>
        <ModalBody paddingY={4}>
          <Flex
            alignItems="center"
            borderRadius="lg"
            backgroundColor="light.orange"
            padding={4}
            gap={4}
          >
            <Icon as={FiUser} boxSize="24px" color="brand.primary" />
            <Text>
              O valor será debitado do colaborador{' '}
              <Semibold>imediatamente</Semibold>
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap={2} width="100%">
            <Button variant="outline" width="100%" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              width="100%"
              onClick={() => {
                refundEmployeeBalance.mutate(
                  {
                    payload: formValues,
                  },
                  {
                    onSuccess: handleClose,
                  }
                );
              }}
              isLoading={refundEmployeeBalance.isPending}
            >
              Confirmar estorno
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RefundConfirmationModal;
