export enum UserStatusEnum {
  ACTIVE = 'Ativo',
  PENDING = 'Convite enviado',
  INACTIVE = 'Inativo',
}

export enum UserPolicyName {
  GERAL = 'GERAL',
  ADMIN = 'ADMINISTRADOR',
  FINANCEIRO = 'FINANCEIRO',
  ADMIN_RAIO = 'ADMIN_RAIÔ',
}

export type UserStatus =
  | 'ACTIVE'
  | 'PENDING'
  | 'WAITING_ONBOARDING'
  | 'INACTIVE'
  | 'PROCESSING';

export type Policies = {
  id: string;
  name: UserPolicyName;
  description: string | null;
  isActive: boolean;
};

export type UserPolicy = {
  id: string;
  name: UserPolicyName;
  isActive: boolean;
};

export type CustomerUsers = {
  id: string;
  owner: boolean;
  customerId: string;
  policies: Policies[];
};

export type User = {
  id: string;
  fullName: string;
  username: string;
  activationCode: string;
  userStatus: UserStatus;
  isActive: boolean;
  externalCode: string;
  userType: string;
  customerUsers: CustomerUsers[];
};
