import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  RechargeCancelReason,
  RechargeListInfo,
  RechargePaymentMethod,
  RechargeStatusEnum,
  RechargeVisibilityEnum,
} from '../../types/recharge';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
} from '@chakra-ui/react';
import CreditDate from '../NewRechargePage/components/CreditDate';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import PaymentMethod from '../NewRechargePage/components/PaymentMethod';
import RechargeObservation from '../NewRechargePage/components/RechargeObservation';
import RechargeVisibilityConfig from '../NewRechargePage/components/RechargeVisibilityConfig';

import {
  useGetAccountBalance,
  useGetEarliestBilletCreditDate,
  useGetRecharge,
  useGetRechargeDates,
  usePatchRecharge,
} from '../../api/recharge';
import RechargeAlert from './components/RechargeAlert';
import Spinner from '../../components/Spinner';
import HelpMessage from '../../components/HelpMessage';
import {
  getRechargeVisibilityLabel,
  getRechargeVisibilityTooltip,
} from '../NewRechargePage/utils';
import { H2 } from '../../components/Typography';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import useDisableBilletButton from '../../hooks/useDisableBilletButton';
import { checkoutSchema } from '../../types/schemas/rechargeSchema';

export type FormValues = z.infer<typeof checkoutSchema>;
const rechargeVisibilityOptions = Object.values(RechargeVisibilityEnum);

const EditRechargePage = () => {
  const location = useLocation();
  const locationRecharge: RechargeListInfo | undefined =
    location?.state?.recharge;

  const reason: keyof typeof RechargeCancelReason | undefined =
    location?.state?.reason?.reason;

  const navigate = useNavigate();
  const { data: recharge } = useGetRecharge(locationRecharge?.id || '');
  const { data: accountBalance } = useGetAccountBalance();
  const usePatchRechargeMutation = usePatchRecharge(locationRecharge?.id || '');
  const [changeDueDate, setChangeDueDate] = useState(false);

  const oldRechargeDueDate = recharge?.data.dueDate;

  const methods = useForm<FormValues>({
    resolver: zodResolver(checkoutSchema),
    values: {
      paymentMethod: recharge?.data?.paymentMethod?.toLocaleLowerCase() || '',
      creditAvailability: 'scheduled',
      scheduledDate:
        reason === 'DUE_DATE_CHANGE' ? undefined : recharge?.data.scheduleDate,

      rechargeVisibilityConfig:
        (recharge?.data.rechargeVisibilityConfig as RechargeVisibilityEnum) ||
        RechargeVisibilityEnum.PAID,
    },
  });

  const rechargeValues = methods.getValues();
  const { paymentMethod, scheduledDate, creditAvailability } = rechargeValues;

  const newScheduleDateStatus = getNewScheduleDateStatus();

  const {
    data: earliestBilletCreditDate,
    isLoading: isLoadingEarliestBilletCreditDate,
  } = useGetEarliestBilletCreditDate();

  const getRechargeDates = useGetRechargeDates({
    paymentMethod,
    scheduledDate,
  });

  const { data: rechargeDates, isLoading: isLoadingRechargeDates } =
    getRechargeDates;

  const { shouldDisableBilletButton } = useDisableBilletButton(
    rechargeValues.scheduledDate,
    earliestBilletCreditDate?.creditDate
  );

  const showChangeDueDate = showChangeDueDateComponent();
  const showAlert = showAlertComponent();

  const isAccountBalance =
    recharge?.data?.paymentMethod === RechargePaymentMethod.ACCOUNT_BALANCE;

  const showObservation =
    recharge &&
    (recharge.data.observation ||
      locationRecharge?.status === RechargeStatusEnum.PENDING) &&
    (!isAccountBalance || (isAccountBalance && recharge.data.observation));

  const rechargeVisibilityConfig = methods.getValues(
    'rechargeVisibilityConfig'
  );

  function setRechargeObservation(value: string) {
    methods.setValue('observation', value, { shouldDirty: true });
  }

  function resetRechargeObservation() {
    methods.setValue('observation', '', { shouldDirty: true });
  }
  function setRechargeVisibility(value: RechargeVisibilityEnum) {
    methods.setValue('rechargeVisibilityConfig', value, { shouldDirty: true });
  }

  function getNewScheduleDateStatus() {
    if (!recharge || !scheduledDate) return 'same';

    const isSameDayDate = isSameDay(
      new Date(recharge?.data.scheduleDate),
      new Date(scheduledDate)
    );

    const isBeforeDate = isBefore(
      new Date(scheduledDate),
      new Date(recharge?.data.scheduleDate)
    );

    const isAfterDate = isAfter(
      new Date(scheduledDate),
      new Date(recharge?.data.scheduleDate)
    );

    if (isSameDayDate) return 'same';
    if (isBeforeDate) return 'before';
    if (isAfterDate) return 'after';

    return 'same';
  }

  function showAlertComponent() {
    const isSameDates = isSameDay(
      new Date(locationRecharge?.dueDate || ''),
      new Date(rechargeDates?.dueDate || '')
    );

    if (
      recharge &&
      (recharge.data.paymentMethod.toLowerCase() !== paymentMethod ||
        recharge.data.paymentMethod === 'ACCOUNT_BALANCE' ||
        isSameDates)
    ) {
      return false;
    }

    return true;
  }

  function showChangeDueDateComponent() {
    const isSameDates = isSameDay(
      new Date(locationRecharge?.dueDate || ''),
      new Date(rechargeDates?.dueDate || '')
    );
    if (
      recharge &&
      newScheduleDateStatus === 'after' &&
      recharge.data.paymentMethod.toLowerCase() === paymentMethod &&
      recharge.data.paymentMethod !== 'ACCOUNT_BALANCE' &&
      !isSameDates &&
      (earliestBilletCreditDate?.dueDate || '') < (oldRechargeDueDate || '')
    ) {
      return true;
    }
    return false;
  }

  const isEditingPendingRecharge =
    locationRecharge?.status === RechargeStatusEnum.PENDING;

  function handleSubmit() {
    const values = methods.getValues();

    usePatchRechargeMutation.mutate(
      {
        ...values,
        paymentMethod: values.paymentMethod || recharge?.data.paymentMethod,
        scheduledDate: rechargeDates?.creditDate || recharge?.data.scheduleDate,
        // only change due date if the user has selected to change it or if it must be changed due to the new schedule date or new payment method
        dueDate:
          (changeDueDate ? rechargeDates?.dueDate : oldRechargeDueDate) ||
          oldRechargeDueDate,
        changeDueDate: changeDueDate,
      },
      {
        onSuccess: (response) => {
          navigate('/recargas/editar-recarga/informacoes-da-recarga', {
            state: {
              ...response.data,
              isEdit: true,
              status: locationRecharge?.status,
            },
          });
        },
      }
    );
  }

  useEffect(() => {
    // only recharges with pending status can have its payment method changed, so we need to reset the payment method if the user is editing a pending recharge and the billet button is disabled
    if (
      shouldDisableBilletButton &&
      isEditingPendingRecharge &&
      recharge?.data?.paymentMethod !== RechargePaymentMethod.ACCOUNT_BALANCE
    ) {
      methods.setValue('paymentMethod', '', { shouldDirty: true });
    }
  }, [shouldDisableBilletButton, methods, isEditingPendingRecharge, recharge]);

  useEffect(() => {
    if (!paymentMethod) {
      setChangeDueDate(false);
      return;
    }

    const paymentMethodChanged =
      paymentMethod !== recharge?.data.paymentMethod.toLowerCase();
    const creditDateBeforeSchedule = isBefore(
      new Date(rechargeDates?.creditDate || ''),
      new Date(recharge?.data.scheduleDate || '')
    );
    const dueDateLaterThanOld =
      (earliestBilletCreditDate?.dueDate || '') > (oldRechargeDueDate || '');

    setChangeDueDate(
      paymentMethodChanged || creditDateBeforeSchedule || dueDateLaterThanOld
    );
  }, [
    rechargeDates,
    oldRechargeDueDate,
    paymentMethod,
    recharge,
    scheduledDate,
    earliestBilletCreditDate?.dueDate,
  ]);

  if (!locationRecharge) return <Navigate to={'/recargas'} />;

  if (!recharge || !accountBalance || isLoadingEarliestBilletCreditDate) {
    return <Spinner />;
  }
  return (
    <Box marginBottom="400px">
      <Stepper
        index={0}
        orientation="horizontal"
        gap="0"
        width="430px"
        marginBottom={6}
      >
        <Step>
          <StepIndicator bg="gray.100" fontWeight={600} marginRight={1}>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <Box>
            <Text fontWeight={600}>Editar recarga</Text>
            <Text fontSize="14px">Obrigatório</Text>
          </Box>
          <Box width="20px">
            <StepSeparator />
          </Box>
        </Step>
        <Step>
          <StepIndicator
            bg="gray.100"
            border="2px solid"
            borderColor="gray.500"
            fontWeight={600}
            marginRight={1}
          >
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <Box>
            <Text>Informações da recarga</Text>
            <Text fontSize="14px">Obrigatório</Text>
          </Box>
        </Step>
      </Stepper>
      <FormProvider {...methods}>
        <form>
          <Flex flexWrap={'wrap'} gap={8}>
            <Box flexBasis={'496px'} flexGrow={1}>
              <CreditDate isEdit getRechargeDates={getRechargeDates} />
            </Box>
            {isEditingPendingRecharge && !isAccountBalance ? (
              <Box flexGrow={1} flexBasis={'496px'}>
                <PaymentMethod
                  defaultRechargeVisibility={rechargeVisibilityConfig}
                  setRechargeVisibility={setRechargeVisibility}
                  grandTotal={recharge.data.amount}
                  accountBalance={accountBalance.data.accountBalanceAvailable}
                  resetRechargeObservation={resetRechargeObservation}
                  alert={
                    showAlert &&
                    !isLoadingEarliestBilletCreditDate &&
                    !isLoadingRechargeDates && (
                      <RechargeAlert
                        paymentMethod={recharge.data.paymentMethod}
                        changeDueDate={changeDueDate}
                        status={newScheduleDateStatus}
                      />
                    )
                  }
                  isEdit
                  showChangeDueDate={showChangeDueDate}
                  getRechargeDates={getRechargeDates}
                  earliestBilletCreditDate={
                    earliestBilletCreditDate?.creditDate
                  }
                  earliestBilletDueDate={earliestBilletCreditDate?.dueDate}
                  shouldDisableBilletButton={shouldDisableBilletButton}
                  oldRechargeDueDate={oldRechargeDueDate}
                  changeDueDate={changeDueDate}
                  setChangeDueDate={setChangeDueDate}
                />
              </Box>
            ) : (
              <Box flexGrow={1} flexBasis={'496px'}>
                <Box
                  padding={8}
                  bg={'white'}
                  minHeight={'400px'}
                  borderRadius={'8px'}
                  height="100%"
                >
                  <H2 marginBottom={8}>Configurar exibição no aplicativo</H2>
                  <FormControl as="fieldset">
                    <FormLabel as="legend" marginBottom={4}>
                      Quando devemos disponibilizar aos colaboradores da sua
                      empresa as informações da próxima recarga no Aplicativo
                      Raiô?{' '}
                      <HelpMessage
                        label="O colaborador irá visualizar uma mensagem na página inicial com as informações da próxima recarga"
                        iconProps={{ position: 'relative', bottom: '-2px' }}
                      />
                    </FormLabel>
                    <RadioGroup defaultValue={rechargeVisibilityConfig}>
                      <Stack spacing={2}>
                        {rechargeVisibilityOptions.map((option) => {
                          if (
                            option === RechargeVisibilityEnum.PAID &&
                            recharge.data.paymentMethod ===
                              RechargePaymentMethod.ACCOUNT_BALANCE
                          ) {
                            return null;
                          }
                          return (
                            <Radio
                              key={option}
                              value={option}
                              onChange={() => {
                                setRechargeVisibility(option);
                              }}
                            >
                              <Flex alignItems="center" gap={1}>
                                {getRechargeVisibilityLabel(option)}{' '}
                                {getRechargeVisibilityTooltip(option)}
                              </Flex>
                            </Radio>
                          );
                        })}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            )}

            <Flex
              flexDirection="column"
              gap={8}
              flexGrow={1}
              flexBasis={'496px'}
              display={showObservation ? 'flex' : 'none'}
            >
              {isEditingPendingRecharge && !isAccountBalance && (
                <RechargeVisibilityConfig
                  rechargeVisibility={rechargeVisibilityConfig}
                  setRechargeVisibility={setRechargeVisibility}
                  paymentMethod={paymentMethod}
                />
              )}

              {recharge.data.observation && (
                <RechargeObservation
                  rechargeObservation={recharge.data.observation}
                  setRechargeObservation={setRechargeObservation}
                  employees={[]}
                  rechargeValues={rechargeValues}
                  onlyView
                />
              )}
            </Flex>
          </Flex>

          <Drawer
            placement="bottom"
            isOpen={true}
            autoFocus={false}
            blockScrollOnMount={false}
            returnFocusOnClose={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            trapFocus={false}
            lockFocusAcrossFrames={false}
            variant="clickThrough"
            onClose={() => {}}
          >
            <DrawerContent zIndex={1} paddingX={0} height="104px">
              <DrawerBody paddingX={8}>
                <Flex
                  alignItems="center"
                  direction="row-reverse"
                  gap={4}
                  height="100%"
                  justifyContent="space-between"
                >
                  <Flex
                    alignItems="center"
                    gap={4}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="outline"
                      width="206px"
                      onClick={() => navigate('/recargas')}
                      isDisabled={usePatchRechargeMutation.isPending}
                    >
                      Cancelar
                    </Button>
                    <Button
                      width="206px"
                      isLoading={usePatchRechargeMutation.isPending}
                      isDisabled={
                        !methods.formState.isDirty ||
                        (!scheduledDate &&
                          creditAvailability === 'scheduled' &&
                          !rechargeDates)
                      }
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </Button>
                  </Flex>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </form>
      </FormProvider>
    </Box>
  );
};

export default EditRechargePage;
