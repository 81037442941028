import {
  Box,
  Flex,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { FiCheckCircle, FiCopy, FiFileText } from 'react-icons/fi';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { format } from 'date-fns';

import { DownloadButton } from '../OrderDocs';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { baseCreditOrderDownloadUrl } from '../../../../constants/general';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';
import { CreateCreditOrderResponse } from '../../../../types/credits';

type OrderInfoPixProps = {
  orderInfo: CreateCreditOrderResponse;
};

const OrderInfoPix = ({ orderInfo }: OrderInfoPixProps) => {
  const pixCopyPaste = orderInfo.paymentCode;

  const { hasCopied, onCopy } = useClipboard(pixCopyPaste || '');

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  return (
    <Flex
      padding={8}
      flexDirection={'column'}
      alignItems="left"
      background="white"
      width="100%"
      gap={8}
      borderRadius="8px"
      flexWrap={'wrap'}
    >
      <Flex alignItems="center">
        <Icon as={FiCheckCircle} boxSize="24px" color="feedback.success" />
        <Text fontSize="20px" fontWeight={500} marginLeft={2}>
          Créditos Raiô solicitado com sucesso!
        </Text>
      </Flex>

      <Flex>
        <Text>
          Escaneie a imagem do{' '}
          <Text as="span" fontWeight={600}>
            código QR
          </Text>{' '}
          abaixo com o aplicativo do seu banco para realizar o pagamento do
          pedido:
        </Text>
      </Flex>

      <Flex flexDirection={'row'} alignItems={'flex-end'} gap="20px">
        <Img
          src={`data:image/png;base64,${orderInfo.paymentFileBase64}`}
          margin="-20px"
          width="100%"
          maxWidth="268px"
        />
        <DownloadButton
          isDisabled={!orderInfo.orderId}
          maxWidth="fit-content"
          as={Link}
          href={`${baseCreditOrderDownloadUrl}?orderId=${orderInfo.orderId}`}
          height="56px"
        >
          Código QR
        </DownloadButton>
      </Flex>
      <Box>
        <Text fontSize="16px" fontWeight={400} marginBottom={4}>
          Se preferir, pague utilizando o código{' '}
          <Text as="span" fontWeight={600}>
            Pix Copia e Cola
          </Text>
          :
        </Text>

        <InputGroup>
          <InputRightElement
            width="54px"
            height="54px"
            top="0.7px"
            right="29px"
            cursor="pointer"
            onClick={onCopy}
          >
            {hasCopied ? (
              <Flex alignItems="center" color="feedback.success">
                <Icon as={FiCheckCircle} boxSize="20px" />
                <Link textDecor="underline" fontWeight={600} marginLeft={1}>
                  Copiado!
                </Link>
              </Flex>
            ) : (
              <Flex alignItems="center" color="brand.aux08">
                <Icon as={FiCopy} boxSize="22px" />
                <Link textDecor="underline" fontWeight={600} marginLeft={1}>
                  Copiar
                </Link>
              </Flex>
            )}
          </InputRightElement>
          <Input
            defaultValue={pixCopyPaste}
            marginBottom={6}
            paddingRight="105px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          />
        </InputGroup>

        <Text fontSize="32px" fontWeight={600} marginBottom={3}>
          {formatCurrencyInCents(orderInfo.amount)}
        </Text>
        <Text fontSize={'16px'} fontWeight={'600'} marginBottom={2}>
          Dados do beneficiário
        </Text>
        <InputGroup marginBottom={6}>
          <InputLeftElement
            width="54px"
            height="54px"
            top="0.7px"
            cursor="pointer"
          >
            <Flex alignItems="center" color="brand.aux03">
              <Icon as={FiFileText} boxSize="20px" />
            </Flex>
          </InputLeftElement>
          <Flex
            alignItems="center"
            paddingLeft="32px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            background="gray.100"
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius="md"
            minHeight="54px"
            flexGrow={1}
          >
            <Text
              fontSize={'16px'}
              fontWeight="600"
              lineHeight="130%"
              marginLeft={3}
            >
              {paymentBeneficiary?.name}{' '}
              <Text
                as="span"
                fontSize={'16px'}
                fontWeight="400"
                lineHeight="130%"
              >
                ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
              </Text>
            </Text>
          </Flex>
        </InputGroup>
        {orderInfo?.dates?.due && (
          <>
            <Text fontSize={'16px'} fontWeight={'600'} marginBottom={2}>
              Data de vencimento do Pix
            </Text>
            <InputGroup marginBottom={4}>
              <InputLeftElement
                width="54px"
                height="54px"
                top="0.7px"
                cursor="pointer"
              >
                <Flex alignItems="center" color="brand.aux03">
                  <Icon as={FiFileText} boxSize="20px" />
                </Flex>
              </InputLeftElement>
              <Input
                isReadOnly
                defaultValue={format(orderInfo?.dates?.due, 'dd/MM/yyyy')}
                paddingLeft="44px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                background={'gray.100'}
                fontWeight={600}
              />
            </InputGroup>

            <Flex>
              <Text fontSize={'16px'} fontWeight="400" lineHeight="130%">
                Assim que o pagamento for confirmado, os créditos estarão
                disponíveis no Portal
              </Text>
            </Flex>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default OrderInfoPix;
