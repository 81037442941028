import { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useCheckboxGroup,
  useClipboard,
} from '@chakra-ui/react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import EmployeeStatusBadge from '../../components/EmployeeStatusBadge';
import { useGetEmployeeById, useUpdateEmployee } from '../../api/employees';
import { FiAlertTriangle, FiCheckCircle, FiCopy } from 'react-icons/fi';
import CompanyNameDisplay from '../../components/CompanyNameDisplay';
import { IMaskInput } from 'react-imask';
import HelpMessage from '../../components/HelpMessage';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import Select from '../../components/Select';
import { UFs } from '../../constants/general';
import { useGetCompanyBenefitPackages } from '../../api/benefit';
import CheckboxCard from '../../components/CheckboxCard';
import BenefitCard from '../../components/BenefitCard';
import DatePicker from '../../components/DatePicker';
import { editEmployeeFormSchema } from '../../types/schemas/employeeEditSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import Spinner from '../../components/Spinner';
import { useConsultAddresByZipCode } from '../../api/address';
import InputWithTooltip from '../../components/InputWithTooltip';
import CustomTooltip from '../../components/CustomTooltip';
import AddButton from '../../components/AddButton';
import { EmployeeStatusEnum } from '../../types/employee';

const actionDrawerHeight: DrawerContentProps['height'] = '120px';

type FormValues = z.infer<typeof editEmployeeFormSchema>;

enum BenefitTypeEnum {
  PACKAGE = 'PACKAGE',
  NO_PACKAGE = 'NO_PACKAGE',
}

const EmployeeDetailsPage = () => {
  const [addressWithoutNumber, setAddressWithoutNumber] = useState(false);
  const [addressWithoutComplement, setAddressWithoutComplement] =
    useState(false);
  const [benefitType, setBenefitType] = useState<BenefitTypeEnum>(
    BenefitTypeEnum.PACKAGE
  );

  const { employeeId } = useParams();

  const { data: response, isLoading } = useGetEmployeeById(employeeId);
  const employeeDetails = response?.data;
  const employeeActivationCode = employeeDetails?.activationCode || '';

  const { data: benefitPackages } = useGetCompanyBenefitPackages();
  const [shouldQuery, setShouldQuery] = useState(false);
  const updateEmployeeMutation = useUpdateEmployee();

  const { hasCopied, onCopy } = useClipboard(employeeActivationCode);

  const {
    control,
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    trigger,
    handleSubmit,
    reset,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(editEmployeeFormSchema),
    values: {
      fullName: employeeDetails?.fullName || '',
      identificationNumber: employeeDetails?.identificationNumber,
      admissionDate: employeeDetails?.admissionDate,
      socialName: employeeDetails?.socialName,
      email: employeeDetails?.email,
      cellphone: employeeDetails?.cellphone,
      packageId: employeeDetails?.packageId,
      address: {
        zipCode: employeeDetails?.address?.zipCode || '',
        address: employeeDetails?.address?.address || '',
        number: employeeDetails?.address?.number,
        complement: employeeDetails?.address?.complement,
        district: employeeDetails?.address?.district || '',
        city: employeeDetails?.address?.city || '',
        state: employeeDetails?.address?.state || '',
      },
    },
  });
  const zipCode = watch('address.zipCode');

  const state = watch('address.state');
  const {
    data: address,
    isFetching,
    isFetched,
    isError,
  } = useConsultAddresByZipCode(zipCode || '');
  const isErrorConsult = address?.erro || isError || false;

  const navigate = useNavigate();

  const { setValue: setCheckboxValue, getCheckboxProps } = useCheckboxGroup();
  const [showSocialNameInput, setShowSocialNameInput] = useState(false);

  useEffect(() => {
    if (address && shouldQuery) {
      setValue('address.address', address.logradouro);
      setValue('address.district', address.bairro);
      setValue('address.city', address.localidade);
      setValue('address.state', address.uf);
    }
  }, [address, shouldQuery, setValue]);

  useEffect(() => {
    const element = document.getElementById('beneficios');
    if (!isLoading && window.location.hash === '#beneficios' && element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!employeeId || (!isLoading && !employeeDetails)) {
    return <Navigate to="/colaboradores" />;
  }

  if (!employeeDetails) {
    return <Text>Colaborador não encontrado</Text>;
  }
  const shouldShowActivationCode =
    employeeDetails?.status === 'PROCESSING' ? false : true;

  const activeEmployee =
    employeeDetails?.userStatus === EmployeeStatusEnum.ACTIVE ||
    employeeDetails?.userStatus === EmployeeStatusEnum.WAITING_ONBOARDING;

  function resetAddressForm() {
    const currentValues = getValues();

    reset({
      ...currentValues,
      address: {
        ...currentValues.address,
        city: '',
        address: '',
        complement: '',
        number: '',
        district: '',
        state: '',
      },
    });
  }

  // custom function to allow only one checkbox to be checked at a time
  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nextValue = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setCheckboxValue([nextValue]);
      setValue('packageId', nextValue, { shouldDirty: true });
    } else {
      setCheckboxValue([]);
      setValue('packageId', undefined, { shouldDirty: true });
    }
  }

  const benefitPackageId = getValues('packageId');
  const admissionDate = getValues('admissionDate');
  const socialName = getValues('socialName');

  function handleSave(values: FormValues) {
    if (!employeeId) {
      return;
    }
    updateEmployeeMutation.mutate(
      {
        employee: {
          id: employeeId,
          ...values,
          status: employeeDetails?.status,
        },
      },
      {
        onSuccess: () => navigate('/colaboradores'),
      }
    );
  }

  const isProcessing = employeeDetails.status === 'PROCESSING';
  return (
    <Box height={`calc(100% + ${actionDrawerHeight} + 32px)`}>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/">
            Início
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/colaboradores">
            Colaboradores
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Editar colaborador</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <form>
        <Card marginTop={6} padding={8} borderRadius="lg">
          <Flex direction="column" gap={8}>
            <Box id="employee-details-header">
              <Text fontWeight={600} fontSize="24px">
                {employeeDetails?.fullName}
              </Text>
              {!shouldShowActivationCode && (
                <Flex
                  gap={4}
                  alignItems="center"
                  minHeight="40px"
                  marginTop={2}
                >
                  <EmployeeStatusBadge status={'PROCESSING'} />
                </Flex>
              )}
              {shouldShowActivationCode && (
                <Flex
                  gap={4}
                  alignItems="center"
                  minHeight="40px"
                  marginTop={2}
                >
                  <EmployeeStatusBadge status={employeeDetails.userStatus} />

                  <Flex
                    gap={2}
                    alignItems="center"
                    display={activeEmployee ? 'none' : 'flex'}
                  >
                    <Text>Código de ativação:</Text>
                    <Text fontWeight={600}>
                      {employeeDetails.activationCode}
                    </Text>
                    <IconButton
                      aria-label="copiar código de ativação"
                      title="Copiar código de ativação"
                      onClick={onCopy}
                      boxSize={6}
                      variant="ghost"
                      icon={<Icon as={FiCopy} boxSize="20px" />}
                      color="brand.primary"
                      marginLeft={-1}
                    />
                  </Flex>

                  {hasCopied && (
                    <Flex
                      alignItems="center"
                      gap={2}
                      color="gray.50"
                      backgroundColor="feedback.success"
                      padding="8px 12px"
                      borderRadius="lg"
                    >
                      <Icon as={FiCheckCircle} boxSize="20px" />
                      <Text fontWeight={600}>Código copiado!</Text>
                    </Flex>
                  )}
                </Flex>
              )}
            </Box>
            <Box id="employee-data-container">
              <Text
                fontWeight={500}
                fontSize="20px"
                marginBottom={4}
                lineHeight={1}
              >
                Dados do colaborador
              </Text>
              <Grid
                templateColumns={{
                  lg: 'repeat(12, 1fr)',
                  sm: 'repeat(6, 1fr)',
                }}
                gap={4}
              >
                <GridItem colSpan={3}>
                  <FormControl>
                    <FormLabel fontSize="sm">Empresa</FormLabel>
                    <CompanyNameDisplay
                      companyName={employeeDetails.companyTradeName}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={3}>
                  <FormControl>
                    <FormLabel fontSize="sm">CPF</FormLabel>
                    <Input
                      isDisabled
                      as={IMaskInput}
                      type="text"
                      mask="000.000.000-00"
                      unmask={true}
                      value={employeeDetails.document}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isInvalid={!!errors.identificationNumber}>
                    <FormLabel fontSize="sm" display="flex" gap={1}>
                      Matrícula (opcional){' '}
                      <HelpMessage
                        iconProps={{ fontSize: 'md' }}
                        label="Código que identifica o colaborador internamente na empresa"
                      />
                    </FormLabel>
                    <Input type="text" {...register('identificationNumber')} />
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="identificationNumber"
                      />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="sm"
                      whiteSpace="nowrap"
                      display="flex"
                      gap={1}
                    >
                      Data de admissão (opcional)
                      <HelpMessage
                        iconProps={{ fontSize: 'md' }}
                        label="Essa informação ajuda no cálculo do valor da primeira recarga de benefícios e no envio de comunicações ao colaborador."
                      />
                    </FormLabel>
                    <Controller
                      key={admissionDate}
                      control={control}
                      name="admissionDate"
                      render={({ field }) => (
                        <DatePicker {...field} value={field.value} />
                      )}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={showSocialNameInput || socialName ? 6 : 12}>
                  <FormControl isInvalid={!!errors.fullName}>
                    <FormLabel fontSize="sm">Nome do colaborador</FormLabel>
                    <InputWithTooltip
                      disabled={isProcessing}
                      type="text"
                      {...register('fullName')}
                      disabledTooltip={!isProcessing}
                      tooltipLabel="Durante o processamento, essa informação não pode ser editada"
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="fullName" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={6}>
                  {(showSocialNameInput || socialName) && (
                    <FormControl>
                      <FormLabel fontSize="sm" display="flex" gap={1}>
                        Nome social (opcional)
                        <HelpMessage
                          iconProps={{ fontSize: 'md' }}
                          label="Ao preencher o nome social, ele será impresso no cartão físico e usado nas comunicações ao colaborador"
                        />
                      </FormLabel>
                      <InputWithTooltip
                        type="text"
                        {...register('socialName')}
                        isDisabled={isProcessing}
                        disabledTooltip={!isProcessing}
                        tooltipLabel="Durante o processamento, essa informação não pode ser editada"
                      />
                    </FormControl>
                  )}
                  {!(showSocialNameInput || socialName) && (
                    <Flex>
                      <AddButton
                        borderRadius="md"
                        padding={0}
                        onClick={() => setShowSocialNameInput(true)}
                      >
                        Adicionar nome social{' '}
                      </AddButton>
                      <HelpMessage
                        iconProps={{ ml: 1 }}
                        label="Ao preencher o nome social, ele será impresso no cartão físico e usado nas comunicações ao colaborador"
                      />
                    </Flex>
                  )}
                </GridItem>
              </Grid>
              <Grid marginTop={8} templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <Text fontWeight={500}>Contato</Text>
                </GridItem>
                <GridItem colSpan={{ lg: 6, sm: 4 }}>
                  <FormControl isInvalid={!!errors.cellphone}>
                    <FormLabel fontSize="sm">Celular</FormLabel>
                    <CustomTooltip
                      label="Durante o processamento, essa informação não pode ser editada"
                      isDisabled={!isProcessing}
                    >
                      <Box>
                        <Controller
                          control={control}
                          name="cellphone"
                          render={({ field }) => (
                            <Input
                              disabled={isProcessing}
                              ref={field.ref}
                              as={IMaskInput}
                              mask="(00) 00000-0000"
                              unmask={true}
                              value={field.value}
                              onAccept={(value: string) =>
                                setValue('cellphone', value, {
                                  shouldDirty: true,
                                })
                              }
                              onBlur={() => {
                                field.onBlur();
                              }}
                              onBlurCapture={() =>
                                trigger(['email', 'cellphone'])
                              }
                            />
                          )}
                        />
                      </Box>
                    </CustomTooltip>
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="cellphone" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ lg: 6, sm: 8 }}>
                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel fontSize="sm">E-mail</FormLabel>
                    <Input
                      type="text"
                      {...register('email')}
                      onChange={(e) => {
                        setValue('email', e.target.value.replace(/\s/g, ''), {
                          shouldDirty: true,
                        });
                      }}
                      onBlurCapture={() => trigger(['email', 'cellphone'])}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="email" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
              <Grid marginTop={8} templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <Text fontWeight={500} marginBottom={4}>
                    Endereço do colaborador (opcional)
                  </Text>
                  <Text>
                    Usaremos esse endereço se o{' '}
                    <Text as="span" fontWeight={600}>
                      local de entrega do cartão{' '}
                    </Text>
                    for direto{' '}
                    <Text as="span" fontWeight={600}>
                      para o colaborador
                    </Text>
                  </Text>
                </GridItem>

                <GridItem colSpan={{ lg: 2, sm: 4 }}>
                  <FormControl isInvalid={!!errors.address?.zipCode}>
                    <FormLabel fontSize="sm">CEP</FormLabel>
                    <Controller
                      control={control}
                      name="address.zipCode"
                      render={({ field }) => (
                        <Input
                          as={IMaskInput}
                          inputRef={field.ref}
                          type="text"
                          mask="00000-000"
                          unmask={true}
                          value={field.value}
                          paddingRight={isFetching ? '32px' : '16px'}
                          onKeyUp={() => {
                            if (zipCode?.length === 8) {
                              setShouldQuery(true);
                            }
                          }}
                          onBlur={() => trigger('address.zipCode')}
                          onAccept={(value: string) => {
                            setValue('address.zipCode', value, {
                              shouldDirty: true,
                            });

                            if (value.length < 8 && state) {
                              resetAddressForm();
                            }
                          }}
                        />
                      )}
                    />
                    {isFetching && (
                      <Spinner
                        size="sm"
                        centerProps={{
                          position: 'absolute',
                          top: '45px',
                          right: '16px',
                          margin: '0',
                        }}
                      />
                    )}
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="address.zipCode" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={{ lg: 4, sm: 8 }}>
                  <FormControl isInvalid={!!errors.address?.address}>
                    <FormLabel fontSize="sm">Endereço</FormLabel>
                    <InputWithTooltip
                      type="text"
                      {...register('address.address')}
                      disabled={!isFetched}
                      disabledTooltip={isFetched}
                      tooltipLabel="Preencha o CEP"
                      maxLength={40}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="address.address" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={{ lg: 2, sm: 6 }}>
                  <FormControl isInvalid={!!errors.address?.number}>
                    <FormLabel fontSize="sm">Número</FormLabel>
                    <InputWithTooltip
                      type="number"
                      {...register('address.number')}
                      isDisabled={addressWithoutNumber || !isFetched}
                      disabledTooltip={isFetched}
                      tooltipLabel="Preencha o CEP"
                      maxLength={40}
                    />
                    <Checkbox
                      isChecked={addressWithoutNumber}
                      onChange={() => {
                        setAddressWithoutNumber(!addressWithoutNumber);
                        setValue('address.number', null, { shouldDirty: true });
                      }}
                      marginTop={2}
                      isDisabled={!isFetched}
                    >
                      Sem número
                    </Checkbox>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={{ lg: 4, sm: 6 }}>
                  <FormControl>
                    <FormLabel fontSize="sm">Complemento</FormLabel>
                    <InputWithTooltip
                      type="text"
                      {...register('address.complement')}
                      isDisabled={addressWithoutComplement || !isFetched}
                      disabledTooltip={isFetched}
                      tooltipLabel="Preencha o CEP"
                      maxLength={30}
                    />
                    <Checkbox
                      isChecked={addressWithoutComplement}
                      onChange={() => {
                        setAddressWithoutComplement(!addressWithoutComplement);
                        setValue('address.complement', null, {
                          shouldDirty: true,
                        });
                      }}
                      marginTop={2}
                      isDisabled={!isFetched}
                    >
                      Sem complemento
                    </Checkbox>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={4}>
                  <FormControl isInvalid={!!errors.address?.district}>
                    <FormLabel fontSize="sm">Bairro/Distrito</FormLabel>
                    <InputWithTooltip
                      type="text"
                      {...register('address.district')}
                      disabled={!isFetched}
                      disabledTooltip={isFetched}
                      tooltipLabel="Preencha o CEP"
                      maxLength={30}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="address.district" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={4}>
                  <FormControl isInvalid={!!errors.address?.city}>
                    <FormLabel fontSize="sm">Cidade</FormLabel>
                    <InputWithTooltip
                      type="text"
                      {...register('address.city')}
                      disabled={!isFetched || !isErrorConsult}
                      tooltipLabel={
                        !isFetched
                          ? 'Preencha o CEP'
                          : 'Essa informação não pode ser editada'
                      }
                      disabledTooltip={isErrorConsult}
                      maxLength={30}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="address.city" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={4}>
                  <FormControl isInvalid={!!errors.address?.state}>
                    <FormLabel fontSize="sm">Estado</FormLabel>

                    <Controller
                      name="address.state"
                      control={control}
                      render={({ field }) => {
                        const options = [
                          { value: '', label: 'Selecione' },
                          ...UFs,
                        ];
                        return (
                          <Select
                            isDisabled={!isFetched || !isErrorConsult}
                            inputId="address.state"
                            options={options}
                            {...field}
                            onChange={(state) => field.onChange(state?.value)}
                            value={options.find(
                              (uf) => uf.value === field.value
                            )}
                            tooltipLabel={
                              !isFetched
                                ? 'Preencha o CEP'
                                : 'Essa informação não pode ser editada'
                            }
                            disabledTooltip={isErrorConsult}
                          />
                        );
                      }}
                    />
                    <FormErrorMessage>
                      <ErrorMessage errors={errors} name="address.state" />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>

            <ErrorMessage
              errors={errors}
              name="address"
              render={() => (
                <Flex alignItems="center" gap={2}>
                  <Icon as={FiAlertTriangle} color="feedback.alert" />
                  <Text color="feedback.alert">
                    Você precisa preencher o endereço completo ou deixar todos
                    os campos em branco.
                  </Text>
                </Flex>
              )}
            />

            <Divider />
            <Box id="employee-benefit-container">
              <Text
                fontWeight={500}
                fontSize="20px"
                marginBottom={4}
                lineHeight={1}
              >
                Benefícios
              </Text>

              <RadioGroup
                onChange={(value) => setBenefitType(value as BenefitTypeEnum)}
                value={benefitType}
              >
                <Stack direction="row" spacing={6}>
                  <Radio value={BenefitTypeEnum.PACKAGE}>
                    Pacote de benefícios
                  </Radio>
                  <Radio
                    value={BenefitTypeEnum.NO_PACKAGE}
                    onChange={() =>
                      setValue('packageId', undefined, { shouldDirty: true })
                    }
                  >
                    Sem pacote definido
                  </Radio>
                </Stack>
              </RadioGroup>

              <Flex
                gap={6}
                flexWrap="wrap"
                position="relative"
                marginY={4}
                id="beneficios"
              >
                {benefitType === BenefitTypeEnum.NO_PACKAGE && (
                  <Box
                    id="benefit-checkbox-disabled-overlay"
                    position="absolute"
                    zIndex={1}
                    width="100%"
                    height="105%"
                    bg="white"
                    opacity={0.7}
                  />
                )}
                <CheckboxGroup>
                  {benefitPackages &&
                    benefitPackages.data.length > 0 &&
                    benefitPackages.data.map((benefit) => {
                      const isChecked = benefitPackageId === benefit.id;
                      return (
                        <CheckboxCard
                          key={benefit.id}
                          {...getCheckboxProps({ value: benefit.id })}
                          isChecked={isChecked}
                          onChange={handleCheckboxChange}
                          isDisabled={
                            benefitType === BenefitTypeEnum.NO_PACKAGE
                          }
                          height="100%"
                          boxHeight="auto"
                        >
                          <BenefitCard benefit={benefit} />
                        </CheckboxCard>
                      );
                    })}
                </CheckboxGroup>
              </Flex>
            </Box>
          </Flex>
        </Card>
        <Drawer
          placement="bottom"
          isOpen={true}
          onClose={() => {}}
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
          variant="clickThrough"
        >
          <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
            <DrawerBody
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={4}
            >
              <Button
                variant="outline"
                onClick={() => navigate('/colaboradores')}
                width="fit-content"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  if (!isDirty) {
                    return navigate('/colaboradores');
                  }
                  const values = getValues();
                  handleSubmit(() => handleSave(values))();
                }}
                width="fit-content"
                isLoading={updateEmployeeMutation.isPending}
              >
                Salvar alterações
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </form>
    </Box>
  );
};

export default EmployeeDetailsPage;
