import { Box, Flex, Icon, Text } from '@chakra-ui/react';

type DisableEmployeeCardDisplayProps = {
  disableEmployeeContent: {
    disableText: React.ReactNode;
    icon: React.ElementType;
    key: number;
  }[];
};

const DisableEmployeeCardDisplay = ({
  disableEmployeeContent,
}: DisableEmployeeCardDisplayProps) => {
  return (
    <Box
      background="light.orange"
      display="flex"
      flexDir={'column'}
      alignItems="left"
      padding={4}
      borderRadius="lg"
      gap={6}
      marginTop={1}
      marginBottom={4}
    >
      {disableEmployeeContent.map(({ key, icon, disableText }) => (
        <Flex flexDir={'row'} alignItems={'center'} gap={'4'} key={key}>
          <Icon as={icon} boxSize={'24px'} color={'brand.primary'} />
          <Text fontSize={'16px'}>{disableText}</Text>
        </Flex>
      ))}
    </Box>
  );
};

export default DisableEmployeeCardDisplay;
