import { ColumnHooks } from '../../../types/general';
import { sanitizeAndCapitalizeString } from '../../../utils/formatters/sanitizeAndCapitalizeString';
import { sanitizeEmail } from '../../../utils/formatters/sanitizeEmail';
import { removeSpaces } from '../../../utils/formatters/sanitizeString';

export const steps = [
  {
    title: 'Preparar planilha',
    description: 'Obrigatório',
    path: '/colaboradores/importar/preparar-planilha',
  },
  {
    title: 'Enviar comunicação',
    description: 'Opcional',
    path: '/colaboradores/importar/comunicacao',
  },
  {
    title: 'Configurar e importar',
    description: 'Obrigatório',
    path: '/colaboradores/importar/configurar-importacao',
  },
];

export const employeeImportColumnHooks: ColumnHooks = [
  {
    fieldName: 'fullName',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
  {
    fieldName: 'document',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: cell.value.replace(/\D/g, ''),
      }));
    },
  },
  {
    fieldName: 'cellphone',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: cell.value.replace(/\D/g, ''),
      }));
    },
  },
  {
    fieldName: 'email',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeEmail(cell.value),
      }));
    },
  },
  {
    fieldName: 'identificationNumber',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: removeSpaces(cell.value),
      }));
    },
  },
  {
    fieldName: 'zipCode',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: cell.value.replace(/\D/g, ''),
      }));
    },
  },
  {
    fieldName: 'address',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
  {
    fieldName: 'number',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: cell.value.replace(/\D/g, ''),
      }));
    },
  },
  {
    fieldName: 'complement',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
  {
    fieldName: 'district',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
  {
    fieldName: 'city',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
  {
    fieldName: 'socialName',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
];
