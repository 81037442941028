import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';

type PaymentMethodCardProps = FlexProps & {
  isChecked?: boolean;
  paymentMethodName?: string | React.ReactNode;
  icon?: React.ReactNode;
  type?: string;
  color?: string;
  companyCredits?: number;
};

const PaymentMethodCard = (props: PaymentMethodCardProps) => {
  return (
    <Box>
      <Flex
        direction={props.flexDir || 'column'}
        flexWrap={props.flexWrap || 'wrap'}
        gap={props.gap || 3}
        marginTop={props.marginTop || 4}
        alignItems={props.alignItems || 'center'}
      >
        {props.icon}

        <Text
          fontFamily={'Graphik'}
          fontSize={'18px'}
          fontStyle={'normal'}
          fontWeight={'600'}
          lineHeight={'130%'}
          color={props?.color}
        >
          {props.paymentMethodName}
        </Text>
      </Flex>
    </Box>
  );
};

export default PaymentMethodCard;
