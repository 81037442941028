import {
  Box,
  Flex,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { FiCalendar, FiCheckCircle, FiCopy, FiFileText } from 'react-icons/fi';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { format } from 'date-fns';
import {
  DocumentTypeEnum,
  RechargeResultResponse,
} from '../../../../types/recharge';
import { DownloadButton } from '../RechargeDocs';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { baseDownloadUrl } from '../../../../constants/general';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';

type RechargeInfoPixProps = {
  statusIsProcessed?: boolean;
  rechargeInfo: LocationResultResponse;
};

type LocationResultResponse = RechargeResultResponse & {
  changeDueDate?: boolean;
  isEdit?: boolean;
};

const rechargeTitle = (isEdit: boolean, isChangeDueDate: boolean) => {
  if (isEdit) {
    if (isChangeDueDate) {
      return 'Recarga editada com sucesso, um novo Pix foi gerado!';
    } else {
      return 'Recarga editada com sucesso!';
    }
  } else {
    return 'Recarga criada com sucesso!';
  }
};

const RechargeInfo = ({
  isEdit,
  isChangeDueDate,
}: {
  isEdit: boolean;
  isChangeDueDate: boolean;
}) => {
  if (isEdit && isChangeDueDate) {
    return (
      <Flex>
        <Text>
          Desconsidere o código gerado quando a recarga foi criada e escaneie a
          imagem do{' '}
          <Text as="span" fontWeight={600}>
            novo código QR
          </Text>{' '}
          abaixo com o aplicativo do seu banco para realizar o pagamento da
          recarga:
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex>
        <Text>
          Escaneie a imagem do{' '}
          <Text as="span" fontWeight={600}>
            código QR
          </Text>{' '}
          abaixo com o aplicativo do seu banco para realizar o pagamento da
          recarga:
        </Text>
      </Flex>
    );
  }
};

const RechargeInfoPix = ({
  rechargeInfo,
  statusIsProcessed,
}: RechargeInfoPixProps) => {
  const pixCopyPaste = rechargeInfo.pix?.copyPaste;
  const isEdit = rechargeInfo?.isEdit || false;
  const isChangeDueDate = rechargeInfo?.changeDueDate || false;

  const { hasCopied, onCopy } = useClipboard(pixCopyPaste || '');

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  if (!rechargeInfo.pix) {
    return null;
  }

  return (
    <Flex
      p={8}
      flexDirection={'column'}
      alignItems="left"
      bg="white"
      w="100%"
      gap={8}
      borderRadius="8px"
      flexWrap={'wrap'}
    >
      <Flex alignItems="center">
        <Icon as={FiCheckCircle} boxSize="32px" color="feedback.success" />
        <Text fontSize="20px" fontWeight={500} ml={2}>
          {rechargeTitle(isEdit, isChangeDueDate)}
        </Text>
      </Flex>

      {statusIsProcessed ? (
        <Box>
          <Text fontSize="32px" fontWeight={600} mb="32px">
            {formatCurrencyInCents(rechargeInfo.amount)}
          </Text>
          <>
            <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
              Data de crédito
            </Text>
            <InputGroup mb="16px">
              <InputLeftElement w="54px" h="54px" top="0.7px" cursor="pointer">
                <Flex alignItems="center" color="brand.aux03">
                  <Icon as={FiCalendar} boxSize="20px" />
                </Flex>
              </InputLeftElement>
              {rechargeInfo.pix?.scheduleDate && (
                <Input
                  isReadOnly
                  defaultValue={format(
                    rechargeInfo.pix?.scheduleDate,
                    'dd/MM/yyyy'
                  )}
                  pl="44px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  background={'gray.100'}
                  fontWeight={600}
                />
              )}
            </InputGroup>
          </>
        </Box>
      ) : (
        <>
          <RechargeInfo isEdit={isEdit} isChangeDueDate={isChangeDueDate} />
          <Flex flexDirection={'row'} alignItems={'flex-end'} gap="20px">
            <Img
              src={`data:image/png;base64,${rechargeInfo.pix.qrCode}`}
              margin="-20px"
              width="100%"
              maxWidth="268px"
            />
            <DownloadButton
              isDisabled={!rechargeInfo.rechargeId}
              maxWidth="fit-content"
              as={Link}
              href={`${baseDownloadUrl}?rechargeId=${rechargeInfo.rechargeId}&documentType=${DocumentTypeEnum.PAYMENT}`}
              height="56px"
            >
              Código QR
            </DownloadButton>
          </Flex>
          <Box>
            <Text fontSize="16px" fontWeight={400} mb="16px">
              Se preferir, pague utilizando o código{' '}
              <Text as="span" fontWeight={600}>
                Pix Copia e Cola
              </Text>
              :
            </Text>

            <InputGroup>
              <InputRightElement
                w="54px"
                h="54px"
                top="0.7px"
                right="29px"
                cursor="pointer"
                onClick={onCopy}
              >
                {hasCopied ? (
                  <Flex alignItems="center" color="feedback.success">
                    <Icon as={FiCheckCircle} boxSize="20px" />
                    <Link textDecor="underline" fontWeight={600} ml={1}>
                      Copiado!
                    </Link>
                  </Flex>
                ) : (
                  <Flex alignItems="center" color="brand.aux08">
                    <Icon as={FiCopy} boxSize="22px" />
                    <Link textDecor="underline" fontWeight={600} ml={1}>
                      Copiar
                    </Link>
                  </Flex>
                )}
              </InputRightElement>
              <Input
                defaultValue={pixCopyPaste}
                mb="32px"
                pr="105px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              />
            </InputGroup>

            <Text fontSize="32px" fontWeight={600} mb="12px">
              {formatCurrencyInCents(rechargeInfo.amount)}
            </Text>
            <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
              Dados do beneficiário
            </Text>
            <InputGroup mb="24px">
              <InputLeftElement w="54px" h="54px" top="0.7px" cursor="pointer">
                <Flex alignItems="center" color="brand.aux03">
                  <Icon as={FiFileText} boxSize="20px" />
                </Flex>
              </InputLeftElement>
              <Flex
                alignItems="center"
                pl="32px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                bg="gray.100"
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="md"
                minHeight="54px"
                flexGrow={1}
              >
                <Text
                  fontSize={'16px'}
                  fontWeight="600"
                  lineHeight="130%"
                  ml="12px"
                >
                  {paymentBeneficiary?.name}{' '}
                  <Text
                    as="span"
                    fontSize={'16px'}
                    fontWeight="400"
                    lineHeight="130%"
                  >
                    ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
                  </Text>
                </Text>
              </Flex>
            </InputGroup>
            {rechargeInfo?.pix?.dueDate && (
              <>
                <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
                  Data de vencimento do Pix
                </Text>
                <InputGroup mb="16px">
                  <InputLeftElement
                    w="54px"
                    h="54px"
                    top="0.7px"
                    cursor="pointer"
                  >
                    <Flex alignItems="center" color="brand.aux03">
                      <Icon as={FiFileText} boxSize="20px" />
                    </Flex>
                  </InputLeftElement>
                  <Input
                    isReadOnly
                    defaultValue={format(
                      rechargeInfo?.pix?.dueDate,
                      'dd/MM/yyyy'
                    )}
                    pl="44px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    background={'gray.100'}
                    fontWeight={600}
                  />
                </InputGroup>
                {format(
                  new Date(rechargeInfo.pix?.scheduleDate ?? ''),
                  'dd/MM/yyyy'
                ) !== format(new Date(), 'dd/MM/yyyy') ? (
                  <Flex>
                    <Text fontSize={'16px'} fontWeight="400" lineHeight="130%">
                      Como definido na criação da recarga, o valor será
                      creditado aos colaboradores no dia{' '}
                      <Text
                        as="span"
                        fontSize={'16px'}
                        fontWeight="600"
                        lineHeight="130%"
                      >
                        {format(
                          new Date(rechargeInfo.pix?.scheduleDate ?? ''),
                          'dd/MM/yyyy'
                        )}
                      </Text>
                    </Text>
                  </Flex>
                ) : (
                  <Flex>
                    <Text fontSize={'16px'} fontWeight="400" lineHeight="130%">
                      Assim que o pagamento for confirmado, o valor será
                      creditado aos colaboradores
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default RechargeInfoPix;
