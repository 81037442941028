import {
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Center,
  Link,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { z } from 'zod';
import { Controller } from 'react-hook-form';
import {
  sendCommsFormSchema,
  SEND_COMMS_OPTIONS_ENUM as OPTIONS,
  employeeInfoSchema,
} from '../../../../types/schemas/employeeRegistrationSchema';
import EmailMock from '../EmailMock';
import ConnectForm from '../../../../components/ConnectForm';
import { parseISO, format, isBefore, endOfToday, endOfDay } from 'date-fns';
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from '../../../../components/DatePicker';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import SmsPreview from '../SmsPreview';
import AlertCard from '../../../../components/AlertCard';

type FormValues = z.infer<
  typeof sendCommsFormSchema & typeof employeeInfoSchema
>;

type SendCommunicationFormProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const SendCommunicationForm = ({
  setActiveStep,
}: SendCommunicationFormProps) => {
  const { selectedCompany } = useUserCompaniesStore();

  return (
    <ConnectForm<FormValues>>
      {({
        control,
        getValues,
        setValue,
        formState: { errors },
        trigger,
        clearErrors,
        watch,
      }) => {
        const [socialName, fullName] = getValues([
          'collaboratorData.socialName',
          'collaboratorData.fullName',
        ]);
        const displayName = socialName || fullName;
        const companyTradeName = selectedCompany?.tradeName || '';

        const admissionDate = getValues('collaboratorData.admissionDate');

        const date = admissionDate ? parseISO(admissionDate) : undefined;

        const isAdmissionDateBeforeToday = isBefore(date || '', new Date());

        const scheduledComm = watch('sendCommunication') === 'scheduled';
        const sendCommunicationDate = watch('sendCommunicationDate');

        const hasEmailFilledIn = getValues('collaboratorData.email');

        return (
          <Flex direction="column" gap={8}>
            <Controller
              control={control}
              name="sendCommunication"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(nextValue) => {
                    field.onChange(nextValue);
                    trigger([
                      'collaboratorData.admissionDate',
                      'sendCommunication',
                    ]);
                  }}
                >
                  <Stack direction="row" spacing={4} align={'flex-start'}>
                    {!isAdmissionDateBeforeToday && (
                      <Radio value={OPTIONS.Enum.onAdmissionDate}>
                        Na data de admissão
                      </Radio>
                    )}

                    <Box>
                      <Radio value={OPTIONS.Enum.scheduled}>Agendar data</Radio>
                      {scheduledComm && (
                        <FormControl
                          marginTop={2}
                          maxW="215px"
                          isInvalid={!!errors.sendCommunicationDate}
                        >
                          <FormLabel fontSize="sm">Data de envio</FormLabel>
                          <Controller
                            control={control}
                            name="sendCommunicationDate"
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                onBlur={() => trigger('sendCommunicationDate')}
                                value={sendCommunicationDate}
                                disabledDays={[
                                  new Date(),
                                  { before: new Date() },
                                ]}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            <ErrorMessage
                              name="sendCommunicationDate"
                              errors={errors}
                            />
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Box>
                    <Radio value={OPTIONS.Enum.onRegistrationComplete}>
                      Enviar ao finalizar o cadastro
                    </Radio>
                    <Radio value={OPTIONS.Enum.decideLater}>
                      Não quero enviar ou vou decidir depois
                    </Radio>
                  </Stack>

                  {errors.collaboratorData?.admissionDate && (
                    <AlertCard marginTop={4}>
                      <Text>
                        Você precisa{' '}
                        <Text as={'span'} fontWeight={600}>
                          preencher a data de admissão
                        </Text>{' '}
                        na etapa anterior do cadastro
                      </Text>
                      <Link
                        color="feedback.alert"
                        textDecoration="underline"
                        onClick={() => {
                          clearErrors('sendCommunication');
                          setValue('sendCommunication', undefined);
                          setActiveStep(0);
                        }}
                      >
                        <ErrorMessage
                          name="sendCommunication"
                          errors={errors}
                        />
                      </Link>
                    </AlertCard>
                  )}
                </RadioGroup>
              )}
            />
            <Divider />
            <Text fontWeight={600}>
              Veja a mensagem que o colaborador vai receber
            </Text>
            <Tabs>
              <TabList>
                {hasEmailFilledIn ? <Tab>Por e-mail</Tab> : <Tab>Por SMS</Tab>}
              </TabList>

              <TabPanels background="gray.100">
                {hasEmailFilledIn ? (
                  <TabPanel>
                    <Center>
                      <EmailMock
                        employeeName={displayName}
                        companyName={companyTradeName}
                      />
                    </Center>
                  </TabPanel>
                ) : (
                  <TabPanel>
                    <Center minHeight="500px">
                      <SmsPreview />
                    </Center>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Flex>
        );
      }}
    </ConnectForm>
  );
};

export default SendCommunicationForm;
