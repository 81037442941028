import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { H2 } from '../../../../components/Typography';
import { FiCheck, FiPlusCircle } from 'react-icons/fi';
import { useState } from 'react';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { format, subBusinessDays } from 'date-fns';

import { Employee } from '../../../../types/employee';
import { ptBR } from 'date-fns/locale';
import { FormValues } from '../Checkout';

type RechargeVisibilityConfigProps = {
  rechargeObservation: string;
  setRechargeObservation: (value: string) => void;
  employees?: Employee[];
  rechargeValues: FormValues;
  onlyView?: boolean;
};

enum RechargeSugestionsEnum {
  COMPETENCE_MONTH = 'COMPETENCE_MONTH',
  AWARD_REASON = 'AWARD_REASON',
}

const observationSchema = z.object({
  observation: z.string().min(1, 'Você precisa preencher a observação'),
});

const RechargeObservation = ({
  rechargeObservation,
  setRechargeObservation,
  employees,
  rechargeValues,
  onlyView,
}: RechargeVisibilityConfigProps) => {
  const rechargeVisibilityConfigModal = useDisclosure();
  const maxFieldValue = 499;
  const [addedSugestion, setAddedSugestion] = useState({
    COMPETENCE_MONTH: false,
    AWARD_REASON: false,
  });
  const serializeRechargeObservation = rechargeObservation.replace(
    /\\n/g,
    '\n'
  );

  const linesArray = rechargeObservation.split('\\n');

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: zodResolver(observationSchema),
    values: {
      observation: serializeRechargeObservation,
    },
  });

  const fieldValue = watch('observation');

  function handleSave() {
    const formattedFieldValue = fieldValue.replace(/\r?\n/g, '\\n').trim();
    setRechargeObservation(formattedFieldValue);
    handleClose();
  }

  function handleClose() {
    reset();
    rechargeVisibilityConfigModal.onClose();
  }

  function onAddSugestion(type: RechargeSugestionsEnum) {
    const observation = getValues('observation');
    const competenceDate = getDueDate();
    const sugestion =
      type === RechargeSugestionsEnum.COMPETENCE_MONTH
        ? `${fieldValue && '\n'}O mês de competência dessa recarga é ` +
          competenceDate
        : `${fieldValue && '\n'}Premiação desta recarga foi concedido em razão de [ESCREVA AQUI]`;

    const newValue = observation + sugestion;

    setValue('observation', newValue);
    setAddedSugestion((prev) => {
      return { ...prev, [type]: true };
    });

    setTimeout(() => {
      setAddedSugestion((prev) => {
        return { ...prev, [type]: false };
      });
    }, 1000);
  }

  function checkIfHasAwardInEmployees() {
    return employees?.some((employee) =>
      employee.categories.some(
        (category) => category.name === 'Premiação' && category.value > 0
      )
    );
  }

  function getDueDate() {
    const { creditAvailability, paymentMethod, scheduledDate } = rechargeValues;
    const today = new Date();

    function formatMonthYear(date: Date) {
      return `${format(date, 'MMMM', { locale: ptBR })} de ${format(date, 'yyyy', { locale: ptBR })}`;
    }

    if (creditAvailability === 'paymentConfirmed') {
      return formatMonthYear(today);
    }

    if (creditAvailability === 'scheduled' && scheduledDate) {
      if (paymentMethod === 'pix') {
        return formatMonthYear(new Date(scheduledDate));
      }

      if (paymentMethod === 'boleto' && scheduledDate) {
        return formatMonthYear(subBusinessDays(new Date(scheduledDate), 1));
      }
    }
    return formatMonthYear(today);
  }

  const addedSugestionCompetenceMonth =
    addedSugestion[RechargeSugestionsEnum.COMPETENCE_MONTH];
  const addedSugestionAwardReason =
    addedSugestion[RechargeSugestionsEnum.AWARD_REASON];

  return (
    <Box padding={8} bg="white" borderRadius="8px">
      <H2 marginBottom={8}>
        {serializeRechargeObservation
          ? 'Observações das notas fiscais'
          : 'Personalizar nota fiscal'}
      </H2>
      {serializeRechargeObservation ? (
        <Box
          padding={4}
          borderRadius="8px"
          backgroundColor="gray.100"
          marginBottom={4}
        >
          {linesArray.map((line, index) => (
            <Text key={index} minHeight={4} maxWidth="75vw">
              {line}
            </Text>
          ))}
        </Box>
      ) : (
        <Text marginBottom={6}>
          Você pode escrever um texto livre para ser incluído nas observações
          das notas fiscais desta recarga
        </Text>
      )}
      {!onlyView && (
        <Button
          variant="link"
          color="brand.aux08"
          fontWeight={600}
          padding={0}
          onClick={rechargeVisibilityConfigModal.onOpen}
        >
          {serializeRechargeObservation
            ? 'Editar observações'
            : 'Incluir observações'}
        </Button>
      )}

      {rechargeVisibilityConfigModal.isOpen && (
        <Modal
          isCentered
          isOpen={rechargeVisibilityConfigModal.isOpen}
          onClose={handleClose}
          size="5xl"
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontWeight={700} paddingBottom={6}>
              Personalizar nota fiscal
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl as="fieldset">
                <FormLabel as="legend" marginBottom={4}>
                  Você pode escrever um texto livre para ser incluído na parte
                  de observações das notas fiscais referentes a esta recarga.
                </FormLabel>
                <FormLabel as="legend" marginBottom={8}>
                  Por padrão, cada nota fiscal emitida já inclui o nome e o
                  valor total do benefício que foi recarregado.
                </FormLabel>

                <Text marginBottom={1} fontSize="14px">
                  Sugestões (não obrigatório)
                </Text>
                <Flex gap={2} marginBottom={8}>
                  <Button
                    paddingX={4}
                    paddingY={3}
                    gap={2}
                    isDisabled={fieldValue.length >= 446}
                    backgroundColor={
                      addedSugestionCompetenceMonth
                        ? 'light.orange'
                        : 'gray.100'
                    }
                    outline={
                      addedSugestionCompetenceMonth ? '1px solid' : 'none'
                    }
                    outlineColor={
                      addedSugestionCompetenceMonth ? 'brand.primary' : 'none'
                    }
                    borderRadius="8px"
                    fontSize="14px"
                    variant="ghost"
                    onClick={() => {
                      if (addedSugestionCompetenceMonth) return;
                      onAddSugestion(RechargeSugestionsEnum.COMPETENCE_MONTH);
                    }}
                  >
                    <Icon
                      as={
                        addedSugestionCompetenceMonth ? FiCheck : FiPlusCircle
                      }
                      width={addedSugestionCompetenceMonth ? '12px' : '16px'}
                      height={addedSugestionCompetenceMonth ? '12px' : '16px'}
                    />
                    Adicionar mês de competência
                  </Button>
                  {checkIfHasAwardInEmployees() && (
                    <Button
                      paddingX={4}
                      paddingY={3}
                      alignItems="center"
                      isDisabled={fieldValue.length >= 435}
                      gap={2}
                      backgroundColor={
                        addedSugestionAwardReason ? 'light.orange' : 'gray.100'
                      }
                      outline={addedSugestionAwardReason ? '1px solid' : 'none'}
                      outlineColor={
                        addedSugestionAwardReason ? 'brand.primary' : 'none'
                      }
                      borderRadius="8px"
                      fontSize="14px"
                      variant="ghost"
                      onClick={() => {
                        if (addedSugestionAwardReason) return;
                        onAddSugestion(RechargeSugestionsEnum.AWARD_REASON);
                      }}
                    >
                      <Icon
                        as={addedSugestionAwardReason ? FiCheck : FiPlusCircle}
                        width={addedSugestionAwardReason ? '12px' : '16px'}
                        height={addedSugestionAwardReason ? '12px' : '16px'}
                      />
                      Adicionar motivo da Premiação
                    </Button>
                  )}
                </Flex>
                <Text marginBottom={1} fontSize="14px">
                  Observações
                </Text>
                <Controller
                  name={`observation`}
                  control={control}
                  render={({ field }) => {
                    const fieldValueLength = field.value.length;
                    return (
                      <Box>
                        <Input
                          as={Textarea}
                          height="116px"
                          resize="none"
                          {...field}
                          maxLength={499}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                          marginTop={2}
                        >
                          <Text fontSize="12px" color="gray.700">
                            {fieldValueLength}/
                            <Text
                              as="span"
                              color={
                                fieldValueLength === maxFieldValue
                                  ? 'brand.primary'
                                  : ''
                              }
                            >
                              {maxFieldValue}
                            </Text>
                          </Text>
                        </Box>
                      </Box>
                    );
                  }}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter marginTop={4}>
              <Button
                variant="outline"
                mr={2}
                onClick={handleClose}
                width="150px"
              >
                Cancelar
              </Button>
              <Button onClick={handleSave} width="250px">
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default RechargeObservation;
