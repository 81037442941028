import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Step,
  StepIndicator,
  StepSeparator,
  Stepper,
  Text,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { FiAlertCircle, FiCheckCircle, FiCopy } from 'react-icons/fi';
import ContactForms from '../../../../components/ContactForms';
import { useGetCardDeliveryInfo } from '../../../../api/card';
import { ReactNode, useEffect } from 'react';
import Spinner from '../../../../components/Spinner';
import { format } from 'date-fns';
import {
  BuildingIcon,
  HouseIcon,
  TruckIcon,
} from '../../../../assets/customIcons';
import { CardDelivery } from '../../../../types/card';
import { CARD_TRACKING_URL } from '../../../../constants/general';
import { Semibold } from '../../../../components/Typography';

type TrackingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  cardId: string | null;
  employeeName: string;
};

type AddressCardProps = {
  address?: CardDelivery['address'];
};

type AddressIconProps = {
  addressType: CardDelivery['address']['type'];
};

const AddressIcon = ({ addressType }: AddressIconProps) => {
  if (addressType === 'COMPANY') {
    return <BuildingIcon color="brand.primary" width="24px" height="24px" />;
  }
  if (addressType === 'RESIDENTIAL') {
    return <HouseIcon color="brand.primary" width="24px" height="24px" />;
  }
};

const AddressCard = ({ address }: AddressCardProps) => {
  if (!address?.state) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      gap={2}
      width="100%"
      borderRadius="8px"
      border="1px solid #CDCCCE"
      padding={6}
      backgroundColor="gray.100"
    >
      <AddressIcon addressType={address.type} />
      <Text fontSize="16px" fontWeight={600} color="gray.700" marginY={4}>
        {address.name}
      </Text>
      <Text fontSize="14px" color="gray.700">
        {address.address}
      </Text>
      <Text fontSize="14px" color="gray.700">
        {address.district}, CEP{' '}
        {address.zipCode &&
          address.zipCode.slice(0, 5) + '-' + address.zipCode.slice(5)}
      </Text>
      <Text fontSize="14px" color="gray.700">
        {address.city} / {address.state}
      </Text>
      <Text fontSize="14px" color="gray.700">
        {address.complement}
      </Text>
    </Flex>
  );
};

const TrackingModal = ({
  employeeName,
  cardId,
  isOpen,
  onClose,
}: TrackingModalProps) => {
  const {
    data: cardDeliveryInfo,
    isFetching,
    isError,
  } = useGetCardDeliveryInfo(cardId);

  const trackingCode = cardDeliveryInfo?.code || '';
  const address = cardDeliveryInfo?.address;

  const { hasCopied, onCopy } = useClipboard(trackingCode);

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="90%" overflowY="auto" padding={0}>
        <ModalCloseButton top="27px" right="30px" width="24px" height="24px" />

        <ModalBody p={8}>
          <Flex
            flexDirection="column"
            alignItems="center"
            gap={6}
            marginBottom={8}
          >
            <TruckIcon width="40px" height="36px" />
            <Text fontSize="18px" fontWeight={700}>
              Rastrear cartão
            </Text>
          </Flex>

          {isFetching ? (
            <Spinner size="md" />
          ) : (
            <Box>
              <Flex flexDirection="column" gap={6}>
                <Text fontSize="16px">
                  Nome do colaborador:{' '}
                  <Text as="span" fontWeight={600} marginLeft={2}>
                    {employeeName}
                  </Text>
                </Text>
                {cardDeliveryInfo && (
                  <Text fontSize="16px">
                    Data de solicitação do cartão:{' '}
                    <Text as="span" fontWeight={600} marginLeft={2}>
                      {cardDeliveryInfo.card.createdAt &&
                        format(
                          new Date(cardDeliveryInfo.card.createdAt),
                          'dd/MM/yy'
                        )}
                    </Text>
                  </Text>
                )}

                {cardDeliveryInfo && (
                  <Box>
                    <Text fontSize="16px" marginBottom={2}>
                      Endereço de entrega
                    </Text>

                    <AddressCard address={address} />
                  </Box>
                )}
              </Flex>

              {cardDeliveryInfo && (
                <Stepper
                  orientation="vertical"
                  gap={6}
                  index={-1}
                  marginY="40px"
                >
                  <Step>
                    <StepIndicator
                      backgroundColor="gray.100"
                      border="2px solid gray.500"
                      marginRight={4}
                    >
                      <Text fontSize="16px" fontWeight={600}>
                        1
                      </Text>
                    </StepIndicator>

                    <Stack>
                      <Text fontSize="16px">
                        Copie o <Semibold>código de rastreio</Semibold>
                      </Text>

                      <InputGroup width="324px">
                        <InputRightElement
                          as={Button}
                          variant="unstyled"
                          borderRadius="lg"
                          minWidth="120px"
                          cursor="pointer"
                          onClick={onCopy}
                        >
                          {hasCopied ? (
                            <Flex alignItems="center" color="feedback.success">
                              <Icon as={FiCheckCircle} boxSize="20px" />
                              <Link
                                textDecor="underline"
                                fontWeight={600}
                                ml={1}
                              >
                                Copiado!
                              </Link>
                            </Flex>
                          ) : (
                            <Flex alignItems="center" color="brand.aux08">
                              <Icon as={FiCopy} boxSize="22px" />
                              <Link
                                textDecor="underline"
                                fontWeight={600}
                                ml={1}
                              >
                                Copiar
                              </Link>
                            </Flex>
                          )}
                        </InputRightElement>
                        <Input
                          defaultValue={trackingCode}
                          paddingRight="120px"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          readOnly
                        />
                      </InputGroup>
                    </Stack>

                    <StepSeparator as={Box} marginTop={3} />
                  </Step>

                  <Step>
                    <StepIndicator
                      backgroundColor="gray.100"
                      border="2px solid gray.500"
                      marginRight={4}
                    >
                      <Text fontSize="16px" fontWeight={600}>
                        2
                      </Text>
                    </StepIndicator>

                    <Box width="280px">
                      <Text fontSize="16px" lineHeight="130%">
                        Acesse{' '}
                        <Link
                          href={CARD_TRACKING_URL}
                          color="brand.aux08"
                          target="_blank"
                          fontWeight={500}
                        >
                          o site da transportadora
                        </Link>
                        , selecione "REMESSA/NPC" e{' '}
                        <Semibold>cole o código</Semibold> para localizar o
                        cartão. Você também pode utilizar o{' '}
                        <Semibold>CPF do colaborador</Semibold> para rastrear o
                        cartão.
                      </Text>
                    </Box>

                    <StepSeparator />
                  </Step>
                </Stepper>
              )}

              {!cardDeliveryInfo && (
                <Alert
                  status="warning"
                  marginBottom={6}
                  borderRadius="lg"
                  backgroundColor="light.orange"
                  marginTop={4}
                >
                  <AlertIcon as={FiAlertCircle} color="brand.primary" />
                  Entrega não rastreável.
                </Alert>
              )}

              <Box>
                <Text
                  fontSize="16px"
                  fontWeight={600}
                  marginBottom={6}
                  lineHeight="130%"
                >
                  Se você precisar de ajuda, você pode falar com o nosso
                  atendimento:
                </Text>
                <ContactForms />
              </Box>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type TrackingCardModalProps = {
  children?: ReactNode;
  cardId: string | null;
  employeeName: string;
};

const TrackingCardModal = ({
  employeeName,
  cardId,
  children,
}: TrackingCardModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {children ? (
        <Box onClick={onOpen}>{children}</Box>
      ) : (
        <Link
          color="brand.aux08"
          textDecor="underline"
          display="flex"
          alignItems="center"
          minHeight="fit-content"
          gap={2}
          onClick={onOpen}
        >
          <TruckIcon />
          Rastrear cartão
        </Link>
      )}

      {isOpen && (
        <TrackingModal
          employeeName={employeeName}
          cardId={cardId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default TrackingCardModal;
