export const dromoStyleOverride = {
  global: {
    successColor: '#1e7500',
    warningColor: '#db0200',
  },
  primaryButton: {
    backgroundColor: '#DE8706',
    textColor: '#ffffff',
    hoverBackgroundColor: '#AE6A02',
    hoverTextColor: '#ffffff',
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    textColor: '#DE8706',
    border: '1px solid #DE8706',
    hoverBackgroundColor: '#fff4db',
    hoverTextColor: '#fffff',
    hoverBorder: '1px solid #DE8706',
  },
  stepperBar: {
    currentColor: '#DE8706',
  },
};
