import { Box, DrawerContentProps, Flex, Link } from '@chakra-ui/react';
import RechargeSpreadsheetStepper from './components/RechargeSpreadsheetStepper';
import RechargeSpreadsheetInstructionsCard from './components/RechargeSpreadsheetInstructionsCard';
import RechargeSpreadsheetUploader from './components/RechargeSpreadsheetUploader';
import BenefitModal from '../BenefitsPage/components/BenefitModal';
import { SunRiseIcon } from '../../assets/customIcons';
import { useGetEmployeesDocument } from '../../api/employees';
import Spinner from '../../components/Spinner';
import { useGetSelectedCompanyBenefitCategories } from '../../api/benefit';

const actionDrawerHeight: DrawerContentProps['height'] = '120px';

const RechargeSpreadsheet = () => {
  const { data: existingCPFs = [], isFetching: isFetchingEmployeesDocument } =
    useGetEmployeesDocument({
      onlyActive: true,
    });

  const {
    data: benefitCategories = [],
    isFetching: isFetchingBenefitCategories,
  } = useGetSelectedCompanyBenefitCategories({
    onlyActive: true,
  });

  if (isFetchingEmployeesDocument || isFetchingBenefitCategories) {
    return <Spinner />;
  }

  return (
    <>
      <Box paddingBottom={actionDrawerHeight} height="100%">
        <RechargeSpreadsheetStepper />
        <Box height="100%" gap={6}>
          <Flex gap={6} width="100%" height="fit-content">
            <RechargeSpreadsheetInstructionsCard
              benefitCategories={benefitCategories}
            />

            <RechargeSpreadsheetUploader
              existingCPFs={existingCPFs}
              benefitCategories={benefitCategories}
            />
          </Flex>

          <Flex marginTop={6}>
            <BenefitModal>
              <Link
                color="brand.aux08"
                textDecor="underline"
                fontWeight={600}
                display="flex"
                minHeight="fit-content"
                gap={2}
              >
                <SunRiseIcon />
                Saiba mais sobre cada benefício
              </Link>
            </BenefitModal>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default RechargeSpreadsheet;
