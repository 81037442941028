import { ReactNode } from 'react';
import CustomTooltip from '../../../../components/CustomTooltip';
import { Box, Link, LinkProps, useToast } from '@chakra-ui/react';

type CustomDownloadLinkProps = LinkProps & {
  href?: string;
  children: ReactNode;
  toolTipText?: string;
};

const CustomDownloadLink = ({
  href,
  children,
  toolTipText,
  ...linkProps
}: CustomDownloadLinkProps) => {
  const toast = useToast();

  return (
    <CustomTooltip label={toolTipText ?? ''}>
      <Box
        as={Link}
        // only add href if it is defined
        {...(href ? { href } : {})}
        download={true}
        boxSize="32px"
        backgroundColor="gray.100"
        borderRadius={4}
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        _hover={{
          backgroundColor: 'orange.50',
          color: 'orange.500',
        }}
        cursor="pointer"
        onClick={() => {
          if (href) {
            toast({
              title: 'Pedido de download criado com sucesso!',
              status: 'success',
            });
          }
        }}
        {...linkProps}
      >
        {children}
      </Box>
    </CustomTooltip>
  );
};

export default CustomDownloadLink;
