import React, { memo, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Text,
  Input,
  Flex,
  Divider,
  Grid,
  GridItem,
  FormErrorMessage,
  Checkbox,
} from '@chakra-ui/react';
import { z } from 'zod';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from '../../../../components/DatePicker';
import Select from '../../../../components/Select';
import { UFs } from '../../../../constants/general';
import { IMaskInput } from 'react-imask';
import { employeeInfoSchema } from '../../../../types/schemas/employeeRegistrationSchema';
import AddButton from '../../../../components/AddButton';
import HelpMessage from '../../../../components/HelpMessage';
import ConnectForm from '../../../../components/ConnectForm';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import CompanyNameDisplay from '../../../../components/CompanyNameDisplay';
import { cpfValidationSchema } from '../../../../types/schemas/cpfValidationSchema';
import Spinner from '../../../../components/Spinner';
import { EmployeeInfo } from '../../../../types/employee';
import DocumentAlert from '../DocumentAlert';

export type FormValues = z.infer<typeof employeeInfoSchema>;

type EmployeeInfoFormProps = {
  isFetchedByZipCode: boolean;
  isFetchingByZipCode: boolean;
  isErrorConsult: boolean;
  handleResetAddress: () => void;
  employeeInfo: EmployeeInfo[] | undefined;
};

const EmployeeInfoForm = memo(
  ({
    isFetchedByZipCode,
    isFetchingByZipCode,
    isErrorConsult,
    handleResetAddress,
    employeeInfo,
  }: EmployeeInfoFormProps) => {
    const [showSocialNameInput, setShowSocialNameInput] = useState(false);

    const { selectedCompany } = useUserCompaniesStore();

    return (
      <ConnectForm<FormValues>>
        {({
          control,
          register,
          watch,
          setValue,
          getValues,
          formState: { errors },
          clearErrors,
          trigger,
        }) => {
          // TODO: Employee CPF should be checked against the database on the backend and fill the fields with existing user data if available. All inputs should be disabled until the request is completed.
          const document = watch('collaboratorData.document');
          const isValidCPF =
            cpfValidationSchema.safeParse(document).success &&
            employeeInfo?.length === 0;

          const selectedDate = getValues('collaboratorData.admissionDate');

          const socialName = getValues('collaboratorData.socialName');
          const addressWithoutNumber = watch(
            'collaboratorData.address.addressWithoutNumber'
          );
          const addressWithoutComplement = watch(
            'collaboratorData.address.addressWithoutComplement'
          );

          const state = watch('collaboratorData.address.state');

          return (
            <Flex direction="column" gap={4}>
              <Text fontWeight={600}>Dados principais</Text>
              <Flex gap={4} flexWrap="wrap">
                <FormControl flexBasis="160px" flexGrow={1}>
                  <FormLabel fontSize="sm" whiteSpace="nowrap">
                    Empresa
                  </FormLabel>
                  <CompanyNameDisplay companyName={selectedCompany?.name} />
                </FormControl>
                <FormControl
                  flexBasis="160px"
                  flexGrow={1}
                  isInvalid={!!errors.collaboratorData?.document}
                >
                  <FormLabel fontSize="sm">CPF</FormLabel>
                  {/* TODO: checked CPF against the database */}
                  <Controller
                    control={control}
                    name="collaboratorData.document"
                    render={({ field }) => (
                      <Input
                        as={IMaskInput}
                        type="text"
                        mask="000.000.000-00"
                        unmask={true}
                        value={field.value}
                        onAccept={(value: string) => {
                          setValue('collaboratorData.document', value);
                        }}
                        onBlurCapture={() =>
                          trigger('collaboratorData.document')
                        }
                      />
                    )}
                  />

                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="collaboratorData.document"
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl flexBasis="160px" flexGrow={1}>
                  <FormLabel fontSize="sm" display="flex" gap={1}>
                    Matrícula (opcional){' '}
                    <HelpMessage
                      iconProps={{ fontSize: 'md' }}
                      label="Código que identifica o colaborador internamente na empresa"
                    />
                  </FormLabel>
                  <Input
                    type="text"
                    {...register('collaboratorData.registration')}
                    isDisabled={!isValidCPF}
                  />
                </FormControl>
                <FormControl
                  flexBasis="160px"
                  flexGrow={1}
                  isInvalid={!!errors.collaboratorData?.admissionDate}
                >
                  <FormLabel
                    fontSize="sm"
                    whiteSpace="nowrap"
                    display="flex"
                    gap={1}
                  >
                    Data de admissão (opcional)
                    <HelpMessage
                      iconProps={{ fontSize: 'md' }}
                      label="Essa informação ajuda no cálculo do valor da primeira recarga de benefícios e no envio de comunicações ao colaborador"
                    />
                  </FormLabel>
                  <Controller
                    control={control}
                    name="collaboratorData.admissionDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        onBlur={() => trigger('collaboratorData.admissionDate')}
                        isDisabled={!isValidCPF}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="collaboratorData.admissionDate"
                    />
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              {!isValidCPF && employeeInfo && (
                <DocumentAlert
                  collaboratorId={employeeInfo[0].collaboratorId}
                  status={employeeInfo[0].status}
                />
              )}
              <Flex gap={4}>
                <FormControl
                  isInvalid={isValidCPF && !!errors.collaboratorData?.fullName}
                >
                  <FormLabel fontSize="sm">Nome do colaborador</FormLabel>
                  <Input
                    type="text"
                    {...register('collaboratorData.fullName')}
                    onBlurCapture={() => trigger('collaboratorData.fullName')}
                    isDisabled={!isValidCPF}
                  />
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="collaboratorData.fullName"
                    />
                  </FormErrorMessage>
                </FormControl>
                {(showSocialNameInput || socialName) && (
                  <FormControl
                    isInvalid={!!errors.collaboratorData?.socialName}
                  >
                    <FormLabel fontSize="sm">
                      Nome social (opcional)
                      <HelpMessage
                        iconProps={{ ml: 1 }}
                        label="Ao preencher o nome social, ele será impresso no cartão físico e usado nas comunicações ao colaborador"
                      />
                    </FormLabel>
                    <Input
                      type="text"
                      {...register('collaboratorData.socialName')}
                      isDisabled={!isValidCPF}
                    />
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.socialName"
                      />
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Flex>

              {!(showSocialNameInput || socialName) && (
                <Flex>
                  <AddButton
                    borderRadius="md"
                    padding={0}
                    onClick={() => setShowSocialNameInput(true)}
                    isDisabled={!isValidCPF}
                  >
                    Adicionar nome social{' '}
                  </AddButton>
                  <HelpMessage
                    iconProps={{ ml: 1 }}
                    label="Ao preencher o nome social, ele será impresso no cartão físico e usado nas comunicações ao colaborador"
                  />
                </Flex>
              )}

              <Divider marginY={8} />

              <Text fontWeight={600}>Contato</Text>
              <Text>
                Você precisa informar{' '}
                <Text as="span" fontWeight={600}>
                  pelo menos um celular ou um e-mail
                </Text>
                . Se quiser, pode informar os dois. Os meios de contato são
                utilizados para envio de comunicação
                {/* <HelpMessage
                  iconProps={{ position: 'relative', top: '2px' }}
                  label="Usamos esses dados para enviar mensagens ao colaborador, como as instruções de primeiro acesso ao aplicativo."
                /> */}
              </Text>
              <Flex gap={4}>
                <Flex direction="column" width="50%">
                  <FormControl isInvalid={!!errors.collaboratorData?.cellphone}>
                    <FormLabel fontSize="sm">Celular</FormLabel>
                    <Controller
                      control={control}
                      name="collaboratorData.cellphone"
                      render={({ field }) => (
                        <Input
                          ref={field.ref}
                          as={IMaskInput}
                          mask="(00) 00000-0000"
                          isDisabled={!isValidCPF}
                          unmask={true}
                          value={field.value}
                          onAccept={(value: string) =>
                            setValue('collaboratorData.cellphone', value)
                          }
                          onBlur={() => {
                            field.onBlur();
                            trigger([
                              'collaboratorData.email',
                              'collaboratorData.personalEmail',
                              'collaboratorData.cellphone',
                              'collaboratorData.alternativePhone',
                            ]);
                          }}
                        />
                      )}
                    />

                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.cellphone"
                      />
                    </FormErrorMessage>
                    {/* Button should be hidden for now because backend is lacking this implementation */}
                    {/* {!(showAlternativePhoneInput || alternativePhone) && (
                      <AddButton
                        borderRadius="md"
                        padding={0}
                        marginTop={4}
                        onClick={() => setShowAlternativePhoneInput(true)}
                        isDisabled={!isValidCPF}
                      >
                        Adicionar telefone
                      </AddButton>
                    )} */}
                  </FormControl>

                  {/* {(showAlternativePhoneInput || alternativePhone) && (
                    <FormControl
                      isInvalid={!!errors.collaboratorData?.alternativePhone}
                    >
                      <FormLabel fontSize="sm">Telefone</FormLabel>
                      <Controller
                        control={control}
                        name="collaboratorData.alternativePhone"
                        render={({ field }) => (
                          <Input
                            ref={field.ref}
                            as={IMaskInput}
                            mask="(00) 00000-0000"
                            isDisabled={!isValidCPF}
                            unmask={true}
                            value={field.value}
                            onAccept={(value: string) =>
                              setValue(
                                'collaboratorData.alternativePhone',
                                value
                              )
                            }
                            onBlur={() => {
                              field.onBlur();
                              trigger([
                                'collaboratorData.email',
                                'collaboratorData.personalEmail',
                                'collaboratorData.cellphone',
                                'collaboratorData.alternativePhone',
                              ]);
                            }}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        <ErrorMessage
                          errors={errors}
                          name="collaboratorData.alternativePhone"
                        />
                      </FormErrorMessage>
                    </FormControl>
                  )} */}
                </Flex>

                <Flex width="50%" direction="column" gap={4}>
                  <FormControl isInvalid={!!errors.collaboratorData?.email}>
                    <FormLabel fontSize="sm">E-mail </FormLabel>
                    <Input
                      type="text"
                      {...register('collaboratorData.email')}
                      onChange={(e) => {
                        setValue(
                          'collaboratorData.email',
                          e.target.value.replace(/\s/g, ''),
                          { shouldDirty: true }
                        );
                      }}
                      isDisabled={!isValidCPF}
                    />
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.email"
                      />
                    </FormErrorMessage>
                    {/* {!(showPersonalEmailInput || personalEmail) && (
                      <AddButton
                        borderRadius="md"
                        padding={0}
                        marginTop={4}
                        onClick={() => setShowPersonalEmailInput(true)}
                        isDisabled={!isValidCPF}
                      >
                        Adicionar e-mail pessoal
                      </AddButton>
                    )} */}
                  </FormControl>

                  {/* {(showPersonalEmailInput || personalEmail) && (
                    <FormControl
                      isInvalid={!!errors.collaboratorData?.personalEmail}
                    >
                      <FormLabel fontSize="sm">E-mail pessoal</FormLabel>
                      <Input
                        type="text"
                        {...register('collaboratorData.personalEmail')}
                        isDisabled={!isValidCPF}
                        onBlurCapture={() =>
                          trigger([
                            'collaboratorData.email',
                            'collaboratorData.personalEmail',
                            'collaboratorData.cellphone',
                            'collaboratorData.alternativePhone',
                          ])
                        }
                      />
                      <FormErrorMessage>
                        <ErrorMessage
                          errors={errors}
                          name="collaboratorData.personalEmail"
                        />
                      </FormErrorMessage>
                    </FormControl>
                  )} */}
                </Flex>
              </Flex>

              <Divider marginY={8} />

              <Text fontWeight={600}>Endereço</Text>
              <Text>
                Usaremos esse endereço se o{' '}
                <Text as="span" fontWeight={600}>
                  local de entrega do cartão{' '}
                </Text>
                for direto{' '}
                <Text as="span" fontWeight={600}>
                  para o colaborador
                </Text>
              </Text>

              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={2}>
                  <FormControl
                    isInvalid={!!errors.collaboratorData?.address?.zipCode}
                    position="relative"
                  >
                    <FormLabel fontSize="sm">CEP</FormLabel>
                    <Controller
                      control={control}
                      name="collaboratorData.address.zipCode"
                      render={({ field }) => (
                        <Input
                          ref={field.ref}
                          as={IMaskInput}
                          inputRef={field.ref}
                          type="text"
                          mask="00000-000"
                          unmask={true}
                          value={field.value}
                          onAccept={(value: string) => {
                            setValue('collaboratorData.address.zipCode', value);
                            if (value?.length < 8 && state) {
                              handleResetAddress();
                            }
                          }}
                          paddingRight={isFetchingByZipCode ? '32px' : '16px'}
                          isDisabled={!isValidCPF}
                          onBlur={() => {
                            trigger('collaboratorData.address.zipCode');
                          }}
                        />
                      )}
                    />

                    {isFetchingByZipCode && (
                      <Spinner
                        size="sm"
                        centerProps={{
                          position: 'absolute',
                          top: '45px',
                          right: '16px',
                          margin: '0',
                        }}
                      />
                    )}
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.address.zipCode"
                      />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl
                    isInvalid={!!errors.collaboratorData?.address?.address}
                  >
                    <FormLabel fontSize="sm">Endereço</FormLabel>
                    <Input
                      type="text"
                      {...register('collaboratorData.address.address')}
                      onBlurCapture={() => trigger('collaboratorData.address')}
                      isDisabled={!isValidCPF || !isFetchedByZipCode}
                      maxLength={40}
                    />
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.address.address"
                      />
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl
                    isInvalid={!!errors.collaboratorData?.address?.number}
                  >
                    <FormLabel fontSize="sm">Número</FormLabel>
                    <Input
                      type="number"
                      {...register('collaboratorData.address.number')}
                      isDisabled={
                        !!addressWithoutNumber ||
                        !isValidCPF ||
                        !isFetchedByZipCode
                      }
                      onBlurCapture={() => trigger('collaboratorData.address')}
                      maxLength={40}
                    />
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.address.number"
                      />
                    </FormErrorMessage>
                  </FormControl>
                  <Checkbox
                    {...register(
                      'collaboratorData.address.addressWithoutNumber'
                    )}
                    onInput={() =>
                      trigger([
                        'collaboratorData.address.number',
                        'collaboratorData.address.addressWithoutNumber',
                      ])
                    }
                    marginTop={2}
                    isDisabled={!isValidCPF || !isFetchedByZipCode}
                  >
                    Sem número
                  </Checkbox>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl
                    isInvalid={!!errors.collaboratorData?.address?.complement}
                  >
                    <FormLabel fontSize="sm">Complemento</FormLabel>
                    <Input
                      type="text"
                      {...register('collaboratorData.address.complement')}
                      isDisabled={
                        !!addressWithoutComplement ||
                        !isValidCPF ||
                        !isFetchedByZipCode
                      }
                      onBlurCapture={() => trigger('collaboratorData.address')}
                      maxLength={30}
                    />
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={errors}
                        name="collaboratorData.address.complement"
                      />
                    </FormErrorMessage>
                  </FormControl>
                  <Checkbox
                    {...register(
                      'collaboratorData.address.addressWithoutComplement'
                    )}
                    onInput={() =>
                      trigger([
                        'collaboratorData.address.complement',
                        'collaboratorData.address.addressWithoutComplement',
                      ])
                    }
                    marginTop={2}
                    isDisabled={!isValidCPF || !isFetchedByZipCode}
                  >
                    Sem complemento
                  </Checkbox>
                </GridItem>
              </Grid>

              <Flex gap={4}>
                <FormControl
                  isInvalid={!!errors.collaboratorData?.address?.district}
                >
                  <FormLabel fontSize="sm">Bairro/Distrito</FormLabel>
                  <Input
                    type="text"
                    {...register('collaboratorData.address.district')}
                    isDisabled={!isValidCPF || !isFetchedByZipCode}
                    onBlurCapture={() => trigger('collaboratorData.address')}
                    maxLength={30}
                  />
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="collaboratorData.address.district"
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.collaboratorData?.address?.city}
                >
                  <FormLabel fontSize="sm">Cidade</FormLabel>
                  <Input
                    type="text"
                    {...register('collaboratorData.address.city')}
                    isDisabled={
                      !isValidCPF || !isFetchedByZipCode || !isErrorConsult
                    }
                    onBlurCapture={() => trigger('collaboratorData.address')}
                    maxLength={30}
                  />
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="collaboratorData.address.city"
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.collaboratorData?.address?.state}
                >
                  <FormLabel fontSize="sm">Estado</FormLabel>
                  <Controller
                    name="collaboratorData.address.state"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputId="collaboratorData.address.state"
                        options={UFs}
                        {...field}
                        onChange={(state) => field.onChange(state?.value)}
                        value={UFs.find((uf) => uf.value === field.value)}
                        isDisabled={
                          !isValidCPF || !isFetchedByZipCode || !isErrorConsult
                        }
                        onMenuOpen={() =>
                          clearErrors('collaboratorData.address.state')
                        }
                      />
                    )}
                  />
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="collaboratorData.address.state"
                    />
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
          );
        }}
      </ConnectForm>
    );
  },
  (prevProps, nextProps) => prevProps === nextProps
);

export default EmployeeInfoForm;
