import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
  VisuallyHidden,
  useToast,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { z } from 'zod';
import Title from '../../components/Title';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useFetchContext } from '../../hooks/useFetchContext';
import FirstAccessLayout from '../../layouts/FirstAccessLayout';
import PasswordInput from '../../components/PasswordInput';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import ContactModalButton from '../../components/ContactModalButton';
import { AuthState } from '../../types/auth';

const FIELD_REQUIRED_MESSAGE = 'Você precisa preencher este campo.';

const schema = z.object({
  username: z
    .string({ required_error: FIELD_REQUIRED_MESSAGE })
    .email('E-mail inválido'),
  password: z
    .string({ required_error: FIELD_REQUIRED_MESSAGE })
    .min(1, { message: FIELD_REQUIRED_MESSAGE }),
});

type FormValues = z.infer<typeof schema>;

const LoginPage = () => {
  const { handleSubmit, formState, register, setError, watch } =
    useForm<FormValues>({
      resolver: zodResolver(schema),
    });
  const { errors } = formState;

  const { api } = useFetchContext();
  const { setAuthInfo, isAuthenticated } = useAuthContext();

  const { setSelectedCompany } = useUserCompaniesStore();

  const toast = useToast();

  const signIn = useMutation({
    mutationFn: (values: FormValues) =>
      api.post<AuthState>('/api/auth/login', values),
    onSuccess: (data) => {
      const user = data.data.user;
      setSelectedCompany(user?.selectedCompany);
    },
    onError: (error: AxiosError) => {
      const status = error.response?.status;
      if (status === 404 || status === 401) {
        setError('username', { message: '' });
        setError('password', { message: '' });
      } else {
        toast({
          title: 'Erro ao fazer login',
          description: 'Tente novamente mais tarde.',
          status: 'error',
        });
      }
    },
  });

  const email = watch('username');

  const authInfo = signIn.data?.data;
  const isAuthed = isAuthenticated();

  const onSubmit = (values: FormValues) => {
    signIn.mutate(values);
  };

  useEffect(() => {
    if (authInfo) {
      setAuthInfo(authInfo);
    }
  }, [authInfo, setAuthInfo]);

  if (isAuthed) {
    return <Navigate to="/" />;
  }

  return (
    <FirstAccessLayout variant="login">
      <Box>
        <Card
          backgroundColor="gray.50"
          width={{ base: '400px', lg: '478px' }}
          boxShadow="none"
        >
          <CardHeader>
            <Title>Login</Title>
          </CardHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody paddingTop={0}>
              <VStack spacing={6}>
                <ErrorMessage
                  errors={errors}
                  name={'username' || 'password'}
                  render={() => (
                    <Text width="100%" textAlign="left" color="feedback.alert">
                      Parece que seu e-mail ou senha estão incorretos.
                    </Text>
                  )}
                />
                <FormControl isInvalid={!!errors.username}>
                  <VisuallyHidden>
                    <FormLabel>Email</FormLabel>
                  </VisuallyHidden>
                  <Input
                    type="username"
                    autoComplete="username"
                    placeholder="Qual seu e-mail"
                    {...register('username')}
                  />
                  <FormErrorMessage>
                    <ErrorMessage name="username" errors={errors} />
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.password}>
                  <VisuallyHidden>
                    <FormLabel>Senha</FormLabel>
                  </VisuallyHidden>
                  <PasswordInput
                    placeholder="Sua senha"
                    {...register('password')}
                  />
                  <FormErrorMessage>
                    <ErrorMessage name="password" errors={errors} />
                  </FormErrorMessage>
                </FormControl>
                <Text
                  as="span"
                  color="brand.aux08"
                  width="100%"
                  textAlign="right"
                  marginTop={-4}
                >
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/solicitar-troca-de-senha"
                    state={{ emailFromLoginPage: email }}
                    textDecor="underline"
                    fontWeight={500}
                  >
                    Esqueci a senha
                  </ChakraLink>
                </Text>
                <Button type="submit" width="100%" isLoading={signIn.isPending}>
                  Entrar
                </Button>
              </VStack>
            </CardBody>
          </form>
        </Card>
        <Text textAlign="center" marginTop={4}>
          Ainda não tem cadastro? <ContactModalButton />
          <Text as="span" color="brand.aux08"></Text>
        </Text>
      </Box>
    </FirstAccessLayout>
  );
};

export default LoginPage;
