import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  ModalFooter,
  Button,
  Box,
  Text,
  FormErrorMessage,
  UseDisclosureProps,
} from '@chakra-ui/react';
import InputWithCounter from '../../../../components/InputWithCounter';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import {
  RechargeCancelReason,
  RechargeListInfo,
} from '../../../../types/recharge';
import { useCancelRecharge } from '../../../../api/recharge';
import RedirectInfosCard from '../RedirectInfoCard';

type CancelRechargeModalProps = UseDisclosureProps & {
  isOpen: boolean;
  onClose: () => void;
  rechargeInfos: RechargeListInfo;
};

const schema = z
  .object({
    reason: z.nativeEnum(RechargeCancelReason, {
      required_error: 'Você precisa selecionar uma opção',
    }),
    description: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.reason === RechargeCancelReason.OTHER && !data.description) {
        return false;
      }
      return true;
    },
    {
      message: 'Você precisa dizer o motivo da solicitação',
      path: ['description'],
    }
  );

type FormValues = z.infer<typeof schema>;

const BilletFormLabel = () => {
  return (
    <Text>
      Assim que você concluir a solicitação,
      <Text as="span" fontWeight={600}>
        {' '}
        o boleto e a nota fiscal da recarga serão cancelados. O crédito
      </Text>{' '}
      aos colaboradores{' '}
      <Text as="span" fontWeight={600}>
        não será feito
      </Text>
    </Text>
  );
};

const PixFormLabel = () => {
  return (
    <Text>
      Assim que você concluir a solicitação,{' '}
      <Text as="span" fontWeight={600}>
        o Pix e a nota fiscal serão cancelados da recarga. O crédito
      </Text>{' '}
      aos colaboradores{' '}
      <Text as="span" fontWeight={600}>
        não será feito
      </Text>
    </Text>
  );
};

type DueDatePaymentButtonLinkEditRechargeProps = {
  formValues: FormValues;
  handleClick: (formValues: FormValues) => void;
};

const DueDatePaymentButtonLinkEditRecharge = ({
  formValues,
  handleClick,
}: DueDatePaymentButtonLinkEditRechargeProps) => {
  return (
    <Button
      variant="link"
      marginLeft={8}
      color="brand.aux08"
      fontWeight={500}
      padding={0}
      onClick={() => handleClick(formValues)}
    >
      Editar recarga e alterar a data de crédito
    </Button>
  );
};

type OverduePaymentButtonLinkEditRechargeProps = {
  data: FormValues;
  paymentType: string;
  handleClick: (data: FormValues) => void;
};

const OverduePaymentButtonLinkEditRecharge = ({
  data,
  handleClick,
  paymentType,
}: OverduePaymentButtonLinkEditRechargeProps) => {
  const buttonText = {
    PIX: 'Editar recarga e gerar novo código Pix',
    BOLETO: 'Editar recarga e gerar novo boleto',
  }[paymentType];

  return (
    <Button
      variant="link"
      marginLeft={8}
      color="brand.aux08"
      fontWeight={500}
      padding={0}
      onClick={() => handleClick(data)}
    >
      {buttonText}
    </Button>
  );
};

const CancelRechargeOptionsModal = ({
  isOpen,
  onClose,
  rechargeInfos,
}: CancelRechargeModalProps) => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      description: '',
    },
  });

  const selectedReason = watch('reason');

  const lessThanThreeModifications = rechargeInfos.modified < 3;

  const cancelRecharge = useCancelRecharge();

  function onSubmit(data: FormValues) {
    cancelRecharge.mutate({
      id: rechargeInfos.id,
      reason: data.reason,
      observation: data.description,
    });
  }
  const handleEditRecharge = (data: FormValues) => {
    navigate('/recargas/editar-recarga', {
      state: { recharge: rechargeInfos, reason: data },
    });
  };

  const cancelRechargeOptionsPix = [
    {
      label: 'O Pix está vencido',
      value: RechargeCancelReason.OVERDUE_PAYMENT,
      component: (
        <RedirectInfosCard
          lessThanThreeModifications={lessThanThreeModifications}
          hideRedirectInfosCard={
            selectedReason === RechargeCancelReason.OVERDUE_PAYMENT
          }
          displayText={
            <Text as="span">
              Você pode gerar um{' '}
              <Text as="span" fontWeight={600}>
                novo código Pix
              </Text>
            </Text>
          }
          buttonRedirect={
            <OverduePaymentButtonLinkEditRecharge
              data={{ reason: selectedReason }}
              handleClick={handleEditRecharge}
              paymentType={rechargeInfos.paymentType}
            />
          }
        />
      ),
    },
    {
      label: 'Preciso mudar a data de crédito',
      value: RechargeCancelReason.DUE_DATE_CHANGE,
      component: (
        <RedirectInfosCard
          lessThanThreeModifications={lessThanThreeModifications}
          hideRedirectInfosCard={
            selectedReason === RechargeCancelReason.DUE_DATE_CHANGE
          }
          displayText={
            <Text>
              Você pode alterar a{' '}
              <Text as="span" fontWeight={600}>
                {' '}
                data de crédito
              </Text>
            </Text>
          }
          buttonRedirect={
            <DueDatePaymentButtonLinkEditRecharge
              formValues={{ reason: selectedReason }}
              handleClick={handleEditRecharge}
            />
          }
        />
      ),
    },
    {
      label: 'Os valores da recarga estão errados',
      value: RechargeCancelReason.WRONG_VALUES,
    },
    {
      label: 'Os benefícios e categorias da recarga estão errados',
      value: RechargeCancelReason.WRONG_BENEFITS,
    },
    {
      label: 'A lista de colaboradores na recarga está errada',
      value: RechargeCancelReason.WRONG_EMPLOYEES,
    },
    { label: 'Outro motivo', value: RechargeCancelReason.OTHER },
  ];

  const cancelRechargeOptionsBoleto = [
    {
      label: 'O boleto está vencido',
      value: RechargeCancelReason.OVERDUE_PAYMENT,
      component: (
        <RedirectInfosCard
          lessThanThreeModifications={lessThanThreeModifications}
          hideRedirectInfosCard={
            selectedReason === RechargeCancelReason.OVERDUE_PAYMENT
          }
          displayText={
            <Text as="span">
              Você pode gerar um{' '}
              <Text as="span" fontWeight={600}>
                novo boleto
              </Text>
            </Text>
          }
          buttonRedirect={
            <OverduePaymentButtonLinkEditRecharge
              data={{ reason: selectedReason }}
              handleClick={handleEditRecharge}
              paymentType={rechargeInfos.paymentType}
            />
          }
        />
      ),
    },
    {
      label: 'Preciso mudar a data de crédito',
      value: RechargeCancelReason.DUE_DATE_CHANGE,
      component: (
        <RedirectInfosCard
          lessThanThreeModifications={lessThanThreeModifications}
          hideRedirectInfosCard={
            selectedReason === RechargeCancelReason.DUE_DATE_CHANGE
          }
          displayText={
            <Text>
              Você pode alterar a{' '}
              <Text as="span" fontWeight={600}>
                {' '}
                data de crédito
              </Text>
            </Text>
          }
          buttonRedirect={
            <DueDatePaymentButtonLinkEditRecharge
              formValues={{ reason: selectedReason }}
              handleClick={handleEditRecharge}
            />
          }
        />
      ),
    },
    {
      label: 'Os valores da recarga estão errados',
      value: RechargeCancelReason.WRONG_VALUES,
    },
    {
      label: 'Os benefícios e categorias da recarga estão errados',
      value: RechargeCancelReason.WRONG_BENEFITS,
    },
    {
      label: 'A lista de colaboradores na recarga está errada',
      value: RechargeCancelReason.WRONG_EMPLOYEES,
    },
    { label: 'Outro motivo', value: RechargeCancelReason.OTHER },
  ];

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent padding={8}>
        <ModalHeader fontWeight={700} padding={0}>
          Cancelar recarga
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody padding={0} marginTop={4}>
            <FormLabel as="legend" marginBottom={4} color="brand.aux08">
              {rechargeInfos.paymentType === 'PIX' ? (
                <PixFormLabel />
              ) : (
                <BilletFormLabel />
              )}
            </FormLabel>

            <FormLabel as="legend" marginBottom={4}>
              Por favor, nos diga por que você está cancelando esta recarga:
            </FormLabel>

            <FormControl isInvalid={!!errors.reason}>
              <Controller
                control={control}
                name="reason"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <RadioGroup
                    marginBottom={4}
                    ref={ref}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    onBlur={onBlur}
                  >
                    <Stack spacing={4}>
                      {rechargeInfos.paymentType === 'PIX' &&
                        cancelRechargeOptionsPix.map((option, index) => {
                          return (
                            <Box width="100%" key={index}>
                              <Radio
                                key={option.label}
                                value={option.value}
                                size="xl"
                                width="100%"
                              >
                                <Text fontSize="16px">{option.label}</Text>
                              </Radio>
                              {option.component}
                            </Box>
                          );
                        })}
                      {rechargeInfos.paymentType === 'BOLETO' &&
                        cancelRechargeOptionsBoleto.map((option, index) => {
                          return (
                            <Box width="100%" key={index}>
                              <Radio
                                key={option.label}
                                value={option.value}
                                size="xl"
                                width="100%"
                              >
                                <Text fontSize="16px">{option.label}</Text>
                              </Radio>
                              {option.component}
                            </Box>
                          );
                        })}
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                <ErrorMessage errors={errors} name="reason" />
              </FormErrorMessage>
            </FormControl>

            {selectedReason === RechargeCancelReason.OTHER && (
              <FormControl isInvalid={!!errors.description}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <InputWithCounter
                      maxLength={140}
                      placeholder="Diga o que aconteceu"
                      {...field}
                    />
                  )}
                />
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="description" />
                </FormErrorMessage>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter marginTop={8} padding={0}>
            <Button variant="outline" mr={3} onClick={onClose} width="134px">
              Voltar
            </Button>
            <Button
              type="submit"
              width={'288px'}
              isLoading={cancelRecharge.isPending}
            >
              Confirmar cancelamento
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CancelRechargeOptionsModal;
