import { ChangeEventHandler, forwardRef, useRef, useState } from 'react';
import {
  InputGroup,
  Input,
  InputLeftElement,
  Icon,
  Popover,
  PopoverContent,
  useDisclosure,
  useOutsideClick,
  PopoverAnchor,
} from '@chakra-ui/react';
import { FiCalendar } from 'react-icons/fi';
import {
  DayPicker,
  SelectSingleEventHandler,
  DayPickerSingleProps,
  Matcher,
} from 'react-day-picker';
import { format, isValid, parse, formatISO } from 'date-fns';
import 'react-day-picker/dist/style.css';
import './chakra-react-datepicker.css';
import { IMaskInput } from 'react-imask';
import { ptBR } from 'date-fns/locale';

type DatePickerProps = Omit<DayPickerSingleProps, 'mode'> & {
  onChange?: (dateISO: string | undefined) => void;
  isDisabled?: boolean;
  value?: string | null;
  onBlur?: () => void;
  disabledDays?: Matcher | Matcher[];
};

const DatePicker = forwardRef((props: DatePickerProps, ref) => {
  const { onChange, isDisabled, value, onBlur } = props;
  const [selected, setSelected] = useState<Date | undefined>(() => {
    if (value && isValid(new Date(value))) {
      return new Date(value);
    }
  });
  const formattedValue = value ? format(value, 'dd/MM/yyyy') : undefined;
  const [inputValue, setInputValue] = useState<string | undefined>(
    formattedValue
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverContentRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: wrapperRef,
    handler: onClose,
  });

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    if (!e.currentTarget.value) {
      setSelected(undefined);
      onChange?.(undefined);
      return;
    }
    const date = parse(e.currentTarget.value, 'dd/MM/yyyy', new Date());
    if (isValid(date)) {
      setSelected(date);
      onChange?.(formatISO(date));
    } else {
      setSelected(undefined);
      onChange?.(undefined);
    }
  };

  const handleDaySelect: SelectSingleEventHandler = (date) => {
    if (date) {
      setSelected(date);
      setInputValue(format(date, 'dd/MM/yyyy'));
      onChange?.(formatISO(date));
    }
    onBlur?.();
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && isOpen) {
      e.preventDefault();
      onClose();
    }
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div ref={wrapperRef}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        autoFocus={false}
        closeOnEsc={true}
      >
        <PopoverAnchor>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiCalendar} color="gray.700" />
            </InputLeftElement>
            <Input
              ref={ref}
              as={IMaskInput}
              placeholder="Selecione uma data"
              value={inputValue}
              onChange={handleInputChange}
              paddingLeft={10}
              onKeyDown={handleKeyDown}
              onFocus={onOpen}
              mask="00/00/0000"
              isDisabled={isDisabled}
              onBlur={onBlur}
            />
          </InputGroup>
        </PopoverAnchor>

        <PopoverContent ref={popoverContentRef}>
          <DayPicker
            key={inputValue}
            locale={ptBR}
            defaultMonth={new Date()}
            selected={selected}
            onSelect={handleDaySelect}
            mode="single"
            onDayBlur={onBlur}
            {...props}
            disabled={props.disabledDays}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
});

export default DatePicker;
