import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useGetCompanyList, useSwitchCompanyMutation } from '../../api/company';
import Select from '../Select';
import { SingleValue } from 'react-select';
import { Company } from '../../types/company';

const CompanySelect = () => {
  const { selectedCompany } = useUserCompaniesStore();

  const companyList = useGetCompanyList();

  const { data, isPending } = companyList;
  const userCompanies = data?.companies ?? [];

  const switchCompanyMutation = useSwitchCompanyMutation();

  function handleChange(company: SingleValue<Company>) {
    if (!company) {
      return;
    }
    switchCompanyMutation.mutate(company);
  }

  return (
    <Select
      isLoading={isPending}
      isDisabled={switchCompanyMutation.isPending}
      isSearchable={true}
      width="270px"
      options={userCompanies.sort((a, b) =>
        a.tradeName.localeCompare(b.tradeName)
      )}
      defaultValue={selectedCompany}
      getOptionLabel={(company) => company.tradeName}
      getOptionValue={(company) => company.id}
      onChange={handleChange}
    />
  );
};

export default CompanySelect;
