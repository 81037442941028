import { Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';

import { FiAlertCircle } from 'react-icons/fi';

type RedirectInfosCardProps = {
  hideRedirectInfosCard: boolean;
  lessThanThreeModifications: boolean;
  displayText: React.ReactNode | string;
  buttonRedirect: React.ReactNode | string;
};

const RedirectInfosCard = ({
  displayText,
  buttonRedirect,
  hideRedirectInfosCard,
  lessThanThreeModifications,
}: RedirectInfosCardProps) => {
  if (!hideRedirectInfosCard || !lessThanThreeModifications) {
    return null;
  }
  return (
    <Flex
      marginTop={4}
      dir={'row'}
      alignItems={'center'}
      padding={6}
      borderRadius={'8px'}
      background={'light.orange'}
    >
      <Icon
        color={'brand.aux08'}
        as={FiAlertCircle}
        width={'24px'}
        height={'24px'}
      />
      <Text fontSize="16px" as="span" marginLeft={6}>
        {displayText}
      </Text>
      {buttonRedirect}
    </Flex>
  );
};

export default RedirectInfosCard;
