type AddressCardContentProps = StackProps & {
  address: Partial<Address>;
};

import {
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  StackProps,
  Text,
  VStack,
  useClipboard,
} from '@chakra-ui/react';
import { Address } from '../../../../types/address';
import { BuildingIcon } from '../../../../assets/customIcons';
import { FiCheckCircle, FiCopy } from 'react-icons/fi';

const EmployeeImportAddressCard = ({
  address,
  ...props
}: AddressCardContentProps) => {
  const { hasCopied, onCopy } = useClipboard(address.name ?? '');
  return (
    <VStack
      align="flex-start"
      {...props}
      backgroundColor="gray.100"
      padding={6}
      width="355px"
      borderRadius="lg"
      spacing={6}
    >
      <Icon as={BuildingIcon} boxSize="24px" />
      <Stack spacing={2}>
        <Text fontWeight={600}>{address.name}</Text>
        {hasCopied ? (
          <Flex alignItems="center" color="feedback.success">
            <Icon as={FiCheckCircle} boxSize="22px" />
            <Button variant="link" padding={0} fontWeight={600} ml={2}>
              Copiado!
            </Button>
          </Flex>
        ) : (
          <Flex alignItems="center" color="brand.aux08" onClick={onCopy}>
            <Icon as={FiCopy} boxSize="22px" />
            <Button variant="link" padding={0} fontWeight={600} ml={2}>
              Copiar
            </Button>
          </Flex>
        )}
      </Stack>
      <Box>
        <Text>
          {address.address}
          {address.number ? `, ${address.number}` : ''}
        </Text>
        <Text>
          {address.district},{' '}
          {address.zipCode &&
            address.zipCode.slice(0, 5) + '-' + address.zipCode.slice(5)}
        </Text>
        <Text>
          {address.city}/{address.state}
        </Text>
        <Text>{address.complement}</Text>
      </Box>
    </VStack>
  );
};

export default EmployeeImportAddressCard;
