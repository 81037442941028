import React from 'react';
import axios, { AxiosInstance } from 'axios';
import { redirect } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

const AXIOS_TIMEOUT = 30000;
const API_URL = import.meta.env.VITE_API_URL;

type FetchContextType = {
  api: AxiosInstance;
};

type FetchContextProviderProps = {
  children: React.ReactNode;
};

const FetchContext = React.createContext({} as FetchContextType);

const FetchContextProvider = ({ children }: FetchContextProviderProps) => {
  const { getToken, removeAuthInfo } = useAuthContext();

  const api = axios.create({
    timeout: AXIOS_TIMEOUT,
    baseURL: API_URL,
  });

  api.interceptors.request.use(
    (config) => {
      config.headers = config.headers ?? {};
      config.headers.Authorization = `Bearer ${getToken()}`;

      return config;
    },

    (error) => error
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        removeAuthInfo();
        redirect('/login');
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  return (
    <FetchContext.Provider value={{ api }}>{children}</FetchContext.Provider>
  );
};

export { FetchContextProvider, FetchContext };
