import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  ModalFooter,
  Button,
  Box,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import InputWithCounter from '../../../../components/InputWithCounter';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { Employee } from '../../../../types/employee';

type NewCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  employee: Employee;
};

const optionsEnum = [
  'LOST',
  'STOLEN',
  'DELIVERY_LOST',
  'DAMAGED',
  'OTHER',
] as const;

const schema = z
  .object({
    reason: z.enum(optionsEnum, {
      required_error: 'Você precisa selecionar uma opção',
    }),
    description: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.reason === 'OTHER' && !data.description) {
        return false;
      }
      return true;
    },
    {
      message: 'Você precisa dizer o motivo da solicitação',
      path: ['description'],
    }
  );

const newCardOptions = [
  { label: 'O colaborador perdeu o cartão', value: 'LOST' },
  { label: 'O colaborador foi furtado/roubado', value: 'STOLEN' },
  {
    label: 'O cartão foi perdido na entrega (extraviado)',
    value: 'DELIVERY_LOST',
  },
  { label: 'O cartão foi danificado', value: 'DAMAGED' },
  { label: 'Outro motivo', value: 'OTHER' },
];

type FormValues = z.infer<typeof schema>;

const NewCardModal = ({ isOpen, onClose, employee }: NewCardModalProps) => {
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      description: '',
    },
  });

  const selectedReason = watch('reason');

  function onSubmit(data: FormValues) {
    navigate('/nova-via', {
      state: { employee: employee, reason: data },
    });
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent padding={8}>
        <ModalHeader fontWeight={700} padding={0}>
          Trocar cartão (nova via)
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody padding={0} marginTop={4}>
            <FormLabel as="legend" marginBottom={4} color="brand.aux08">
              Assim que você concluir a solicitação, iremos{' '}
              <Text as="span" fontWeight={600}>
                cancelar automaticamente o cartão atual
              </Text>{' '}
              do colaborador
            </FormLabel>

            <FormLabel as="legend" marginBottom={4}>
              Por favor, nos diga por que você está solicitando um novo cartão
              para este colaborador:
            </FormLabel>

            <FormControl isInvalid={!!errors.reason}>
              <Controller
                control={control}
                name="reason"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <RadioGroup
                    marginBottom={4}
                    ref={ref}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    onBlur={onBlur}
                  >
                    <Stack spacing={4}>
                      {newCardOptions.map((option, index) => {
                        return (
                          <Box width="100%" key={index}>
                            <Radio
                              key={option.label}
                              value={option.value}
                              size="xl"
                              width="100%"
                            >
                              <Text fontSize="16px">{option.label}</Text>
                            </Radio>
                          </Box>
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                <ErrorMessage errors={errors} name="reason" />
              </FormErrorMessage>
            </FormControl>

            {selectedReason === 'OTHER' && (
              <FormControl isInvalid={!!errors.description}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <InputWithCounter
                      maxLength={140}
                      placeholder="Diga o que aconteceu"
                      {...field}
                    />
                  )}
                />
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="description" />
                </FormErrorMessage>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter marginTop={8} padding={0}>
            <Button variant="outline" mr={3} onClick={onClose} width="134px">
              Cancelar
            </Button>
            <Button width="209px" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default NewCardModal;
