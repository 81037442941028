import { ReactNode, createContext, useState } from 'react';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../utils/localstorage';
import { useUserCompaniesStore } from '../stores/useUserCompaniesStore';
import { AuthContextValues, AuthState } from '../types/auth';
import { UserPolicyName } from '../types/user';

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthStateContext = createContext({} as AuthContextValues);

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [authState, setAuthState] = useState<AuthState>(() => {
    const accessToken = getLocalStorageItem('accessToken');
    const user = getLocalStorageItem('user');

    return {
      accessToken,
      user: user ? JSON.parse(user) : null,
    };
  });

  function setAuthInfo(info: AuthState): void {
    const { accessToken, user } = info;

    if (accessToken && user) {
      setAuthState(info);
      setLocalStorageItem('accessToken', accessToken);
      setLocalStorageItem('user', JSON.stringify(user));
    }
  }

  const userCompaniesStore = useUserCompaniesStore();

  function removeAuthInfo(): void {
    setAuthState({
      accessToken: null,
      user: null,
    });

    userCompaniesStore.reset();

    removeLocalStorageItem('accessToken');
    removeLocalStorageItem('user');
  }

  function isAuthenticated(): boolean {
    return !!authState.accessToken;
  }

  function getToken() {
    return localStorage.getItem('accessToken');
  }

  const user = authState.user;

  const userPolicyName = user?.policy?.name || UserPolicyName.GERAL;

  const { ADMIN, ADMIN_RAIO } = UserPolicyName;

  const isUserAdmin = [ADMIN, ADMIN_RAIO].includes(userPolicyName);
  const isOwner = !!user?.selectedCompany?.isOwner;

  const isAdmin = isUserAdmin || isOwner;

  return (
    <AuthStateContext.Provider
      value={{
        authState,
        setAuthInfo,
        removeAuthInfo,
        isAuthenticated,
        user,
        getToken,
        isAdmin,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export { AuthContextProvider, AuthStateContext };
