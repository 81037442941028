import {
  Box,
  Button,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import HelpMessage from '../../components/HelpMessage';
import InfoText from '../../components/InfoText';
import { PAT_OPTIONS, VA_VR_OPTIONS } from '../../constants/general';
import {
  usePatchCustomerConfigs,
  usePostCustomerConfigs,
} from '../../api/configs';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { CustomerConfig } from '../../types/config';
import ExitWithoutSaveModal from './ExitWithoutSaveModal';
import DiscardChangesModal from './DiscardChangesModal';
import { useEffect, useState } from 'react';
import { RechargeVisibilityEnum } from '../../types/recharge';
import { FiInfo } from 'react-icons/fi';
import CheckboxCard from '../../components/CheckboxCard';
import { useGetSelectedCompanyBenefitCategories } from '../../api/benefit';
import { benefitsPresentation } from '../../utils/formatters/benefitsPresentation';
import { EmployeeBenefitCategory, UsageTypeEnum } from '../../types/employee';
import CustomTooltip from '../../components/CustomTooltip';
import BenefitModal from '../BenefitsPage/components/BenefitModal';
import { SunRiseIcon } from '../../assets/customIcons';

const schema = z.object({
  companyId: z.string().nullish(),
  customerId: z.string().nullish(),
  financialResponsible: z.boolean().nullish(),
  configs: z.object({
    pat: PAT_OPTIONS.optional(),
    vavr: VA_VR_OPTIONS.optional(),
  }),
  company: z
    .object({
      stateRegistration: z.string().nullish(),
      municipalRegistration: z.string().nullish(),
    })
    .nullish(),
  users: z
    .array(z.object({ fullName: z.string(), email: z.string() }))
    .nullish(),
  answerLater: z.boolean().nullish(),
  RechargeVisibilityConfig: z.nativeEnum(RechargeVisibilityEnum).nullish(),
  categories_customers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        period: z.string().optional(),
        value: z.number().optional(),
        edited: z.boolean().nullish().optional(),
        usageType: z.nativeEnum(UsageTypeEnum).optional(),
        isActive: z.boolean(),
      })
    )
    .refine(
      (value) => {
        const hasOneIsActive =
          value.filter((item) => item.isActive).length >= 1;
        if (!hasOneIsActive) return false;
        return true;
      },
      {
        message: 'Selecione ao menos um benefício ou categoria para salvar',
      }
    ),
});

type FormValues = z.infer<typeof schema>;

const BenefitSettings = ({
  customerConfig,
  tabsIndex,
}: {
  customerConfig: CustomerConfig | undefined;
  tabsIndex: number;
}) => {
  const patchCustomerConfigsMutation = usePatchCustomerConfigs();
  const postCustomerConfigsMutation = usePostCustomerConfigs();

  const { selectedCompany } = useUserCompaniesStore();
  const discardChangesModal = useDisclosure();
  const exitWithoutSavingModal = useDisclosure();

  const { data: companyBenefitCategories } =
    useGetSelectedCompanyBenefitCategories();

  const remappedCompanyBenefitCategories =
    companyBenefitCategories?.map((category) => {
      return {
        ...category,
        isActive: category.isActive || false,
        usageType: category.usageType || UsageTypeEnum.RESTRICT,
        id: category.customerCategoryId || '',
      };
    }) || [];

  const defaultValues = {
    configs: {
      pat: customerConfig?.pat || undefined,
      vavr: customerConfig?.vavr || undefined,
    },
    categories_customers: remappedCompanyBenefitCategories,
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, errors },
    getValues,
    setValue,
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: defaultValues,
  });

  const pat = watch('configs.pat');
  const vavr = watch('configs.vavr');
  const categoriesCustomers = watch('categories_customers');

  const benefitsCategoriesFreeUsage = categoriesCustomers?.filter(
    (category) => category.usageType === UsageTypeEnum.FREE_USAGE
  );

  const benefitsCategoriesRestrict = categoriesCustomers?.filter(
    (category) => category.usageType === UsageTypeEnum.RESTRICT
  );

  function onSubmit(data: FormValues) {
    if (customerConfig?.id) {
      patchCustomerConfigsMutation.mutate({
        data: {
          pat: data?.configs?.pat,
          vavr: data?.configs?.vavr,
          categories_customers: data.categories_customers,
        },
        id: customerConfig.id || '',
      });
    } else {
      postCustomerConfigsMutation.mutate({
        data: {
          pat: data?.configs?.pat,
          vavr: data?.configs?.vavr,
          customerId: selectedCompany?.customerId,
          categories_customers: data.categories_customers,
        },
      });
    }
  }
  function handleCheckboxChange(category: EmployeeBenefitCategory) {
    if (category.isActive) {
      const newCategoriesCustomers = categoriesCustomers.map((item) => {
        if (item.id === category.id) {
          return { ...item, isActive: false };
        }
        return item;
      });
      setValue('categories_customers', newCategoriesCustomers, {
        shouldDirty: true,
      });
    } else {
      const newCategoriesCustomers = categoriesCustomers.map((item) => {
        if (item.id === category.id) {
          return { ...item, isActive: true };
        }
        return item;
      });

      setValue('categories_customers', newCategoriesCustomers, {
        shouldDirty: true,
      });
    }
  }
  function getDisabledBenefits() {
    if (vavr === VA_VR_OPTIONS.Enum.TOGETHER) {
      return ['Alimentação', 'Refeição'];
    }
    if (vavr === VA_VR_OPTIONS.Enum.APART) {
      return ['Alimentação e Refeição'];
    }
    return [];
  }

  function onChangeVavr(value: string) {
    if (!categoriesCustomers) return;

    if (value === VA_VR_OPTIONS.Enum.TOGETHER) {
      const newCheckboxValues = categoriesCustomers.map((item) => {
        if (item.name === 'Alimentação' || item.name === 'Refeição') {
          return { ...item, isActive: false };
        }
        return item;
      });
      setValue('categories_customers', newCheckboxValues);
    }

    if (value === VA_VR_OPTIONS.Enum.APART) {
      const newCheckboxValues = categoriesCustomers.map((item) => {
        if (item.name === 'Alimentação e Refeição') {
          return { ...item, isActive: false };
        }
        return item;
      });
      setValue('categories_customers', newCheckboxValues);
    }
  }

  useEffect(() => {
    if (isDirty && tabsIndex !== 1 && exitWithoutSavingModal.isOpen === false) {
      exitWithoutSavingModal.onOpen();
    }
    // removed exitWithoutSavingModal because it causes the modal to open twice.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsIndex, isDirty]);

  return (
    <Flex direction="column" gap={6}>
      <form>
        <Flex
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          marginBottom={8}
        >
          <Text fontSize="20px" fontWeight="500">
            Alimentação e Refeição
          </Text>
        </Flex>
        <FormControl name="pat" as="fieldset">
          <FormLabel as="legend" marginBottom="16px">
            Sua empresa está cadastrada atualmente no PAT (Programa de
            Alimentação do Trabalhador) como beneficiária?{' '}
            <HelpMessage
              label="O cadastro no PAT não é obrigatório para o pagamento de benefícios de alimentação e refeição."
              iconProps={{ position: 'relative', bottom: '-3px' }}
            />
          </FormLabel>
          <Controller
            control={control}
            name="configs.pat"
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioGroup onChange={onChange} onBlur={onBlur} value={value}>
                <Stack spacing={4} direction="row">
                  <Radio value={PAT_OPTIONS.Enum.YES}>Sim</Radio>
                  <Radio value={PAT_OPTIONS.Enum.NO}>Não</Radio>
                  <Radio value={PAT_OPTIONS.Enum.NOTSURE}>Não sei</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl name="vavr" as="fieldset" mt="32px">
          <Flex>
            <FormLabel as="legend" marginBottom="16px" display="flex" gap={1}>
              Como a sua empresa pretende pagar os benefícios de alimentação e
              refeição?{' '}
              <HelpMessage label="O vale-refeição pode ser usado em lugares como restaurantes, cafeterias e lanchonetes. Já o vale-alimentação pode ser usado em mercados e açougues, por exemplo." />
            </FormLabel>
          </Flex>
          {pat === 'YES' && (
            <InfoText
              marginBottom={4}
              display="flex"
              alignItems="center"
              gap={2}
              fontSize="16px"
            >
              <Icon as={FiInfo} color="gray.800" width="24px" height="24px" />
              Se a sua empresa está cadastrada no PAT, é recomendável o uso de
              saldos separados de acordo com a legislação vigente.
            </InfoText>
          )}
          <Controller
            control={control}
            name="configs.vavr"
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioGroup
                onChange={(e) => {
                  onChange(e);
                  onChangeVavr(e);
                }}
                onBlur={onBlur}
                value={value as string}
              >
                <Stack spacing={2} direction="column">
                  <Flex gap={1}>
                    <Radio value={VA_VR_OPTIONS.Enum.TOGETHER}>
                      Os dois juntos, numa carteira com saldo único
                    </Radio>
                    <HelpMessage label="Aqui os colaboradores recebem um valor que poderão usar tanto em alimentação quanto em refeição." />
                  </Flex>

                  <Flex gap={1}>
                    <Radio value={VA_VR_OPTIONS.Enum.APART}>
                      Em carteiras separadas, com seus próprios saldos
                    </Radio>
                    <HelpMessage label="Já aqui, os colaboradores recebem um valor para usar em alimentação e um para usar só em refeição." />
                  </Flex>
                  <Flex gap={1}>
                    <Radio value={VA_VR_OPTIONS.Enum.BOTH}>
                      As duas opções, tanto juntos quanto separados
                    </Radio>
                    <HelpMessage label="Você poderá escolher se você quer que os colaboradores recebam um valor para usar em alimentação e um para usar só em refeição ou os dois benefícios juntos na mesma carteira. " />
                  </Flex>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <Text fontSize="20px" fontWeight="500" marginTop={8}>
          Benefícios
        </Text>

        {errors.categories_customers && (
          <Text color="feedback.alert" fontSize="16px" mt={2}>
            {errors.categories_customers?.message}
          </Text>
        )}

        <FormLabel as="legend" marginY={8}>
          Quais benefícios a sua empresa gostaria de oferecer aos colaboradores?
          Selecione quantos quiser{' '}
          <HelpMessage
            label="As categorias escolhidas estarão disponíveis no momento em que você for fazer recargas"
            iconProps={{ position: 'relative', bottom: '-3px' }}
          />
        </FormLabel>

        <CheckboxGroup>
          <Flex flexWrap="wrap" gap={6}>
            {benefitsCategoriesRestrict?.map((category) => {
              const disabledBenefits = getDisabledBenefits();
              const currentBenefitIsDisabled = disabledBenefits.includes(
                category.name
              );
              const categoryName =
                category.name as keyof typeof benefitsPresentation;

              const benefitColor = benefitsPresentation[categoryName]?.color;

              const textColor = category.isActive ? benefitColor : 'gray.700';

              const iconColor = currentBenefitIsDisabled
                ? 'gray.700'
                : benefitColor;

              return (
                <CustomTooltip
                  key={category.id}
                  label={
                    currentBenefitIsDisabled
                      ? 'Esta categoria não pode ser selecionada por causa da configuração dos benefícios de Alimentação e Refeição acima'
                      : ''
                  }
                >
                  <Flex>
                    <CheckboxCard
                      key={category.id}
                      maxWidth={'228px'}
                      minWidth={'228px'}
                      boxHeight="auto"
                      height="126px"
                      isChecked={category.isActive}
                      onChange={() =>
                        handleCheckboxChange(
                          category as EmployeeBenefitCategory
                        )
                      }
                      isDisabled={currentBenefitIsDisabled}
                      _disabled={{
                        opacity: 0.5,
                        cursor: 'not-allowed',
                      }}
                    >
                      <Box>
                        <Icon
                          as={benefitsPresentation[categoryName]?.icon}
                          color={iconColor}
                          width="24px"
                          height="24px"
                        />
                        <Text
                          fontSize="18px"
                          fontWeight="600"
                          marginTop={2}
                          color={textColor}
                          lineHeight="130%"
                          maxWidth="148px"
                        >
                          {category.name}
                        </Text>
                      </Box>
                    </CheckboxCard>
                  </Flex>
                </CustomTooltip>
              );
            })}
          </Flex>
        </CheckboxGroup>

        <Text fontSize="20px" fontWeight="500" marginTop={8}>
          Categorias de uso livre
        </Text>

        <FormLabel as="legend" marginTop={8}>
          Quais categorias de uso livre a sua empresa gostaria de oferecer aos
          colaboradores? Você pode escolher uma ou mais{' '}
          <HelpMessage
            label="As categorias escolhidas estarão disponíveis no momento em que você for fazer recargas"
            iconProps={{ position: 'relative', bottom: '-3px' }}
          />
        </FormLabel>

        <InfoText
          marginTop={4}
          marginBottom={8}
          display="flex"
          alignItems="center"
          gap={2}
          fontSize="16px"
        >
          <Icon as={FiInfo} color="gray.800" width="24px" height="24px" />O
          valor dessas categorias pode ser usado em qualquer lugar que aceite
          cartões com bandeira Visa e para pagar boletos
        </InfoText>

        <Flex flexWrap="wrap" gap={6}>
          {benefitsCategoriesFreeUsage?.map((category) => {
            const categoryName =
              category.name as keyof typeof benefitsPresentation;

            const textColor = category.isActive
              ? benefitsPresentation[categoryName]?.color
              : 'gray.700';

            return (
              <CheckboxCard
                key={category.id}
                maxWidth={'228px'}
                minWidth={'228px'}
                boxHeight="auto"
                height="126px"
                isChecked={category.isActive}
                onChange={() =>
                  handleCheckboxChange(category as EmployeeBenefitCategory)
                }
              >
                <Box>
                  <Icon
                    as={benefitsPresentation[categoryName]?.icon}
                    color={benefitsPresentation[categoryName]?.color}
                    width="24px"
                    height="24px"
                  />
                  <Text
                    fontSize="18px"
                    fontWeight="600"
                    marginTop={2}
                    color={textColor}
                  >
                    {category.name}
                  </Text>
                </Box>
              </CheckboxCard>
            );
          })}
        </Flex>
        <Box marginTop={8}>
          <BenefitModal>
            <Button
              color="brand.aux08"
              variant="link"
              fontWeight={600}
              display="flex"
              minHeight="fit-content"
              gap={3}
            >
              <SunRiseIcon />
              Saiba onde usar cada um dos benefícios e categorias de uso livre
            </Button>
          </BenefitModal>
        </Box>
        <Drawer
          isOpen={isDirty || patchCustomerConfigsMutation.isPending}
          placement="bottom"
          onClose={() => {}}
          variant="clickThrough"
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
        >
          <DrawerContent padding="24px 32px">
            <DrawerBody
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={4}
              padding={0}
            >
              <Button
                variant="outline"
                width="fit-content"
                onClick={() => {
                  discardChangesModal.onOpen();
                }}
              >
                Cancelar
              </Button>
              <Button
                width="fit-content"
                onClick={() => {
                  const values = getValues();
                  handleSubmit(() => onSubmit(values))();
                }}
                isLoading={
                  patchCustomerConfigsMutation.isPending ||
                  postCustomerConfigsMutation.isPending
                }
              >
                Salvar alterações
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {discardChangesModal.isOpen && (
          <DiscardChangesModal
            isOpen={true}
            onClose={discardChangesModal.onClose}
            onDiscardChanges={() => {
              reset(defaultValues);
              discardChangesModal.onClose();
            }}
          />
        )}

        {exitWithoutSavingModal.isOpen && (
          <ExitWithoutSaveModal
            isOpen={exitWithoutSavingModal.isOpen}
            onClose={() => {
              const values = getValues();
              handleSubmit(() => onSubmit(values))();
              exitWithoutSavingModal.onClose();
            }}
            onDiscardChanges={() => {
              exitWithoutSavingModal.onClose();
              reset(defaultValues);
            }}
          />
        )}
      </form>
    </Flex>
  );
};

export default BenefitSettings;
