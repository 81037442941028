import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './styles/theme.ts';
import 'unfonts.css';
import { AuthContextProvider } from './contexts/AuthContext.tsx';
import { FetchContextProvider } from './contexts/FetchContext.tsx';
import * as Sentry from '@sentry/react';
import { clarity } from 'react-microsoft-clarity';

const rootElement = document.getElementById('root');

Sentry.init({
  enabled: import.meta.env.MODE !== 'development',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  sendDefaultPii: true,
  release: `portal@${APP_VERSION}`,
  environment: SENTRY_ENVIRONMENT || 'development',
  integrations: [
    Sentry.browserTracingIntegration({
      traceFetch: true,
      traceXHR: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkCaptureBodies: true,
      networkDetailAllowUrls: [/raiobeneficios\.com\/api/i],
      networkRequestHeaders: ['X-Amzn-Requestid'],
      networkResponseHeaders: ['Origin'],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
});

const { VITE_CLARITY_PROJECT_ID } = import.meta.env;

if (VITE_CLARITY_PROJECT_ID) {
  clarity.init(VITE_CLARITY_PROJECT_ID);
}

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <AuthContextProvider>
        <FetchContextProvider>
          <ChakraProvider
            theme={theme}
            toastOptions={{
              defaultOptions: {
                position: 'bottom',
                isClosable: true,
                duration: 3000,
              },
            }}
          >
            <App />
          </ChakraProvider>
        </FetchContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  );
}
