import {
  Button,
  Card,
  Checkbox,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerContentProps,
  Text,
  Circle,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  RowSelectionState,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import {
  FiChevronDown,
  FiDollarSign,
  FiMoreVertical,
  FiPower,
  FiSlash,
  FiSearch,
} from 'react-icons/fi';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { MailPlusIcon } from '../../../../assets/customIcons/MailPlusIcon';
import { employeesTableColumns } from './employeesTableColumns';
import EmployeeActionMenu from './EmployeeActionMenu';
import { fuzzyFilter } from './employeesTableHelpers';
import { useNavigate } from 'react-router-dom';
import RechargeCompetencyModal from './RechargeCompetencyModal';
import { useDebounce } from '../../../../hooks/useDebounce';
import { checkIfSomeEmployeeReceiveDailyBenefits } from '../../../NewRechargePage/utils';
import { useBlockCard } from '../../../../api/card';
import {
  useDisableEmployees,
  useSendCommsToEmployees,
} from '../../../../api/employees';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator';
import Pagination from '../../../../components/Pagination';
import ItemsCountDisplay from '../../../../components/ItemsCountDisplay';
import AlertDialog from '../../../../components/AlertDialog';
import { wait } from '../../../../utils/wait';
import { Employee, EmployeeStatusEnum } from '../../../../types/employee';
import { BoxIcon } from '../../../../assets/customIcons/BoxIcon';
import SetBenefitPackageModal from '../SetBenefitPackageModal';
import DisableEmployeeCardDisplay from './DisableEmployeeCardDisplay';
import {
  disableMultipleEmployeesHelperTexts,
  disableSingleEmployeeHelperTexts,
} from './DisableEmployeeHelperTexts';

type EmployeesListProps = {
  employees: Employee[];
};

const actionDrawerHeight: DrawerContentProps['height'] = '200px';

const EmployeesList = ({ employees }: EmployeesListProps) => {
  const [columns] = useState(() => [...employeesTableColumns]);
  const [employeesList] = useState(() => structuredClone(employees));
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const debouncedGlobalFilter = useDebounce<string>(globalFilter, 700);

  const { onClose } = useDisclosure();

  const disableEmployeeConfirmationAlert = useDisclosure();
  const sendCommsConfirmationAlert = useDisclosure();
  const rechargeCompetencyConfirmationAlert = useDisclosure();
  const blockCardConfirmationAlert = useDisclosure();
  const setBenefitPackageModal = useDisclosure();

  const table = useReactTable({
    data: employeesList,
    columns: columns,
    state: {
      columnVisibility: columnVisibility,
      rowSelection: rowSelection,
      sorting: sorting,
      globalFilter: debouncedGlobalFilter,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
  });

  const navigate = useNavigate();

  const totalEmployees = employeesList.length;
  const totalEmployeesOnThePage = table.getRowModel().rows.length;
  const isAllEmployeesOnThePageSelected = table.getIsAllPageRowsSelected();
  const isAllEmployeesSelected = table.getIsAllRowsSelected();
  const isSomeEmployeesSelected = table.getIsSomeRowsSelected();

  const selectedEmployees = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original);

  const hasSomePendingEmployees = selectedEmployees.some(
    (employee) => employee.status === EmployeeStatusEnum.PENDING
  );

  const numOfSelectedEmployees = selectedEmployees.length;

  const resendCommsToSelectedEmployees = selectedEmployees.filter(
    (employee) =>
      employee.status !== EmployeeStatusEnum.ACTIVE &&
      employee.status !== EmployeeStatusEnum.WAITING_ONBOARDING
  );
  const numOfSelectedEmployeesToResendComms =
    resendCommsToSelectedEmployees.length;

  const drawerTitle =
    selectedEmployees.length === 1
      ? selectedEmployees[0].name
      : 'Ações em grupo';

  const countText =
    selectedEmployees.length === 1
      ? 'colaborador selecionado'
      : 'colaboradores selecionados';

  const layoffAlertDialogTitle =
    selectedEmployees.length === 1 ? (
      <>
        <Flex align={'center'} gap="2">
          <Icon as={FiPower} boxSize={'24px'} />
          Deseja realmente desligar {selectedEmployees[0]?.name}?
        </Flex>
      </>
    ) : (
      <>
        <Flex align={'center'} gap="2">
          <Icon as={FiPower} boxSize={'24px'} />
          Deseja realmente desligar {numOfSelectedEmployees} colaboradores?
        </Flex>
      </>
    );

  const blockCardAlertDialogTitle = `Bloquear o cartão do colaborador ${selectedEmployees[0]?.name}`;

  const sendCommsAlertDialogTitle =
    selectedEmployees.length === 1
      ? `Enviar comunicação para ${selectedEmployees[0].name}`
      : numOfSelectedEmployeesToResendComms === 1
        ? `Enviar comunicação para ${
            selectedEmployees.find(
              (employees) =>
                employees.status !== EmployeeStatusEnum.ACTIVE &&
                employees.status !== EmployeeStatusEnum.WAITING_ONBOARDING
            )?.name
          }`
        : `Enviar comunicação para ${numOfSelectedEmployeesToResendComms} colaboradores`;

  const someEmployeesReceiveDailyBenefits = useMemo(
    () => checkIfSomeEmployeeReceiveDailyBenefits(selectedEmployees),
    [selectedEmployees]
  );

  const getButtonAction = async (e: MouseEvent<HTMLButtonElement>) => {
    const toggleAllPageRowsSelectedHandler =
      table.getToggleAllPageRowsSelectedHandler();
    if (isAllEmployeesSelected) {
      table.resetRowSelection(); // reset row selection
      await wait(1);
      toggleAllPageRowsSelectedHandler(e); // select only the employees on the page
    } else {
      toggleAllPageRowsSelectedHandler(e); // select or remove all employees on the page
    }
  };

  const getButtonLabel = useCallback(() => {
    if (isAllEmployeesSelected) {
      return `Selecionar apenas os ${totalEmployeesOnThePage} colaboradores da página atual`;
    }

    if (
      !isAllEmployeesSelected &&
      isSomeEmployeesSelected &&
      !isAllEmployeesOnThePageSelected
    ) {
      return `Adicionar todos os ${totalEmployeesOnThePage} colaboradores da página atual na seleção existente`;
    }

    if (!isAllEmployeesSelected && isAllEmployeesOnThePageSelected) {
      return `Remover apenas os ${totalEmployeesOnThePage} colaboradores desta página da seleção`;
    }
  }, [
    isAllEmployeesOnThePageSelected,
    isAllEmployeesSelected,
    isSomeEmployeesSelected,
    totalEmployeesOnThePage,
  ]);

  const handleEmployeeBenefitRecharge = useCallback(() => {
    if (someEmployeesReceiveDailyBenefits) {
      rechargeCompetencyConfirmationAlert.onOpen();
    } else {
      navigate('/recargas/nova-recarga', {
        state: { selectedEmployees },
      });
    }
  }, [
    navigate,
    rechargeCompetencyConfirmationAlert,
    selectedEmployees,
    someEmployeesReceiveDailyBenefits,
  ]);

  const blockCard = useBlockCard();
  const sendCommsToEmployees = useSendCommsToEmployees();
  const disableEmployees = useDisableEmployees();

  return (
    <>
      <Flex
        minHeight="56px"
        justifyContent="space-between"
        alignItems="center"
        marginTop={4}
        marginBottom={4}
      >
        <InputGroup maxWidth="360px">
          <Input
            type="search"
            placeholder="Pesquisar por nome, CPF ou pacote"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(String(e.target.value))}
            backgroundColor="gray.50"
            paddingRight={12}
          />
          <InputRightElement>
            <Icon as={FiSearch} boxSize="16px" color="brand.primary" />
          </InputRightElement>
        </InputGroup>
        <Popover>
          <PopoverTrigger>
            <Button
              variant="link"
              color="brand.aux08"
              rightIcon={<Icon as={FiChevronDown} />}
              padding={0}
            >
              Ver colunas
            </Button>
          </PopoverTrigger>
          <PopoverContent boxShadow="lg" padding={2}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverBody>
              <VStack align="justify">
                {table
                  .getAllLeafColumns()
                  .filter((column) => column.id !== 'select')
                  .map((column) => {
                    return (
                      <Checkbox
                        key={column.id}
                        maxWidth="90%"
                        isChecked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        padding={1}
                      >
                        {column.columnDef.header as string}
                      </Checkbox>
                    );
                  })}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Flex direction="column" gap={4} marginBottom={actionDrawerHeight}>
        <Card padding={6} maxWidth="100%" overflowX="auto">
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      cursor={header.column.getCanSort() ? 'pointer' : 'unset'}
                      onClick={header.column.getToggleSortingHandler()}
                      userSelect="none"
                    >
                      <Flex
                        alignItems="center"
                        gap={2}
                        {...header.column.columnDef.meta}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <SortDirectionIndicator
                            direction={header.column.getIsSorted()}
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}

                  <Th textAlign="center">Ações</Th>
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}

                  <Td>
                    <Flex justifyContent="center">
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Menu de ações"
                          icon={<Icon as={FiMoreVertical} boxSize="24px" />}
                          variant="ghost"
                          boxSize="30px"
                        >
                          Ações
                        </MenuButton>
                        <EmployeeActionMenu
                          key={row.original.id}
                          employee={row.original}
                        />
                      </Menu>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>

        <Flex justifyContent="space-between" alignItems="center">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onChangeRowsPerPage={(option) =>
              table.setPageSize(option?.value ?? 10)
            }
            handleClickFirstPage={() => table.setPageIndex(0)}
            handleClickNextPage={() => table.nextPage()}
            handleClickPreviousPage={() => table.previousPage()}
            handleClickLastPage={() =>
              table.setPageIndex(table.getPageCount() - 1)
            }
          />

          <ItemsCountDisplay
            count={totalEmployeesOnThePage}
            total={totalEmployees}
          />
        </Flex>
      </Flex>

      <Drawer
        placement="bottom"
        isOpen={numOfSelectedEmployees > 0}
        onClose={onClose}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
          <DrawerHeader>{drawerTitle}</DrawerHeader>
          <DrawerBody>
            <Flex alignItems="center" gap={4} marginBottom={6}>
              <Text as="span" fontWeight={600}>
                {numOfSelectedEmployees} {countText} de {totalEmployees}
              </Text>

              <Circle size="4px" bg="gray.800" />
              <Button
                variant="link"
                color="brand.aux08"
                padding={0}
                onClick={getButtonAction}
              >
                {getButtonLabel()}
              </Button>
            </Flex>
            <Flex gap={3} flexWrap="wrap">
              <Button
                width="fit-content"
                leftIcon={<FiDollarSign />}
                lineHeight={1}
                onClick={handleEmployeeBenefitRecharge}
              >
                Recarregar benefícios
              </Button>
              <Button
                variant="outline"
                width="fit-content"
                leftIcon={<BoxIcon />}
                lineHeight={1}
                onClick={setBenefitPackageModal.onOpen}
              >
                Definir pacote de benefícios
              </Button>
              {selectedEmployees.length === 1 ? (
                <>
                  <Button
                    variant="outline"
                    width="fit-content"
                    leftIcon={<FiPower />}
                    lineHeight={1}
                    onClick={disableEmployeeConfirmationAlert.onOpen}
                  >
                    Desligar colaborador
                  </Button>
                  {selectedEmployees[0].cardStatus === 'ACTIVE' && (
                    <Button
                      variant="outline"
                      width="fit-content"
                      leftIcon={<FiSlash />}
                      lineHeight={1}
                      onClick={blockCardConfirmationAlert.onOpen}
                    >
                      Bloquear cartão
                    </Button>
                  )}
                  {hasSomePendingEmployees && (
                    <Button
                      variant="outline"
                      width="fit-content"
                      leftIcon={<MailPlusIcon />}
                      lineHeight={1}
                      onClick={sendCommsConfirmationAlert.onOpen}
                    >
                      Reenviar comunicação
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    variant="outline"
                    width="fit-content"
                    leftIcon={<FiPower />}
                    lineHeight={1}
                    onClick={disableEmployeeConfirmationAlert.onOpen}
                  >
                    Desligar colaboradores
                  </Button>
                  {hasSomePendingEmployees && (
                    <Button
                      variant="outline"
                      width="fit-content"
                      leftIcon={<MailPlusIcon />}
                      lineHeight={1}
                      onClick={sendCommsConfirmationAlert.onOpen}
                    >
                      Reenviar comunicação
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {disableEmployeeConfirmationAlert.isOpen && (
        <AlertDialog
          size="2xl"
          isOpen={disableEmployeeConfirmationAlert.isOpen}
          onClose={disableEmployeeConfirmationAlert.onClose}
          cancelButtonAction={disableEmployeeConfirmationAlert.onClose}
          title={layoffAlertDialogTitle}
          confirmButtonLabel={
            numOfSelectedEmployees > 1
              ? 'Desligar colaboradores'
              : 'Desligar colaborador'
          }
          isConfirmButtonLoading={disableEmployees.isPending}
          confirmButtonAction={() => {
            disableEmployees.mutate(
              { collaboratorIds: selectedEmployees.map((e) => e.id) },
              {
                onSuccess: () => {
                  disableEmployeeConfirmationAlert.onClose();
                  table.resetRowSelection();
                },
              }
            );
          }}
        >
          {numOfSelectedEmployees > 1 ? (
            <DisableEmployeeCardDisplay
              disableEmployeeContent={disableMultipleEmployeesHelperTexts}
            />
          ) : (
            <DisableEmployeeCardDisplay
              disableEmployeeContent={disableSingleEmployeeHelperTexts}
            />
          )}
        </AlertDialog>
      )}

      {blockCardConfirmationAlert.isOpen && (
        <AlertDialog
          isOpen={blockCardConfirmationAlert.isOpen}
          onClose={blockCardConfirmationAlert.onClose}
          cancelButtonAction={blockCardConfirmationAlert.onClose}
          title={blockCardAlertDialogTitle}
          isConfirmButtonLoading={blockCard.isPending}
          confirmButtonLabel="Desligar"
          confirmButtonAction={() =>
            blockCard.mutate(
              {
                employeeDocument: selectedEmployees[0].cpf,
              },
              {
                onSuccess: () => {
                  blockCardConfirmationAlert.onClose();
                },
              }
            )
          }
        >
          Iremos bloquear o cartão e o colaborador não terá mais acesso a ele.
        </AlertDialog>
      )}

      {sendCommsConfirmationAlert.isOpen && (
        <AlertDialog
          isOpen={sendCommsConfirmationAlert.isOpen}
          onClose={sendCommsConfirmationAlert.onClose}
          cancelButtonAction={sendCommsConfirmationAlert.onClose}
          title={sendCommsAlertDialogTitle}
          confirmButtonLabel="Enviar"
          isConfirmButtonLoading={sendCommsToEmployees.isPending}
          confirmButtonAction={() =>
            sendCommsToEmployees.mutate(
              {
                collaboratorIds: selectedEmployees
                  .filter(
                    (employee) =>
                      employee.status !== EmployeeStatusEnum.ACTIVE &&
                      employee.status !== EmployeeStatusEnum.WAITING_ONBOARDING
                  )
                  .map((employee) => employee.id),
              },
              {
                onSuccess: () => {
                  sendCommsConfirmationAlert.onClose();
                },
              }
            )
          }
        >
          Enviaremos novamente{' '}
          {numOfSelectedEmployees > 1 ? 'aos colaboradores' : 'ao colaborador'}{' '}
          o e-mail com link de primeiro acesso do aplicativo.
        </AlertDialog>
      )}

      {rechargeCompetencyConfirmationAlert.isOpen && (
        <RechargeCompetencyModal
          disclosureProps={rechargeCompetencyConfirmationAlert}
          employees={selectedEmployees}
        />
      )}

      {setBenefitPackageModal.isOpen && (
        <SetBenefitPackageModal
          isOpen={setBenefitPackageModal.isOpen}
          onClose={setBenefitPackageModal.onClose}
          employees={selectedEmployees}
          queryKeyToInvalidate={['employees']}
        />
      )}
    </>
  );
};

export default EmployeesList;
