import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import RechargeHelpProcessingColabs from '../HelpCards/RechargeHelpProcessingColabs';
import RechargeHelpInfo from '../HelpCards/RechargeHelpInfo';
import { Link, useNavigate } from 'react-router-dom';
import RegistrationProcessingEmptyState from '../RegistrationProcessingQueue/EmptyState';
import RegistrationProcessingQueue from '../RegistrationProcessingQueue/RegistrationProcessingQueue';
import EmployeeEmptyState from '../EmptyState';
import EmployeesList from '../EmployeesList';
import { LuPlusCircle, LuUpload } from 'react-icons/lu';
import { useState } from 'react';
import { Employee } from '../../../../types/employee';

type Employees = {
  data: Employee[];
};
type EmployeePageProps = {
  processingEmployeesCount: number;
  employees: Employees | undefined;
};

const EmployeeTabsPage = ({
  processingEmployeesCount,
  employees,
}: EmployeePageProps) => {
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(
    processingEmployeesCount > 0 ? 0 : 1
  );

  const handleChange = (index: number) => {
    setActiveIndex(index);
  };
  return (
    <Box height="100%">
      <Flex flexDir={'row'} justifyContent="space-between">
        <Flex>
          {activeIndex === 0 && <RechargeHelpProcessingColabs />}
          {activeIndex === 1 && <RechargeHelpInfo />}
        </Flex>
        <Flex
          gap={{ base: 4, lg: 2 }}
          alignItems="center"
          justifyContent="right"
          flexWrap="wrap-reverse"
        >
          <Button
            leftIcon={<LuUpload size="20px" />}
            variant="outline"
            marginBottom="auto"
            width="fit-content"
            onClick={() =>
              navigate('/colaboradores/importar/preparar-planilha')
            }
          >
            Cadastrar vários colaboradores
          </Button>
          <Link to="/colaboradores/cadastrar-colaborador">
            <Button
              leftIcon={<LuPlusCircle size="20px" />}
              marginBottom="auto"
              width="fit-content"
            >
              Cadastrar um novo colaborador
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Tabs isLazy onChange={handleChange} index={activeIndex} marginTop={4}>
        {
          <TabList
            display={
              processingEmployeesCount === 0 && activeIndex === 1
                ? 'none'
                : 'flex'
            }
            width={'fit-content'}
          >
            {processingEmployeesCount > 0 && (
              <Tab>
                Colaboradores em processamento ({processingEmployeesCount})
              </Tab>
            )}
            {processingEmployeesCount === 0 && activeIndex === 0 && (
              <Tab>Colaboradores em processamento</Tab>
            )}
            <Tab>Colaboradores cadastrados</Tab>
          </TabList>
        }

        <TabPanels>
          <TabPanel padding={0}>
            {processingEmployeesCount === 0 && activeIndex === 0 ? (
              <RegistrationProcessingEmptyState marginY={10} />
            ) : (
              <RegistrationProcessingQueue />
            )}
          </TabPanel>
          <TabPanel padding={0}>
            <Flex
              direction="column"
              gap={0}
              lineHeight={1}
              height="calc(100% - 100px)"
            >
              {employees && employees.data.length === 0 ? (
                <EmployeeEmptyState marginY={10} />
              ) : (
                <EmployeesList employees={employees?.data || []} />
              )}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EmployeeTabsPage;
