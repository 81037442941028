import { z } from 'zod';
import { employeeFullNameSchema } from './employee';
import { validDDDs } from '../../utils/validations/regex';

const PHONE_OR_EMAIL_REQUIRED_MESSAGE =
  'Você precisa preencher pelo menos um celular ou um e-mail';

export const editEmployeeFormSchema = z
  .object({
    identificationNumber: z
      .string()
      .max(10, 'A matrícula deve ter no máximo 10 caracteres.')
      .optional()
      .or(z.literal(''))
      .nullish(),
    admissionDate: z.string().optional().or(z.literal('')).nullish(),
    fullName: employeeFullNameSchema,
    socialName: z.string().optional().or(z.literal('')).nullish(),
    email: z
      .string({
        required_error: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
      })
      .email({ message: 'Parece que este e-mail está incorreto' })
      .optional()
      .or(z.literal('')),
    cellphone: z
      .string({
        required_error: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
      })
      .min(11, 'Por favor, digite o celular com DDD + 9 números')
      .refine((data) => validDDDs.test(data), {
        message: 'Ops... parece que o DDD deste celular não está certo',
      })
      .refine((data) => data.charAt(2) === '9', {
        message: 'O celular precisa começar com o número 9 após o DDD',
      })
      .optional()
      .or(z.literal('')),
    address: z
      .object({
        city: z.string().optional().or(z.literal('')),
        zipCode: z
          .string()
          .min(8, 'Parece que este CEP é inválido')
          .optional()
          .or(z.literal('')),
        address: z.string().optional().or(z.literal('')),
        complement: z.string().or(z.literal('')).nullish(),
        number: z.string().or(z.literal('')).nullish(),
        district: z.string().optional().or(z.literal('')),
        state: z.string().optional().or(z.literal('')),
      })
      .optional(),
    packageId: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (!values.cellphone && !values.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
        path: ['cellphone'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: PHONE_OR_EMAIL_REQUIRED_MESSAGE,
        path: ['email'],
      });
    }

    const employeeAddress = values.address;

    if (!employeeAddress) {
      return;
    }

    const { zipCode, address, number, complement, district, city, state } =
      employeeAddress;

    const fieldsArray = [
      zipCode,
      address,
      number,
      complement,
      district,
      city,
      state,
    ];

    if (!fieldsArray.some((value) => value)) {
      return;
    }

    // If any of the fields are filled, all of them must be filled
    if (!zipCode) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher o CEP.',
        path: ['address.zipCode'],
      });
    }

    if (!address) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher o endereço',
        path: ['address.address'],
      });
    }

    if (!district) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher o bairro',
        path: ['address.district'],
      });
    }

    if (!city) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher a cidade',
        path: ['address.city'],
      });
    }

    if (!state) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher o estado',
        path: ['address.state'],
      });
    }
  });
