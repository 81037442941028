import { Box, Flex, Icon } from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';

type StepperProps = {
  numberOfSteps: number;
  activeStep: number;
};

const Stepper = ({ activeStep, numberOfSteps }: StepperProps) => {
  const numOfSteps = new Array(numberOfSteps).fill(1);

  return (
    <Flex
      role="progressbar"
      aria-label="Progresso do cadastro"
      background="transparent"
      position="relative"
      flexWrap="nowrap"
      gap="20px"
      width="fit-content"
      zIndex={1000}
    >
      {numOfSteps.map((_, index) => {
        const isActiveStep = activeStep === index + 1;
        const currentStep = index + 1;
        const isPreviousStep = currentStep < activeStep;

        return (
          <Flex
            data-testid="step-indicator"
            key={index}
            position="relative"
            boxSize="24px"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            fontSize="14px"
            color={isPreviousStep || isActiveStep ? 'gray.50' : 'inherit'}
            backgroundColor={
              isPreviousStep || isActiveStep ? 'brand.primary' : 'gray.100'
            }
            fontWeight={isActiveStep ? 600 : 400}
          >
            {isPreviousStep ? (
              <Icon data-testid="step-completed" as={FiCheck} />
            ) : (
              currentStep
            )}
          </Flex>
        );
      })}
      <Box
        zIndex={-1}
        position="absolute"
        top="calc(50% - 1px)"
        height="1px"
        width="100%"
        backgroundColor="gray.200"
      />
    </Flex>
  );
};

export default Stepper;
