import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Text,
} from '@chakra-ui/react';
import Select from '../Select';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/general';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { SingleValue } from 'react-select';

type PaginationProps = {
  rowsPerPageOptions?: Array<{
    value: number;
    label: string;
  }>;
  onChangeRowsPerPage: (
    rowsPerPage: SingleValue<{
      label: string;
      value: number;
    }>
  ) => void;
  currentPage: number;
  totalPages: number;
  handleClickFirstPage: () => void;
  handleClickPreviousPage: () => void;
  handleClickNextPage: () => void;
  handleClickLastPage: () => void;
};

const Pagination = ({
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  onChangeRowsPerPage,
  currentPage,
  totalPages,
  handleClickFirstPage,
  handleClickPreviousPage,
  handleClickNextPage,
  handleClickLastPage,
}: PaginationProps) => {
  const canGoToPreviousPage = currentPage > 1;
  const canGoToFirstPage = canGoToPreviousPage;
  const canGoToNextPage = currentPage < totalPages;
  const canGoToLastPage = canGoToNextPage;
  return (
    <Flex alignItems="center" gap={3} maxWidth="fit-content">
      <FormControl
        maxWidth="fit-content"
        display="flex"
        alignItems="center"
        gap={3}
      >
        <FormLabel margin={0} lineHeight={1}>
          Linhas por página
        </FormLabel>

        <Select
          height="40px"
          menuPlacement="top"
          isSearchable={false}
          options={rowsPerPageOptions}
          defaultValue={rowsPerPageOptions[0]}
          onChange={onChangeRowsPerPage}
        />
      </FormControl>

      <Text minWidth="120px" textAlign="right">
        Página {currentPage} de {totalPages}
      </Text>

      <IconButton
        icon={<FiChevronsLeft />}
        variant="pagination"
        aria-label="Navegar para a primeira página"
        onClick={handleClickFirstPage}
        isDisabled={!canGoToFirstPage}
      />
      <IconButton
        icon={<FiChevronLeft />}
        variant="pagination"
        aria-label="Navegar para a página anterior"
        onClick={handleClickPreviousPage}
        isDisabled={!canGoToPreviousPage}
      />
      <IconButton
        icon={<FiChevronRight />}
        variant="pagination"
        aria-label="Navegar para a próxima página"
        onClick={handleClickNextPage}
        isDisabled={!canGoToNextPage}
      />
      <IconButton
        icon={<FiChevronsRight />}
        variant="pagination"
        aria-label="Navegar para a última página"
        onClick={handleClickLastPage}
        isDisabled={!canGoToLastPage}
      />
    </Flex>
  );
};

export default Pagination;
