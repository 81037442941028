import { Card, CardProps, Text } from '@chakra-ui/react';
import { ReceiptIcon } from '../../../../assets/customIcons/ReceiptIcon';

const BenefitEmptyState = (props: CardProps) => {
  return (
    <Card
      width="100%"
      height="calc(100vh - 473px)"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      minHeight="250px"
      padding={8}
      {...props}
    >
      <ReceiptIcon boxSize="56px" color="brand.primary" />
      <Text fontSize="40px" fontWeight={600} color="gray.900">
        Já Raiô!
      </Text>
      <Text fontSize="32px">
        Você ainda não possui nenhum pacote cadastrado
      </Text>
    </Card>
  );
};

export default BenefitEmptyState;
