import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import logoImg from '../../assets/raio.svg';
import { ReactNode } from 'react';

const FirstAccessMessage = () => {
  return (
    <Box maxWidth="468px">
      <Text>
        Bem-vindo(a) ao seu portal{' '}
        <Text as="span" fontWeight="bold">
          Raiô
        </Text>
        !
      </Text>
      <Text>Aqui a experiência é fácil e direto ao ponto.</Text>
      <Text>Bora configurar o seu acesso?</Text>
    </Box>
  );
};

const LoginMessage = () => {
  return (
    <Box maxWidth="635px">
      <Text>
        Este é o seu portal{' '}
        <Text as="span" fontWeight="bold">
          Raiô
        </Text>
        !
      </Text>
      <Text>Faça login para acessar a área da sua empresa.</Text>
    </Box>
  );
};

const ResetPasswordMessage = () => {
  return (
    <Box fontSize="28px" maxWidth="635px">
      <Text>
        Altere sua senha de acesso do portal {''}
        <Text as="span" fontWeight="bold">
          Raiô.
        </Text>
      </Text>
    </Box>
  );
};

const GenericTextContainer = ({
  children,
  assertText,
}: {
  children: ReactNode;
  assertText: string;
}) => {
  return (
    <Flex
      direction="column"
      width="640px"
      alignContent="start"
      justifyContent="center"
      gap={6}
    >
      <Image src={logoImg} maxWidth="190px" />
      <Heading fontSize="55px" textAlign="left" width="100%" marginLeft="-5px">
        {assertText}
      </Heading>

      {children}
    </Flex>
  );
};

const WelcomeMessageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      direction="column"
      width={{ base: 'fit-content', lg: '640px' }}
      alignContent="start"
      justifyContent="center"
      gap={{ base: 2, lg: 6 }}
      fontSize={{ base: '18px', lg: '28px' }}
    >
      <Image src={logoImg} maxWidth="190px" />
      <Heading
        fontSize={{ base: '36px', lg: '55px' }}
        textAlign="left"
        width="100%"
        marginLeft="-5px"
      >
        Boas-vindas!
      </Heading>

      {children}
    </Flex>
  );
};

const WelcomeMessageLogin = () => {
  return (
    <WelcomeMessageContainer>
      <LoginMessage />
    </WelcomeMessageContainer>
  );
};

const WelcomeMessageFirstAccess = () => {
  return (
    <WelcomeMessageContainer>
      <FirstAccessMessage />
    </WelcomeMessageContainer>
  );
};

const MessageRequestPasswordChange = () => {
  return (
    <GenericTextContainer assertText="Redefinir senha">
      <ResetPasswordMessage />
    </GenericTextContainer>
  );
};

export {
  WelcomeMessageLogin,
  WelcomeMessageFirstAccess,
  MessageRequestPasswordChange,
};
