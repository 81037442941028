import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { H2 } from '../../../../components/Typography';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import AddButton from '../../../../components/AddButton';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import Spinner from '../../../../components/Spinner';
import { UserPolicyName } from '../../../../types/user';
import {
  useCreateFinanceUser,
  useGetSelectedCompanyFinanceUsers,
} from '../../../../api/users';
import { employeeNameRegex } from '../../../../utils/validations/regex';

type FormUser = {
  fullName: string;
  username: string;
};

type FormData = {
  users: FormUser[];
};

const userSchema = z.object({
  users: z.array(
    z.object({
      fullName: z
        .string()
        .min(1, 'Você precisa preencher o nome do usuário.')
        .regex(
          employeeNameRegex,
          'O nome do usuário só pode conter letras e precisa de pelo menos um sobrenome.'
        ),
      username: z.string().email('Parece que este e-mail está incorreto.'),
    })
  ),
});

const SendToFinance = () => {
  const [addNewUser, setNewUser] = useState(false);
  const newFinanceUserModal = useDisclosure();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      users: [],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'users',
  });

  const { selectedCompany } = useUserCompaniesStore();

  const { data: users = [], isPending: isPendingUsers } =
    useGetSelectedCompanyFinanceUsers();

  const createFinanceUser = useCreateFinanceUser();

  function onSave(data: FormData) {
    const userToCreate = data.users[0];
    if (!userToCreate) return;

    createFinanceUser.mutate(
      {
        ...userToCreate,
        customerId: selectedCompany?.customerId,
        policyName: UserPolicyName.FINANCEIRO,
      },
      {
        onSuccess: () => {
          setNewUser(false);
          reset();
        },
      }
    );
  }

  function onCloseModal() {
    setNewUser(false);
    reset();
    newFinanceUserModal.onClose();
  }

  return (
    <Flex
      p={8}
      bg="white"
      borderRadius="8px"
      overflowY="auto"
      flexDirection="column"
      justifyContent="space-between"
      minHeight={'458px'}
    >
      <Box>
        <H2>Configurar contatos financeiros</H2>

        {users.length > 0 ? (
          isPendingUsers ? (
            <Spinner size="md" />
          ) : (
            <Box marginTop={8}>
              {users.map((user) => (
                <Box
                  p={3}
                  bg="gray.100"
                  borderRadius="4px"
                  mt={4}
                  key={user.id}
                >
                  <Text color="brand.aux08">{user.fullName}</Text>
                  <Text marginTop={1}>{user.username}</Text>
                </Box>
              ))}

              <Button
                variant="link"
                color="brand.aux08"
                fontWeight={600}
                padding={0}
                marginTop={4}
                onClick={newFinanceUserModal.onOpen}
              >
                Editar contatos financeiros
              </Button>
            </Box>
          )
        ) : (
          <>
            <Text my={8}>
              Você pode cadastrar endereços de e-mail para enviar
              automaticamente os documentos da recarga
            </Text>
            <Button
              variant="link"
              color="brand.aux08"
              fontWeight={600}
              padding={0}
              onClick={newFinanceUserModal.onOpen}
            >
              Adicionar contatos financeiros
            </Button>
          </>
        )}
      </Box>
      {newFinanceUserModal.isOpen && (
        <Modal
          isCentered
          isOpen={newFinanceUserModal.isOpen}
          onClose={onCloseModal}
          size="lg"
          closeOnEsc={false}
          closeOnOverlayClick={false}
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader mb={8} fontWeight={700}>
              Enviar para o financeiro
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text mb={8}>
                  Ao fechar o pedido, enviamos a{' '}
                  <Text as="span" fontWeight={600}>
                    forma de pagamento
                  </Text>
                  , o{' '}
                  <Text as="span" fontWeight={600}>
                    relatório da recarga
                  </Text>{' '}
                  e a{' '}
                  <Text as="span" fontWeight={600}>
                    nota fiscal
                  </Text>{' '}
                  para o time financeiro e para você.
                </Text>

                {users.length > 0 && (
                  <Text mb={6}>Veja quem já recebe estes documentos:</Text>
                )}

                <Box>
                  {users.map((user) => (
                    <Box
                      p={3}
                      bg="gray.100"
                      borderRadius="4px"
                      mt={4}
                      key={user.id}
                    >
                      <Text color="brand.aux08">{user.fullName}</Text>
                      <Text marginTop={1}>{user.username}</Text>
                    </Box>
                  ))}
                  {users.length > 0 && !addNewUser && <Divider mt={6} />}
                </Box>

                {fields.map((item, index) => (
                  <Flex
                    key={item.id}
                    flexDirection="column"
                    gap={4}
                    marginTop={6}
                  >
                    {index >= 1 && <Divider mt={6} />}
                    <FormControl
                      isInvalid={
                        !!(errors.users && errors.users[index]?.fullName)
                      }
                    >
                      <FormLabel fontSize="sm">Nome completo</FormLabel>
                      <Controller
                        name={`users.${index}.fullName`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="Nome do usuário"
                            bg="white"
                          />
                        )}
                      />
                      {errors.users && errors.users[index]?.fullName && (
                        <Text color="red.500">
                          {errors.users[index]?.fullName?.message}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!(errors.users && errors.users[index]?.username)
                      }
                    >
                      <FormLabel fontSize="sm">Endereço de e-mail</FormLabel>
                      <Controller
                        name={`users.${index}.username`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="E-mail do usuário"
                            bg="white"
                          />
                        )}
                      />
                      {errors.users && errors?.users[index]?.username && (
                        <Text color="red.500">
                          {errors.users[index]?.username?.message}
                        </Text>
                      )}
                    </FormControl>
                  </Flex>
                ))}

                {!addNewUser && (
                  <AddButton
                    marginTop={6}
                    padding={0}
                    onClick={() => {
                      append({ fullName: '', username: '' });
                      setNewUser(true);
                    }}
                  >
                    Adicionar mais uma pessoa
                  </AddButton>
                )}
              </Box>

              {addNewUser && (
                <Flex mt={8}>
                  <Button
                    w="50%"
                    variant="outline"
                    mr={3}
                    onClick={() => {
                      reset();
                      setNewUser(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    w="50%"
                    onClick={handleSubmit(onSave)}
                    isLoading={createFinanceUser.isPending}
                  >
                    Salvar
                  </Button>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default SendToFinance;
