import {
  Box,
  Circle,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';
import phoneEmailMock from '../../../../assets/images/phoneEmailMock.png';
import raioLogo from '../../../../assets/raio.svg';
import bgEmail from '../../../../assets/bg-email.svg';
import { FiInstagram, FiPhone } from 'react-icons/fi';
import { MdWhatsapp } from 'react-icons/md';
import { FaLinkedinIn } from 'react-icons/fa';
import { CardIcon } from '../../../../assets/customIcons';
import { WalletIcon } from '../../../../assets/customIcons/WalletIcon';
import { WirelessIcon } from '../../../../assets/customIcons/WirelessIcon';
import { LocationIcon } from '../../../../assets/customIcons/LocationIcon';

type EmailMockProps = {
  employeeName?: string;
  companyName?: string;
};

const EmailMock = ({
  employeeName = '{Nome Colaborador}',
  companyName = '{Nome Empresa}',
}: EmailMockProps) => {
  return (
    <Flex direction="column" gap={8} width="702px" backgroundColor="gray.50">
      <Box
        width="100%"
        height="200px"
        background={`linear-gradient(0deg, #FAEF72 100%, #FAEF72 100%), url(${bgEmail}) no-repeat center center`}
        backgroundBlendMode={'screen'}
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        padding="20px"
        position="relative"
      >
        <Image src={raioLogo} width="94px" filter={'brightness(0)'} />
      </Box>
      <Flex direction="column" gap={8} padding={12}>
        <Text textAlign="center" fontSize="2xl" fontWeight={600} paddingX={4}>
          {employeeName}, já Raiô!
        </Text>
        <Text textAlign="center">
          Estamos iniciando uma fase incrível por aqui.
        </Text>

        <Text textAlign="center">{companyName} cadastrou você na Raiô!</Text>

        <Text textAlign={'center'} fontWeight={'700'}>
          Para começar a sua jornada com a gente, você deverá seguir os passos
          abaixo:
        </Text>

        <Text>É simples, rápido e fácil! Se liga no passo a passo:</Text>

        <Flex>
          <Flex alignItems="center" direction="column" gap={8}>
            <OrderedList spacing={6}>
              <ListItem>
                <Text fontSize="md">
                  Baixe o aplicativo{' '}
                  <Text as="span" fontWeight={700}>
                    Raiô Benefícios
                  </Text>{' '}
                  na loja de aplicativos.
                </Text>
              </ListItem>

              <ListItem>
                <Text fontSize="md">
                  {' '}
                  <Text as="span" fontWeight={700}>
                    Desbloqueie o seu cartão.
                  </Text>{' '}
                  <Text as="span"> Para isso, faça o </Text>
                  <Text fontWeight={700} as="span">
                    primeiro acesso
                  </Text>{' '}
                  no aplicativo Raiô Benefícios ou acesse a sua conta
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  Agora é só levar o seu cartão e
                  <Text as="span" fontWeight={700}>
                    {' '}
                    aproveitar seus benefícios!
                  </Text>
                </Text>
              </ListItem>
            </OrderedList>

            <Flex
              flexDirection={'column'}
              gap="8"
              alignItems={'left'}
              maxWidth={'343px'}
            >
              <Text>
                <CardIcon mr={'4'} />
                Use seu cartão na{' '}
                <Text as="span" fontWeight={'700'}>
                  função débito
                </Text>
              </Text>
              <Text>
                <WalletIcon width={'21px'} height={'19px'} mr={'4'} />
                Todos os benefícios em{' '}
                <Text as="span" fontWeight={'700'}>
                  um só cartão
                </Text>
              </Text>
              <Text>
                <WirelessIcon width={'24px'} height={'19px'} mr={'4'} />
                Faça pagamentos por{' '}
                <Text as="span" fontWeight={'700'}>
                  aproximação
                </Text>
              </Text>
              <Flex justifyContent={'center'}>
                <LocationIcon width={'24px'} height={'24px'} mr={'4'} />
                <Box>
                  Seu cartão tem rede de aceitação em todo Brasil com{' '}
                  <Text as="span" fontWeight={'700'}>
                    bandeira Visa
                  </Text>
                </Box>
              </Flex>
            </Flex>
            <Text fontWeight={'700'}>
              Se ainda precisar de ajuda, conte com a gente!
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        gap={8}
        width="100%"
        background={`#FAEF72`}
        padding={12}
        alignItems="flex-start"
      >
        <Image src={raioLogo} width="55px" filter={'brightness(0)'} />
        <Text>
          Por favor, pedimos que não responda este e-mail, pois se trata de um
          e-mail automático.
        </Text>
        <Divider borderColor="gray.900" />
        <Text>
          Caso queira entrar em contato conosco, estamos à disposição nestes
          canais:
        </Text>
        <HStack spacing={4}>
          <Circle size="4px" bg="gray.900" />
          <Text fontWeight={700} lineHeight={1} display="flex" gap={1}>
            <Icon as={MdWhatsapp} boxSize={'16px'} />
            51 3025-8893
          </Text>
          <Circle size="4px" bg="gray.900" />
          <Text fontWeight={700} lineHeight={1} display="flex" gap={1}>
            ajuda@raiobeneficios.com
          </Text>
        </HStack>
        <Flex gap={4}>
          <Circle bg="gray.900" padding={2}>
            <Icon as={FiInstagram} color="gray.100" fontSize="24px" />
          </Circle>
          <Circle bg="gray.900" padding={2}>
            <Icon as={FaLinkedinIn} color="gray.100" fontSize="24px" />
          </Circle>
        </Flex>
        <Divider borderColor="gray.900" />
        <HStack spacing={4} fontSize="sm">
          <Text fontWeight={700} lineHeight={1} display="flex" gap={1}>
            Raiô Benefícios Ltda
          </Text>
          <Circle size="4px" bg="gray.900" />
          <Text fontWeight={700} lineHeight={1} display="flex" gap={1}>
            49.854.823/0001-73
          </Text>
          <Circle size="4px" bg="gray.900" />
          <Text fontWeight={700} lineHeight={1} display="flex" gap={1}>
            Inscrição no PAT: 230813350
          </Text>
        </HStack>
        <Text fontSize="sm">
          Avenida Ipiranga, 6681, Prédio 95A, Sala 06 – CEP 90619-900 – Porto
          Alegre – RS
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmailMock;
