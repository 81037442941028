import { Box, BoxProps, Flex, Radio, useRadio } from '@chakra-ui/react';

const ColumnFieldRadioCard = (props: BoxProps) => {
  const { getInputProps, getRadioProps, state } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        backgroundColor="gray.100"
        _checked={{
          bg: 'light.orange',
          borderColor: 'brand.primary',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        padding={4}
        fontSize="18px"
        lineHeight={1}
      >
        <Flex alignItems="center">
          <Radio size="md" isChecked={state.isChecked} marginRight={4} />
          {props.children}
        </Flex>
      </Box>
    </Box>
  );
};

export default ColumnFieldRadioCard;
