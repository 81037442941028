import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import DatePicker from '../../../../components/DatePicker';
import { H2, Semibold } from '../../../../components/Typography';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import { IoCalendarOutline } from 'react-icons/io5';
import { ScanBarCode } from '../../../../assets/customIcons/ScanBarCode';
import ConnectForm from '../../../../components/ConnectForm';
import { CheckoutOrderFormValues } from '../../types';
import { Controller } from 'react-hook-form';
import { isBefore, subDays } from 'date-fns';
import { FiCalendar, FiDollarSign } from 'react-icons/fi';
import InfoDisplayWithIcon from '../../../../components/InfoDisplayWithIcon';
import { getWeekdayFromISO } from '../../../../utils/formatters/getWeekdayFromISO';
import { getDayMonthYearFromISO } from '../../../../utils/formatters/getDayMonthYearFromISO';
import { capitalizeFirstLetter } from '../../../../utils/formatters/capitalizeFirstLetter';
import { UseQueryResult } from '@tanstack/react-query';
import { RechargeDates } from '../../../../types/recharge';

type CreditDateProps = {
  isEdit?: boolean;
  oldScheduleDate?: string;
  isLoadingEarliestCreditDate?: boolean;
  getRechargeDates: UseQueryResult<RechargeDates, Error>;
};

const CreditDate = ({ isEdit = false, getRechargeDates }: CreditDateProps) => {
  const { data: rechargeDates } = getRechargeDates;

  return (
    <ConnectForm<CheckoutOrderFormValues>>
      {({
        watch,
        register,
        control,
        setValue,
        formState: { errors },
        trigger,
      }) => {
        const { paymentMethod, scheduledDate, creditAvailability } = watch();

        const disabledDays = [
          subDays(new Date(), 1),
          {
            before: new Date(),
          },
        ];

        return (
          <Box
            p={8}
            bg="white"
            borderRadius="8px"
            height={'100%'}
            minHeight={'400px'}
          >
            <H2>
              {isEdit ? 'Alterar' : 'Escolher'} data do crédito dos benefícios
            </H2>

            <Text marginTop={8} marginBottom={6}>
              Quando você quer que o valor esteja disponível aos colaboradores?
            </Text>
            <RadioGroup
              onChangeCapture={() => {
                setValue('scheduledDate', undefined, {
                  shouldDirty: true,
                });
              }}
              value={creditAvailability}
            >
              <Stack direction="column">
                <Radio
                  size="lg"
                  {...register('creditAvailability')}
                  value="scheduled"
                >
                  <Text fontSize="16px">Quero escolher o dia</Text>
                </Radio>
              </Stack>
              {creditAvailability === 'scheduled' && (
                <FormControl
                  key={paymentMethod}
                  flexBasis="260px"
                  flexGrow={1}
                  mt={4}
                >
                  <FormLabel fontSize="sm">Data do crédito</FormLabel>
                  <Controller
                    key={scheduledDate}
                    control={control}
                    name="scheduledDate"
                    render={({ field }) => (
                      <DatePicker
                        ref={field.ref}
                        onChange={(date) => {
                          if (date) {
                            field.onChange(date);
                          }

                          trigger('creditAvailability');
                        }}
                        value={scheduledDate}
                        disabledDays={disabledDays}
                      />
                    )}
                  />
                </FormControl>
              )}
              <Stack direction="column" mt={6}>
                <Radio
                  size="lg"
                  {...register('creditAvailability')}
                  value="paymentConfirmed"
                >
                  <Text fontSize="16px">O mais rápido possível</Text>
                </Radio>
              </Stack>

              {creditAvailability === 'paymentConfirmed' && (
                <Box p={6} bg="light.orange" borderRadius="8px" marginTop={6}>
                  <Flex alignItems="center" gap={6}>
                    <Box color="brand.aux08">
                      <IoCalendarOutline size="20px" />
                    </Box>
                    <Text lineHeight="18px" fontSize="16px">
                      A forma de pagamento será emitida com a{' '}
                      <Text as="span" fontWeight={600}>
                        menor data de vencimento possível
                      </Text>
                    </Text>
                  </Flex>

                  <Flex
                    alignItems="center"
                    gap={6}
                    marginTop={6}
                    marginBottom={6}
                  >
                    <Box color="brand.aux08">
                      <PixIcon width="20px" height="20px" />
                    </Box>
                    <Text lineHeight="18px" fontSize="16px">
                      Caso você escolha pagar com{' '}
                      <Text as="span" fontWeight={600}>
                        Pix
                      </Text>
                      , o valor será creditado para os colaboradores{' '}
                      <Text as="span" fontWeight={600}>
                        logo após o pagamento
                      </Text>
                    </Text>
                  </Flex>

                  <Flex alignItems="center" gap={6} marginBottom={6}>
                    <Box color="brand.aux08">
                      <ScanBarCode width="20px" height="20px" />
                    </Box>
                    <Text lineHeight="18px" fontSize="16px">
                      Caso escolha{' '}
                      <Text as="span" fontWeight={600}>
                        Boleto
                      </Text>
                      , o valor será creditado no{' '}
                      <Text as="span" fontWeight={600}>
                        dia útil seguinte ao pagamento
                      </Text>
                    </Text>
                  </Flex>
                  <Flex alignItems={'center'} gap={6}>
                    <Box color="brand.aux08">
                      <FiDollarSign width="24px" height="24px" />
                    </Box>
                    <Text lineHeight={'18px'} fontSize={'16px'}>
                      Caso escolha{' '}
                      <Text as="span" fontWeight={600}>
                        Créditos Raiô
                      </Text>
                      , o valor será creditado para os colaboradores{' '}
                      <Text as="span" fontWeight={600}>
                        logo após o pagamento
                      </Text>
                    </Text>
                  </Flex>
                </Box>
              )}
            </RadioGroup>
            {(errors.creditAvailability || errors.scheduledDate) && (
              <Text color="feedback.alert" fontSize="16px" mt={6}>
                {errors.creditAvailability?.message ||
                  errors.scheduledDate?.message}
              </Text>
            )}

            {creditAvailability === 'paymentConfirmed' && !!paymentMethod && (
              <InfoDisplayWithIcon
                marginTop={6}
                label={'Data de crédito prevista'}
                icon={FiCalendar}
                isLoading={getRechargeDates.isLoading}
              >
                {rechargeDates && (
                  <Text>
                    {capitalizeFirstLetter(
                      getWeekdayFromISO(rechargeDates.creditDate)
                    )}
                    {', '}
                    <Semibold>
                      {getDayMonthYearFromISO(rechargeDates.creditDate)}
                    </Semibold>
                  </Text>
                )}
              </InfoDisplayWithIcon>
            )}
          </Box>
        );
      }}
    </ConnectForm>
  );
};

export default CreditDate;
