import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Tag,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';

import Avatar from '../Avatar';
import { FaInstagram } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import Select from '../Select';
import ContactForms from '../ContactForms';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import PortalTermsOfUseLink from '../PortalTermsOfUseLink';
import PrivacyPolicyLink from '../PrivacyPolicyLink';
import { usePatchUser } from '../../api/users';
import { employeeNameRegex } from '../../utils/validations/regex';

const NAME_REQUIRED_MESSAGE = 'Você precisa preencher seu nome de usuário.';

const schema = z.object({
  fullName: z
    .string({
      required_error: NAME_REQUIRED_MESSAGE,
    })
    .min(1, NAME_REQUIRED_MESSAGE)
    .refine(
      (value) => employeeNameRegex.test(value),
      'O nome do usuário só pode conter letras e precisa de pelo menos um sobrenome.'
    ),
  username: z
    .string({ required_error: 'Campo obrigatório' })
    .email({ message: 'E-mail inválido' }),
});

type FormValues = z.infer<typeof schema>;

const Profile = () => {
  const { authState, setAuthInfo } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = authState;
  const { userCompanies } = useUserCompaniesStore();
  const usePatchUserMutation = usePatchUser({
    userId: user?.id || '',
    successMessage: 'Dados atualizados com sucesso!',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      username: user?.username,
      fullName: user?.fullName,
    },
  });

  if (!user) return null;

  function handleSave(values: FormValues) {
    usePatchUserMutation.mutate(
      {
        user: {
          username: values?.username || '',
          fullName: values?.fullName || '',
        },
      },
      {
        onSuccess: () => {
          if (!authState.user) return;

          setAuthInfo({
            ...authState,
            user: {
              ...authState.user,
              username: values?.username || '',
              fullName: values?.fullName.trim() || '',
            },
          });
        },
      }
    );
  }

  return (
    <>
      <Box onClick={onOpen} cursor="pointer">
        <Avatar name={user?.fullName} marginX={3} />
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontWeight={700}>Perfil</Text>
            <Divider
              orientation="horizontal"
              width="48px"
              borderWidth="3px"
              borderColor="brand.aux11"
              borderRadius="100px"
            />
          </DrawerHeader>
          <DrawerBody>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Text fontSize="20px" fontWeight={500}>
                  {user.fullName}
                </Text>

                <Flex mb={8} gap={2} alignItems="center" flexWrap="wrap">
                  {userCompanies.map((company) => (
                    <Tag bgColor="gray.100" color="gray.900" key={company.id}>
                      {company.tradeName}
                    </Tag>
                  ))}
                </Flex>

                <form onSubmit={handleSubmit(handleSave)}>
                  <Accordion allowMultiple>
                    <AccordionItem border="none" bg="gray.100" borderRadius={2}>
                      <Box>
                        <AccordionButton>
                          <Box
                            as="span"
                            flex="1"
                            display="flex"
                            alignItems="center"
                            textAlign="left"
                            gap={2}
                            padding={2}
                          >
                            <FiUser size="20px" />
                            Editar meus dados
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </Box>

                      <AccordionPanel padding={6}>
                        <VStack spacing={4}>
                          <FormControl isInvalid={!!errors.fullName}>
                            <FormLabel>Nome</FormLabel>
                            <Input bg="gray.50" {...register('fullName')} />
                            <FormErrorMessage>
                              <ErrorMessage name="fullName" errors={errors} />
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl isInvalid={!!errors.username}>
                            <FormLabel>E-mail</FormLabel>
                            <Input
                              type="email"
                              bg="gray.50"
                              {...register('username')}
                            />
                            <FormErrorMessage>
                              <ErrorMessage name="username" errors={errors} />
                            </FormErrorMessage>
                          </FormControl>
                        </VStack>

                        {/* Removed for MVP. TODO: Implement later */}
                        {/* <Text>Permissão</Text>
                      <Box mb={4}>
                        <Select
                          onChange={() => {
                            console.log('onChange');
                          }}
                          options={[
                            { value: 'option1', label: 'Administrador' },
                            { value: 'option2', label: 'Usuário' },
                          ]}
                        />
                      </Box>

                      <Text>Empresas</Text>
                      <Box>
                        <Select
                          menuPlacement="top"
                          inputId="employee-cc-selection"
                          options={userCompanies}
                          placeholder="Selecione uma empresa"
                          isMulti={true}
                          closeMenuOnSelect={false}
                          getOptionValue={(company) => company.id}
                          getOptionLabel={(company) => company.tradeName}
                        />
                      </Box> */}

                        <Button
                          type="submit"
                          mt={6}
                          width="100%"
                          isLoading={usePatchUserMutation.isPending}
                        >
                          Salvar alterações
                        </Button>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </form>
              </Box>

              <Box marginTop={8} marginBottom={4}>
                <Box mb={4}>
                  <PortalTermsOfUseLink color="inherit" fontWeight={400} />
                </Box>

                <Box>
                  <PrivacyPolicyLink color="inherit" fontWeight={400} />
                </Box>
                <Divider
                  orientation="vertical"
                  borderWidth="1px"
                  height="0px"
                  borderColor="gray.550"
                  my={8}
                />
                <Text fontSize="16px" fontWeight={600} mb={8}>
                  Precisa de ajuda? Fale com a gente.
                </Text>
                <Box mb={4}>
                  <ContactForms withoutDescription />
                </Box>

                <Flex gap={2}>
                  <Box
                    bg="gray.100"
                    p="5px"
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    as={Link}
                    href="https://www.instagram.com/raiobeneficios"
                    target="_blank"
                  >
                    <FaInstagram size="20px" />
                  </Box>
                  <Box
                    bg="gray.100"
                    p="5px"
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    as={Link}
                    href="https://www.linkedin.com/company/raio-beneficios/"
                    target="_blank"
                  >
                    <FaLinkedinIn size="18px" />
                  </Box>
                </Flex>

                <Divider
                  orientation="vertical"
                  borderWidth="1px"
                  height="0px"
                  borderColor="gray.550"
                  my={6}
                />
                <Text fontSize="14px" fontWeight={600} mb={2}>
                  Raiô Benefícios Ltda
                </Text>

                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text fontSize="14px">CNPJ 49.854.823/0001-73</Text>
                  <Text fontSize="14px">Inscrição no PAT: 230813350</Text>
                </Flex>
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Profile;
