import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import { Semibold } from '../../../../components/Typography';

type EmployeeImportInstructionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleOk?: () => void;
};

const EmployeeImportInstructionsModal = ({
  isOpen,
  onClose,
  handleOk,
}: EmployeeImportInstructionsModalProps) => {
  return (
    <Modal
      isCentered
      size="xl"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Abrimos uma nova guia/aba do seu navegador</ModalHeader>

        <ModalBody>
          <Stack spacing={6} paddingY={4}>
            <Stack spacing={4}>
              <Text>
                Se houver erros na planilha importada, vamos indicar isso na
                etapa <Semibold>Revisar e editar</Semibold>
              </Text>
              <Text>
                Use esta guia para{' '}
                <Semibold>consultar as instruções de preenchimento</Semibold>{' '}
                durante as correções
              </Text>
            </Stack>

            <Alert
              status="warning"
              borderRadius="lg"
              backgroundColor="light.orange"
            >
              <AlertIcon as={FiAlertCircle} color="brand.primary" />
              Volte na guia ao lado e entre aqui sempre que precisar tirar
              alguma dúvida
            </Alert>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            width="full"
            onClick={() => {
              handleOk?.();
              onClose();
            }}
          >
            Entendi!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmployeeImportInstructionsModal;
