import { Box, CheckboxGroup, Flex, Text } from '@chakra-ui/react';
import { H2, Semibold } from '../../../../components/Typography';
import CheckboxCard from '../../../../components/CheckboxCard';
import PaymentMethodCard from '../../../NewRechargePage/components/PaymentMethodCards';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import { ScanBarCode } from '../../../../assets/customIcons/ScanBarCode';
import InfoDisplayWithIcon from '../../../../components/InfoDisplayWithIcon';
import { FiCalendar } from 'react-icons/fi';
import ConnectForm from '../../../../components/ConnectForm';
import { CreditOrderFormValues } from '../..';
import { Controller } from 'react-hook-form';
import { RechargeDates } from '../../../../types/recharge';
import { capitalizeFirstLetter } from '../../../../utils/formatters/capitalizeFirstLetter';
import { getWeekdayFromISO } from '../../../../utils/formatters/getWeekdayFromISO';
import { getDayMonthYearFromISO } from '../../../../utils/formatters/getDayMonthYearFromISO';
import { differenceInDays, startOfDay } from 'date-fns';

type PaymentMethodProps = {
  rechargeDates: RechargeDates | undefined;
  rechargeDatesIsLoading: boolean;
};

const PaymentMethod = ({
  rechargeDates,
  rechargeDatesIsLoading,
}: PaymentMethodProps) => {
  return (
    <ConnectForm<CreditOrderFormValues>>
      {({ watch, control, formState: { errors }, setValue, trigger }) => {
        const nextBusinessDayMessage = differenceInDays(
          new Date(rechargeDates?.dueDate || ''),
          startOfDay(new Date(rechargeDates?.currentDate || ''))
        );

        const paymentType = watch('paymentType');
        return (
          <Flex
            p={8}
            bg="white"
            borderRadius="8px"
            height={'100%'}
            minHeight={'400px'}
            flexDirection="column"
            gap={8}
          >
            <H2>Escolher forma de pagamento</H2>
            <Flex gap={6} flexWrap={'wrap'} justifyContent={'left'}>
              <Controller
                control={control}
                name="paymentType"
                render={({ field: { onChange } }) => (
                  <CheckboxGroup>
                    <CheckboxCard
                      justifyContent={'center'}
                      minWidth={'204px'}
                      height={'160px'}
                      value="PIX"
                      onChange={(e) => {
                        onChange(e);
                        setValue('paymentType', 'PIX', { shouldDirty: true });
                        trigger('paymentType');
                      }}
                      isChecked={paymentType === 'PIX'}
                    >
                      <PaymentMethodCard
                        color={paymentType === 'PIX' ? 'inerit' : 'gray.700'}
                        icon={
                          <PixIcon
                            boxSize={'48px'}
                            color={
                              paymentType === 'PIX'
                                ? 'brand.primary'
                                : 'gray.700'
                            }
                          />
                        }
                        paymentMethodName="Pix"
                        type="PIX"
                      />
                    </CheckboxCard>
                    <CheckboxCard
                      justifyContent={'center'}
                      minWidth={'204px'}
                      height={'160px'}
                      value="BOLETO"
                      onChange={(e) => {
                        onChange(e);
                        setValue('paymentType', 'BOLETO', {
                          shouldDirty: true,
                        });
                        trigger('paymentType');
                      }}
                      isChecked={paymentType === 'BOLETO'}
                    >
                      <PaymentMethodCard
                        color={paymentType === 'BOLETO' ? 'inerit' : 'gray.700'}
                        icon={
                          <ScanBarCode
                            boxSize={'48px'}
                            color={
                              paymentType === 'BOLETO'
                                ? 'brand.primary'
                                : 'gray.700'
                            }
                          />
                        }
                        paymentMethodName="Boleto"
                        type="BOLETO"
                      />
                    </CheckboxCard>
                  </CheckboxGroup>
                )}
              />
              {errors.paymentType && (
                <Text color="feedback.alert" fontSize="16px" marginTop={2}>
                  Você precisa escolher uma forma de pagamento
                </Text>
              )}
            </Flex>
            <Box>
              {paymentType && (
                <InfoDisplayWithIcon
                  label={`Data de vencimento do
                ${capitalizeFirstLetter(paymentType)}`}
                  icon={FiCalendar}
                  isLoading={rechargeDatesIsLoading}
                >
                  {rechargeDates && (
                    <Text>
                      {capitalizeFirstLetter(
                        getWeekdayFromISO(rechargeDates.dueDate)
                      )}
                      {', '}
                      <Semibold>
                        {getDayMonthYearFromISO(rechargeDates.dueDate)}
                      </Semibold>
                    </Text>
                  )}
                </InfoDisplayWithIcon>
              )}

              {nextBusinessDayMessage >= 2 && (
                <Text color="brand.aux08" marginTop={4}>
                  O <Semibold>vencimento do Boleto</Semibold> foi calculado para
                  ser no próximo dia útil
                </Text>
              )}
            </Box>
          </Flex>
        );
      }}
    </ConnectForm>
  );
};

export default PaymentMethod;
