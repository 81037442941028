import React from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Button } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const { removeAuthInfo } = useAuthContext();
  const navigate = useNavigate();
  return (
    <Button
      variant="ghost"
      leftIcon={<FiLogOut />}
      onClick={() => {
        // TODO: request to invalidate token
        removeAuthInfo();
        navigate('/login');
      }}
    >
      Sair
    </Button>
  );
};

export default LogoutButton;
