import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { z } from 'zod';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import { useGetUsers, usePatchUser, usePostUser } from '../../../../api/users';
import Select from '../../../../components/Select';
import Title from '../../../../components/Title';
import { employeeNameRegex } from '../../../../utils/validations/regex';
import { useQueryClient } from '@tanstack/react-query';

const NAME_REQUIRED_MESSAGE = 'Você precisa preencher o nome do colaborador';
const PERMISSION_REQUIRED_MESSAGE =
  'Você precisa definir uma permissão de acesso para o usuário.';

const userSchema = z.object({
  fullName: z
    .string({
      required_error: NAME_REQUIRED_MESSAGE,
    })
    .min(1, NAME_REQUIRED_MESSAGE)
    .refine(
      (value) => employeeNameRegex.test(value),
      'O nome do usuário só pode conter letras e precisa de pelo menos um sobrenome.'
    ),
  username: z
    .string()
    .email({ message: 'Parece que este e-mail está incorreto.' }),
  policies: z
    .string({
      required_error: PERMISSION_REQUIRED_MESSAGE,
    })
    .min(1, PERMISSION_REQUIRED_MESSAGE),
});

type FormValues = z.infer<typeof userSchema>;

type AddAndEditUserProps = {
  isEdit?: boolean;
  userId?: string;
  isOpen: boolean;
  onClose: () => void;
};

const AddAndEditUser = ({
  isEdit,
  userId,
  isOpen,
  onClose,
}: AddAndEditUserProps) => {
  const { selectedCompany } = useUserCompaniesStore();
  const { data: users } = useGetUsers({
    customerId: selectedCompany?.customerId || '',
  });
  const currentUser = users?.data?.find((user) => user.id === userId);
  const usePostUserMutation = usePostUser();
  const usePatchUserMutation = usePatchUser({
    userId: userId || '',
  });
  function getUserPolicies() {
    const currentCustomerUser = currentUser?.customerUsers?.find(
      (customerUser) => customerUser.customerId === selectedCompany?.customerId
    );
    const policies = currentCustomerUser?.policies[0]?.name || '';

    return policies;
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(userSchema),
    defaultValues: {
      fullName: currentUser?.fullName || '',
      username: currentUser?.username || '',
      policies: getUserPolicies(),
    },
  });

  const permissionOptions = [
    {
      value: 'ADMINISTRADOR',
      label: 'Administrador',
    },
    { value: 'GERAL', label: 'Geral' },
    { value: 'FINANCEIRO', label: 'Financeiro' },
  ];

  const onSubmit = (values: FormValues) => {
    try {
      if (values) {
        if (isEdit) {
          usePatchUserMutation.mutate(
            {
              user: {
                customerId: selectedCompany?.customerId || '',
                fullName: values.fullName,
                username: values.username,
                policyName: values.policies,
              },
            },
            {
              onSuccess: () => {
                reset();
                onClose();
              },
            }
          );
        } else {
          usePostUserMutation.mutate(
            {
              user: {
                customerId: selectedCompany?.customerId || '',
                fullName: values.fullName,
                username: values.username,
                policyName: values.policies,
              },
            },
            {
              onSuccess: () => {
                reset();
                onClose();
              },
            }
          );
        }
      }
    } catch {
      console.log('Erro ao criar usuário');
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          reset();
          onClose();
        }}
        size="sm"
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Title>{isEdit ? 'Editar usuário' : 'Novo usuário'}</Title>

            {!isEdit && (
              <Text fontSize="16px" mt={8} fontWeight={400}>
                Ao criar um novo usuário ele receberá um e-mail com instruções
                de primeiro acesso ao Portal Raiô.
              </Text>
            )}
          </DrawerHeader>
          <DrawerBody marginRight={-5} paddingRight={-5} mt={2}>
            <Box paddingRight={10}>
              <FormControl isInvalid={!!errors.fullName} mb={4}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  placeholder="Nome do usuário"
                  bg="white"
                  {...register('fullName')}
                />
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="fullName" />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.username} mb={4}>
                <FormLabel fontSize="sm">E-mail</FormLabel>
                <Input
                  placeholder="nome.usuario@email.com"
                  bg="white"
                  {...register('username')}
                />
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="username" />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.policies} mb={4}>
                <FormLabel fontSize="sm">Permissão</FormLabel>
                <Box>
                  <Controller
                    name="policies"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputId="policies"
                        options={permissionOptions}
                        value={permissionOptions.find(
                          (permission) => field.value === permission.value
                        )}
                        onChange={(event) => {
                          if (event) {
                            field.onChange(event.value as string);
                          }
                        }}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </Box>
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="policies" />
                </FormErrorMessage>
              </FormControl>
              {/*
              <FormControl isInvalid={!!errors.companies} mb={4}>
                <FormLabel fontSize="sm">Empresas</FormLabel>
                <Box>
                  <Controller
                    name="companies"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputId="companies"
                        options={companies}
                        isMulti
                        closeMenuOnSelect={false}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    )}
                  />
                </Box>
                <FormErrorMessage>
                  <ErrorMessage errors={errors} name="companies" />
                </FormErrorMessage>
              </FormControl> */}

              <Button
                mt={8}
                w="full"
                onClick={handleSubmit(onSubmit)}
                isLoading={
                  usePostUserMutation.isPending ||
                  usePatchUserMutation.isPending
                }
              >
                {isEdit ? 'Salvar alterações' : 'Criar novo usuário'}
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddAndEditUser;
